import React from 'react';
import { format } from 'd3-format';
import { scaleLinear, scaleOrdinal, scaleThreshold, scaleQuantile } from '@visx/scale';
import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { LegendTemplate } from './legendTemplate';

const oneDecimalFormat = format('.2f');

const linearScale = scaleLinear({
  domain: [0, 1],
  range: ['#005beabf', '#f33d15bf'],
});

const legendGlyphSize = 20;

export default function HeatmapLegend({ events = false }) {
  return (
    <div className="legends">
      <LegendTemplate title="Legend">
        <LegendLinear
          steps={9}
          scale={linearScale}
          labelFormat={(d, i) => (i % 2 === 0 ? oneDecimalFormat(d) : '')}
        >
          {(labels) =>
            labels.map((label, i) => (
              <LegendItem
                key={`legend-quantile-${i}`}
                onClick={() => {
                  if (events) alert(`clicked: ${JSON.stringify(label)}`);
                }}
              >
                <svg width={legendGlyphSize} height={legendGlyphSize} style={{ margin: '0 0' }}>
                  <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                </svg>
                <LegendLabel align="center" margin="0 0">
                  {label.text}
                </LegendLabel>
              </LegendItem>
            ))
          }
        </LegendLinear>
      </LegendTemplate>
    </div>
  );
}