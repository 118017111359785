import './styles/hero.css'
export function Hero() {
    return (
        <>
            <div className="homepage-brand d-none d-md-block text-center">
                <br />
                <h1>
                    <img className="center" src='/images/bp_brand-logo.png' style={{objectFit: "contain"}} alt="BrainProt" id="homepageBrand" />
                </h1>
                <h5>
                    BrainProt™ is an integrative and simplified omics-based knowledge-base about the human brain and its associated diseases
                </h5>
            </div>
        </>
    );
}