import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Table } from 'reactstrap';
import { LineSeparator } from '../../components/common/separatorLine';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { MDBDataTableV5, MDBDataTable } from 'mdbreact';
import { BarPlotBDDF, BDDFPlotDataConverter } from '../../components/charts/barPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { PieChart, PieChartDataConverter } from '../../components/charts/pieChart';
import PieChartLegend from '../../components/chartLegends/pieChartLegend';
/*
SOLR FIX CORS
http://marianoguerra.org/posts/enable-cors-in-apache-solr/
*/

export default function BDDFDiseaseResult() {
    let params = useParams();
    let diseaseId = params.diseaseId;
    return (
        <>
            
            <MainSearchBar defPortal={'BDDF'}/>
            <LineSeparator />
            <DiseaseResult query={diseaseId} />
        </>
    );
}

export class DiseaseResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            resultJSX: null
        };
        this.diseaseId = this.props.query;
        this.diseaseName = null;
        this.data = null;
        this.headTableData = null
        this.tableData = {columns:[
            {
                label: 'Uniprot ID',
                field: 'Uniprot',
                width: 150
            },
            {   label: 'Gene Name',
                field: 'Gene_Name',
                width: 100
            },
            {
                label: 'ChEMBL ID',
                field: 'ChemblID',
                width: 200
            },
            {
                label: 'Common Name',
                field: 'pref_name',
                width: 200
            },
            {
                label: 'Score',
                field: 'Score',
                width: 100
            },
            {
                label: 'Source',
                field: 'Source',
                width: 100
            },
            {
                label: 'Mechanism of Action (TTD)',
                field: 'MOA',
                width: 250
            },
            {
                label: 'Mechanism of Action (CLUE)',
                field: 'clue_moa',
                width: 250
            },
            {
                label: 'Toxicity',
                field: 'Toxicity',
                width: 250
            },
            {
                label: 'Clinical Phase (CLUE)',
                field: 'clinical_phase',
                width: 100
            },
            {
                label: 'Investigation (TTD)',
                field: 'Investigation',
                width: 200
            },
            {
                label: 'Drug Name (IUPAC)',
                field: 'Drug_Name',
                width: 400
            },
         ],
            rows: []
        }
    }

    componentDidMount() {
        if (this.props.query) {
            fetch(`https://api.brainprot.org/api/bddf/disease/${this.diseaseId}`)
            .then((res) => res.json())
            .then((result) => {
                    this.setState({
                        isLoaded: true,
                        resultJSX: this.setupData(result),
                    });
                    const ele = document.getElementById('ipl-progress-indicator');
                    if(ele && this.state.isLoaded){
                        ele.classList.add('available');
                        setTimeout(() => {
                            ele.remove();
                        }, 2000)
                    };
                },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    const ele = document.getElementById('ipl-progress-indicator');
                    if(ele && this.state.isLoaded){
                        ele.classList.add('available');
                        setTimeout(() => {
                            ele.remove();
                        }, 2000)
                    };
                }
                )
        };
    }

    getCounts(data, field1, field2) {
        var itemArray1 = data.map(function (item) { return item[field1]; });
        var itemArray2 = data.map(function (item) { return item[field2]; });
        const count1 = {};
        const count2 = {};
        for (const element of itemArray1) {
            if (count1[element]) {
                count1[element] += 1;
            } else {
                count1[element] = 1;
            }
        }
        for (const element of itemArray2) {
            if (count2[element]) {
                count2[element] += 1;
            } else {
                count2[element] = 1;
            }
        }

        console.log("Count1:", count1)
        console.log("Count2:", count2)
        var headerData1 = []
        for(var i of Object.keys(count1)){
            var headerItem = {}
            headerItem.sourceName = i
            headerItem.sourceCount = count1[i]
            headerData1.push(headerItem);
        }
        var headerData2 = []
        for(var i of Object.keys(count2)){
            var headerItem = {}
            headerItem.targetName = i
            headerItem.targetCount = count2[i]
            headerData2.push(headerItem);
        }
        return [headerData1, headerData2]
    }

    setupData(result) {
        this.data = result.uniprotList;
        this.diseaseName = result["disease"]
        this.headTableData = this.getCounts(this.data, "Source", "Gene_Name")

        this.data.map((item, index) => {
            var url_uniprot = `https://www.uniprot.org/uniprotkb/${item.Uniprot}/entry`
            item.Uniprot = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_uniprot, '_blank')}
                    >
                        {item.Uniprot}
                    </div>
                </div>
            );
            var url_chembl = `https://www.ebi.ac.uk/chembl/compound_report_card/${item.ChemblID}/`
            item.ChemblID = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_chembl, '_blank')}
                    >
                        {item.ChemblID}
                    </div>
                </div>
            );
            item.Score = (item.Score.toFixed(3));
        })
        this.tableData.rows = this.data
        if (this.data.length == 0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        } else {
            
            return (
                <>
                    <Container fluid>
                        <h4>Result for Disease: {this.diseaseName} ({this.diseaseId})</h4>
                        <br />
                        <div style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 10,
                            marginLeft: "5%",
                            marginRight: "5%",
                            textAlign: 'center',
                            color: "black",
                        }}>
                            <Table hover borderless>
                            <tbody>
                                <tr key={`Source-tr`}>
                                    <th scope="row" key={`Source-head`} width="20%"><b>Sources (number of associations):</b></th>
                                    <td key={`Source`} style={{textAlign: "left"}}>
                                     {this.headTableData[0].map((element) => 
                                     (<span>{element["sourceName"]} ({element["sourceCount"]}) &emsp;</span>))}
                                    </td>
                                </tr>
                                <tr key={`Source-tr`}>
                                    <th scope="row" key={`Source-head`} width="20%"><b>Top Targets (number of compounds):</b></th>
                                    <td key={`Source`} style={{textAlign: "left"}}>
                                     {this.headTableData[1].map((element) => 
                                     (<span>{element["targetName"]} ({element["targetCount"]}) &emsp;</span>))}
                                    </td>
                                </tr>
                            </tbody>
                            </Table>
                        </div>
                        <LineSeparator />
                        <div style={{
                            borderRadius: 20,
                            paddingTop: "20px",
                            paddingLeft: "2%",
                            paddingRight: "2%",
                            marginLeft: "2%",
                            marginRight: "2%",
                            background: "white",
                            position: 'relative',
                            width: "96%"
                        }}
                            >
                            <MDBDataTableV5 
                                entries={20}
                                scrollX
                                hover
                                searchTop 
                                searchBottom={false}
                                data = {this.tableData}
                            />
                        </div>
                        {/* <div style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 10,
                            marginLeft: "5%",
                            marginRight: "5%",
                            textAlign: 'center',
                            color: "black",
                        }}>
                            <Table hover borderless>
                            <thead>
                                <tr key={"header"}>
                                    <th key={"bddf-link"}>
                                        NCT ID (Click to go to Clinical Trial)
                                    </th>
                                    <th key={"chembl"}>
                                        ChEMBL ID
                                    </th>
                                    <th key={"phase"}>
                                        Trial Phase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.data.map((element) =>
                                    (<tr key={`${element.nct_id}-tr`}>
                                        <td key={`${element.drugind_id}`}><a href={`${element.clinical_trial_url}`} target="blank">{element.nct_id}</a></td>
                                        <td key={element.molecule_chembl_id}>{element.molecule_chembl_id}</td>
                                        <th scope="row" key={`${element.max_phase_for_ind}`}>{element.max_phase_for_ind}</th>
                                    </tr>)
                                )}
                            </tbody>
                            </Table>
                        </div>  */} 
                    </Container>          
                </>
            );
        }
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {
        return (
            <>
                {resultJSX}
            </>
        );
        }
    }
}
//http://localhost:8983/solr/brainprot/select?q=gene_name_list%3AOMP&wt=json


