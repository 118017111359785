import React, { useMemo } from 'react'
import { Axis, AxisLeft } from '@visx/axis'
import { BarGroupHorizontal, Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear , scaleOrdinal} from '@visx/scale'
import { Text } from '@visx/text';

const scatterData = [{
    "geneName": "EFGR",
    "DISGENET_Score": 0.025,
    "Pubpular_Score": 0.482,
    "Harmoni_Score": 0.118,
    "Bionda_Score": 0.91,
    "disease": "Meningioma"
},
{
    "geneName": "NEFM",
    "DISGENET_Score": 0.625,
    "Pubpular_Score": 0.782,
    "Harmoni_Score": 0.018,
    "Bionda_Score": 0.61,
    "disease": "Meningioma"
}]

const defaultMargin = { top: 20, right: 20, bottom: 40, left: 50 };

export function HorizontalBar({ data = scatterData, color = "#EFAB03", margin = defaultMargin, scoreType="DP_Score",labelX, width, height, x_score, y_score, events = false }) {
    
    const blue = "#29d8ff";
    const red = "#000000";
    const orange = "#EFAB03"
    const green = "#8efc4e";
    const purple = "#9caff6";
    //const background = "#612efb";
    const background = "#ffffff"

   /*  var removeByAttr = function(arr, attr, value){
        var i = arr.length;
        while(i--){
            if((!arr[i][x_score]) || (!arr[i][y_score])){ 
    
                arr.splice(i,1);
    
            }
        }
        return arr;
      }
    removeByAttr(data);
         */
    const keys = [scoreType];

    //console.log(keys);

    function getMaxVal(data){
        var scoreVals = data.map(function (el) { return el[scoreType] });

        return Math.max(...scoreVals)
    }
    
    const getGeneName = (d) => d.geneName;
    // margins

    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;
    const xMaxVal = getMaxVal(data)

    const geneNameScale = scaleBand({
        domain: data.map(getGeneName),
        padding: 0.2
    });
    
    const typeScale = scaleBand({
        domain: keys,
        padding: 0.1
    });

    const scoreScale = scaleLinear({
        domain: [0, xMaxVal],
        padding: 0.3
    });

    const colorScale = scaleOrdinal({
        domain: keys,
        range: [color]
    });

    // update scale output dimensions
    geneNameScale.rangeRound([0, yMax]);
    typeScale.rangeRound([0, geneNameScale.bandwidth()]);
    scoreScale.rangeRound([0, xMax]);

    //if(data.length<2){
    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={background}
            rx={14}
        />
        <Group top={margin.top} left={margin.left+10}>
            <BarGroupHorizontal
            data={data}
            keys={keys}
            width={xMax}
            y0={getGeneName}
            y0Scale={geneNameScale}
            y1Scale={typeScale}
            xScale={scoreScale}
            color={colorScale}
            >
            {(barGroups) =>
                barGroups.map((barGroup) => (
                <Group
                    key={`bar-group-horizontal-${barGroup.index}-${barGroup.y0}`}
                    top={barGroup.y0}
                >
                    {barGroup.bars.map((bar) => (
                    <Bar
                        key={`${barGroup.index}-${bar.index}-${bar.key}`}
                        x={bar.x}
                        y={bar.y}
                        width={bar.width}
                        height={bar.height}
                        fill={bar.color}
                        rx={4}
                        onClick={() => {
                        if (events)
                            alert(
                            `${bar.key} (${bar.value}) - ${JSON.stringify(bar)}`
                            );
                        }}
                    />
                    ))}
                </Group>
                ))
            }
            </BarGroupHorizontal>
            <AxisLeft
            scale={geneNameScale}
            stroke={red}
            tickStroke={red}
            tickLabelProps={() => ({
                fill: red,
                fontSize: 9,
                textAnchor: "end",
                dy: "0.33em"
            })}
            label={"geneName"}
            labelProps={{
                y: -46,
                x:-((height+100)/2-(margin.top)-(margin.bottom)),
                fontSize: 15
            }}
            />
            <Axis
                orientation="bottom"
                scale={scoreScale}
                stroke={red}
                tickStroke={red}
                tickLabelProps={() => ({
                    fill: red,
                    fontSize: 9,
                    textAnchor: "end",
                    dy: "0.33em"
                })}
                label={labelX}
                labelOffset={2}
                labelProps={{
                    x: width/2-(margin.left+10)-(margin.right),
                    fontSize: 15
                }}
                top={yMax}
            />
        </Group>
        </svg>
    </>
    )
    /* }
    else{
        return (
          <Group background ="white">
            <Text
              x={width / 2}
              y={height / 2}
              width={width}
              verticalAnchor="start"
              textAnchor="middle"
              style={{ fontSize: '60px'}}
            >
              No Preview!
            </Text>
          </Group>
        )
    } */
}