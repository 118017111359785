import React from 'react';
import { Container } from 'reactstrap';

export default class IBPMNotFound extends React.Component {
    render() {
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele){
            ele.classList.add('available');
            setTimeout(() => {
                ele.outerHTML = '';
            }, 2000)
        };
        return (
            <>
                <Container fluid>
                        <main style={{ padding: "1rem" }}>
                            <p>This entry doesn't exist in IBPM/IBPM PhosphoMap.</p>
                            <img src='/images/duck_conf.gif' />
                        </main>
                </Container>
            </>
        );
    }
}