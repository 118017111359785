import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Hero } from '../components/common/hero';
import MainAccordion from '../components/common/mainAccordion';
import { MainFooter } from '../components/common/mainFooter';
import { MainNavBar } from '../components/common/mainNavBar';
import { MainSearchBar } from '../components/common/mainSearchBar';
import { LineSeparator } from '../components/common/separatorLine';
import useMediaQuery from '../hooks/useMediaQuery';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default class Maintenance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBootstrapMD: true
        };
    }

    componentDidMount(){
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
          // fade out
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 0)
        }
    }

    render() {
        const svgPath = this.state.isBootstrapMD ? "/images/brainprot_icons_nav.svg" : "/images/brainprot_icons.svg";
        //if (!isBootstrapMD) {
        //    alert("BrainProt is best viewed on a desktop. BrainProt is functional on mobile devices, however the plots are not well optimized for mobile screens.");
        //}
        return (
            <>
                <Hero />
                <LineSeparator /> 
                <Container>
                    <div className='text-center' style={{
                        borderRadius: 20,
                        background: "white",
                        padding: 30,
                        color: "black",
                    }}>
                        <h3>We are undergoing an upgrade and expect to complete it by 26th January 2023. Apologies for the inconvenience.</h3>
                        <br />
                        <h2>BrainProt Team wishes you and your family: <br /> Happy New Year 2023!</h2>

                    </div>
                    <br />
                </Container>
                <div className='fixed-bottom'>
                <MainFooter />
                </div>
                
                
            </>
        );
    }
}