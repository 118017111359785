import React from 'react';
import { Container, Button } from 'reactstrap';
import { LineSeparator } from '../../components/common/separatorLine';

export default function HBDALanding() {

    return(
        <>
        <HBDAHome />
        </>
    )

}

export class HBDAHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            diseaseTableJSX: null,
        };
        this.diseaseTable = null;
        this.diseaseTableKeys = null;
        
    }

    componentDidMount() {
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
          // fade out
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        }
    
    }

    

    setupDiseaseData(result){
        this.diseaseTable = result.diseaseTableData;
        this.diseaseTableKeys = Object.keys(result.diseaseTableData[0]);
    }


    render() {
        return(
        <>
            <br />
            <h1 style={{textAlign: "center"}}>Welcome to the Human Brain Disease Atlas (HBDA)</h1>
            <LineSeparator />
            <Container>
                    <figure>
                            <img src="/images/hbda/HBDA.png" alt="HBDA_Image" className="center" style={{'width': "40%"}}/>
                    </figure>

                    <div className='center text-center'>
                        <Button style={{backgroundColor: "#f15a24", color: "black"}} href="/hbda/disease_table"><h3>Disease Table Index</h3><h5>(Click here)</h5></Button>
                    </div>

                    <br />

                    <h2 style={{textAlign: "left"}}>About HBDA</h2>
                    <p style={{fontSize: "20px"}}>
                        The Human Brain Disease Atlas (HBDA) within BrainProt serves as a comprehensive index and navigator, showcasing the extensive scope of 
                        the portal. HBDA encompasses data on over 50 human brain diseases, meticulously curated and integrated from various sources, 
                        including multiple IDs and associated information on brain disorders. Moreover, HBDA offers streamlined access to disease-specific 
                        data across different domains within BrainProt.
                    </p>

                    <br />
                    <h5>Last Update </h5>
                    30 April 2023
                    <br />
                    <br />
                    <h4>Publications</h4>
                    No Publication Yet!
            </Container>              
           
        </>
        );
    }
}