import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table } from 'reactstrap';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function IBPMLanding() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };
    return (
        <>
            <br />
            <h1 style={{textAlign: "center"}}>Welcome to the Inter-hemispheric Brain Proteome Map (IBPM) with PhosphoMap</h1>
            <LineSeparator />
            <MainSearchBar defPortal='IBPM' />
            <LineSeparator />
            <Container>
                <figure>
                    <img src="/images/ibpm/brain_hem-min.png" alt="Brain Hemispheres" className="center img_home"/>
                    <h4 style={{textAlign: 'center'}}>+</h4>
                    <img className='phospho-center' src='/images/ibpm/phosphomap_logo.jpg' />
                </figure>
                <p style={{fontSize:"20px"}}>
                    This domain of BrainProt deciphers the expression profile of proteins and phosphosites identified in the left and right hemispheres of 
                    different neuroanatomical regions and sub-regions of the Human Brain. The section currently contains information on more than 3000 p
                    roteins and 46,000 peptides, depicted through a Bar Plot to illustrate the Left Hemisphere, Right Hemisphere, and Region wise profile. 
                    IBPM aims to support, strengthen and accelerate the understanding of Hemisphere functionality linked to dominance, lateralization, and 
                    the onset of brain disorders. In addition to this, the phosphoproteome section of the human brain hemisphere has also been integrated 
                    recently under the PhosphoMap section. IBPM allows users to search and access protein expression, peptide information, phosphosite 
                    identification, and other popular brain resources. This portal aims to partially bridge that gap and complement previous efforts 
                    performed within the Human Brain Proteome Project (HBPP) under the Human Proteome Organization's (HUPO) patronage.
                </p>

                <br />

                <h5>Last Update </h5>
                1 November 2022
                <br />
                <br />

                <h4>Publications</h4>
                <p>Biswas, D., Shenoy, S.V., Chetanya, C., Lachén-Montes, M., Barpanda, A., Athithyan, A.P., Ghosh, S., Ausín, K., Zelaya, M.V., Fernández-Irigoyen, J. and Manna, A., 2021. Deciphering the Interregional and Interhemisphere Proteome of the Human Brain in the Context of the Human Proteome Project. Journal of Proteome Research.</p>
                <a href='https://pubmed.ncbi.nlm.nih.gov/34714085/'>PubMed</a>, <a href='https://doi.org/10.1021/acs.jproteome.1c00511'>DOI</a>
                <br />
                <br />
                <h5>For IBPM PhosphoMap:</h5>
                <p>Biswas D, Kumari N, Lachén-Montes M, Dutta S, Agrawal I, Samanta D, Shenoy SV, Halder A, Fernández-Irigoyen J, Padhye AR, Santamaría E, Srivastava S. Deep Phosphoproteome Landscape of Interhemispheric Functionality of Neuroanatomical Regions of the Human Brain. J Proteome Res. 2022 Nov 1.</p>
                <a href='https://pubmed.ncbi.nlm.nih.gov/36317652/'>PubMed</a>, <a href='https://doi.org/10.1021/acs.jproteome.2c00244'>DOI</a>
            </Container>
        </>
    );
}