import React from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Table } from 'reactstrap';
import { LineSeparator } from '../../components/common/separatorLine';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { MDBDataTableV5 } from 'mdbreact';
import { BarPlotBDDF, BDDFPlotDataConverter } from '../../components/charts/barPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { PieChart, PieChartDataConverter } from '../../components/charts/pieChart';
import PieChartLegend from '../../components/chartLegends/pieChartLegend';
import diseaseMap from '../../DiseaseId_Mapping_Sheet.json';
import ReadMoreReact from 'read-more-react';
import BDDFError from '../bddfError';
/*
SOLR FIX CORS
http://marianoguerra.org/posts/enable-cors-in-apache-solr/
*/

export default function BDDFDiseaseClinicalResult() {
    let params = useParams();
    let diseaseId = params.diseaseId;
    let disease = diseaseMap.find(x => x["MESHID"] === diseaseId);
    let diseaseName = disease["diseaseName"]
    let diseaseDescription = disease["description"]
    return (
        <>
            
            <br />
            <h4>Result for Disease: {diseaseName} ({diseaseId})</h4>
            <p>
                <ReadMoreReact 
                    text={diseaseDescription}
                    min={500}
                    ideal={500}
                    max={diseaseDescription.length} 
                    readMoreText="Read More..."
                />
            </p>
            <DiseaseResult query={diseaseId} />
        </>
    );
}

export class DiseaseResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            found1: null,
            found2: null,
            resultJSX: null
        };
        this.diseaseId = this.props.query;
        this.diseaseName = null;
        this.data = null;
        this.dataClinical = null;
        this.tableData = {columns:[
            {
                label: 'ChEMBL ID',
                field: 'molecule_chembl_id',
            },
            {
                label: 'NCT ID',
                field: 'nct_id',
            },
            {
                label: 'Clincal Trial Phase',
                field: 'max_phase_for_ind',
            },
            {
                label: 'Overall Status',
                field: 'OverallStatus',
            },
        ],
            rows: []
        }
    }

    componentDidMount() {
        if (this.props.query) {
            Promise.all([
            fetch(`https://api.brainprot.org/api/bddf/compound/${this.diseaseId}`)
            .then((res) => {
                var resjson = res.json();
                if (res.status == 200) {
                    this.setState({
                        found1: true,
                    });
                };
            return resjson;}),
            fetch(`https://api.brainprot.org/api/bddf/clinical/${this.diseaseId}`)
            .then((resClinical) => {
                var resClinicaljson = resClinical.json();
                if (resClinical.status == 200) {
                    this.setState({
                        found2: true,
                    });
                };
            return resClinicaljson;})
            ])
                .then(
                    ([res, resClinical]) => {
                        this.setState({
                            isLoaded: true,
                            resultJSX: this.setupData([res, resClinical])
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    }
                )
        };
    }

    setupData(result) {
        if (this.state.found1 && this.state.found2) {
            this.data = result[0].data;
            var combinedData = [];
            this.diseaseName = result["Disease Name"]
            this.dataClinical = result[1].data

            //console.log("Data:", this.data, "Data Clinical:", this.dataClinical)
            for(var i of this.data){
                var clinical_ncts = this.dataClinical.map(function (item) { return item["NCTId"]; });
                if(clinical_ncts.includes(i["nct_id"])){
                    combinedData.push(
                        {nct_id: i["nct_id"],
                        max_phase_for_ind: i["max_phase_for_ind"],
                        molecule_chembl_id: i["molecule_chembl_id"],
                        OverallStatus: this.dataClinical.find(o => o.NCTId === i["nct_id"]).OverallStatus}
                    )
                }
            }

            var barData = BDDFPlotDataConverter(combinedData, "max_phase_for_ind")[0]
            var pieData = PieChartDataConverter(combinedData, "OverallStatus")[0]

            //console.log(combinedData);
            
            combinedData.map((item, index) => {
                var url_chembl = `https://www.ebi.ac.uk/chembl/compound_report_card/${item.molecule_chembl_id}/`
                item.molecule_chembl_id = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_chembl, '_blank')}
                        >
                            {item.molecule_chembl_id}
                        </div>
                    </div>
                );
                var url_nct = `https://clinicaltrials.gov/ct2/results?cond=&term=${item.nct_id}&cntry=&state=&city=&dist=`
                item.nct_id = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_nct, '_blank')}
                        >
                            {item.nct_id}
                        </div>
                    </div>
                );
            })
            this.tableData.rows = combinedData;
        }
        else {
            this.setState({
                found1: false,
                found2: false
            });
        }
        if (!this.data || this.data.length==0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        } else {
            
            return (
                <>
                    <Container fluid>
                        <Row>
                            <Col sm='5'>
                                <div style={{height: 500, overflowX: "auto", width: "88%", margin:"0% 2% 0% 10%"}} >
                                    <ParentSize>
                                        {({ width, height }) => <BarPlotBDDF data={barData.sort((a,b) => (a.x) - (b.x))} width={width} height={height} labelX={"Trial Phase"} labelY={"Number of Drugs"}/>}
                                    </ParentSize>
                                </div>
                            </Col>
                            <Col sm='2'>
                                <div style={{
                                    borderRadius: 20,
                                    background: "white",
                                    paddingTop: 60,
                                    paddingLeft: 20,
                                    color: "black",
                                    height: 500
                                }}>
                                    <ParentSize>
                                        {({ width, height }) => <PieChartLegend width={width} height={height}/>}
                                    </ParentSize>
                                </div>
                            </Col>
                            <Col sm='5'>
                                <div style={{height: 500, overflowX: "auto", width: "90%", marginRight: "5%"}} >
                                    <ParentSize>
                                        {({ width, height }) => <PieChart data={pieData} width={width} height={height}/>}
                                    </ParentSize>
                                </div>
                            </Col>
                        </Row>
                        <LineSeparator />
                        <div style={{
                            borderRadius: 20,
                            paddingTop: "20px",
                            paddingLeft: "2%",
                            paddingRight: "2%",
                            marginLeft: "2%",
                            marginRight: "2%",
                            background: "white",
                            position: 'relative',
                            width: "96%"
                        }}
                            >
                            <MDBDataTableV5 
                                entries={20}
                                hover
                                searchTop 
                                searchBottom={false}
                                data = {this.tableData}
                            />
                        </div>
                        {/* <div style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 10,
                            marginLeft: "5%",
                            marginRight: "5%",
                            textAlign: 'center',
                            color: "black",
                        }}>
                            <Table hover borderless>
                            <thead>
                                <tr key={"header"}>
                                    <th key={"bddf-link"}>
                                        NCT ID (Click to go to Clinical Trial)
                                    </th>
                                    <th key={"chembl"}>
                                        ChEMBL ID
                                    </th>
                                    <th key={"phase"}>
                                        Trial Phase
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.data.map((element) =>
                                    (<tr key={`${element.nct_id}-tr`}>
                                        <td key={`${element.drugind_id}`}><a href={`${element.clinical_trial_url}`} target="blank">{element.nct_id}</a></td>
                                        <td key={element.molecule_chembl_id}>{element.molecule_chembl_id}</td>
                                        <th scope="row" key={`${element.max_phase_for_ind}`}>{element.max_phase_for_ind}</th>
                                    </tr>)
                                )}
                            </tbody>
                            </Table>
                        </div>  */} 
                    </Container>          
                </>
            );
        }
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}. Please reach out to our team in this scenario!</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
            );
        } else if (isLoaded && (!(this.state.found1) && !(this.state.found2))) {
            return (
                <>
                    <BDDFError />
                </>      
            );
        } else if (isLoaded && (this.state.found1 && this.state.found2)) {
        return (
            <>
                {resultJSX}
            </>
        );
        }
    }
}
//http://localhost:8983/solr/brainprot/select?q=gene_name_list%3AOMP&wt=json


