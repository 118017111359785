// BootStrap/ReactStrap
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Button,
    Container,
    InputGroup,
    Input,
    ButtonDropdown,
  // FormGroup,
    Label,
    Form,
    Row,
    Col
  } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import './styles/mainFooter.css'
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { LineSeparator } from './separatorLine';

export function MainFooter() {

// <a href="https://www.navarrabiomed.es/en" target="_blank"><img className="footer_img" src="/images/concourse/nbmrc_2.png" /></a>
// <a href="https://proteored.org" target="_blank"><img className="footer_img" src="/images/concourse/ProtRed_2.png" /></a>
return (
<>  
    <div className=''>
        <div className="mt-4 p-4 bg-light rounded text-center" style={{marginBottom: 0}}>
            <div className="col-sm-12" style={{borderBottom: "3.25px solid #696969", fontSize: "medium", marginBottom: 20,}}>
                <p style={{color: "#404040"}}>
                    BrainProt™ is currently being actively developed by Proteomics Lab, IIT Bombay, India.
                </p>
            </div>
            <Row>
                <Col sm='2'></Col>
                <Col sm='8' className="d-flex justify-content-center">
                    <a href="https://www.bio.iitb.ac.in/~sanjeeva/facility/" target="_blank"><img className="footer_img" src="/images/concourse/massfiitb_2.png" /></a>
                    <a href="http://www.iitb.ac.in" target="_blank"><img className="footer_img" src="/images/concourse/iitb_2.png" /></a>
                </Col>
                <Col sm='2' className="d-flex justify-content-right">
                    <Col sm='4'></Col>
                    <Col sm='2'><a href="https://twitter.com/brainprot?s=11" target='blank'><TwitterIcon style={{color: "#00acee", fontSize: "40px"}}/></a></Col>
                    <Col sm='2'><a href="https://www.linkedin.com/company/brainprot/" target='blank'><LinkedInIcon style={{color: "#0a66c2", fontSize: "40px"}}/></a></Col>
                    <Col sm='2'><a href="https://www.facebook.com/profile.php?id=100092746510935" target='blank'><FacebookIcon style={{color: "#3b5998", fontSize: "40px"}} /></a></Col>
                    <Col sm='2'><a href="https://www.youtube.com/@BrainProtCommunity" target='blank'><YouTubeIcon style={{color: "#FF0000", fontSize: "40px"}} /></a></Col>
                </Col>
            </Row>   
            <div className="d-flex justify-content-center flex-md-row flex-column">
                
            </div>
        </div>
    </div>
</>
);
}

