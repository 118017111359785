import React from 'react';
import { Container } from 'reactstrap';
import { Hero } from '../components/common/hero';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default class About extends React.Component {
  render() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };
    return (
      <>
          <Container>
          <h1 style={{textAlign: "center"}}>About BrainProt™</h1>
          <Hero />
          <br />
          <div style={{
            borderRadius: 20,
            background: "white",
            padding: 30,
            color: "black",
          }}>
          <h5>
          BrainProt (Version 3.0) is an omics-based knowledgebase that includes six domains to 
          accelerate neuroscience research around the globe. The BrainProt aims to provide an easy and 
          robust framework to access and visualize omics data of the Human Brain and its 
          associated diseases/disorders. This is one of the first knowledgebases that integrates 
          popular disease-gene-association databases to curate and identify markers on more 
          than 50 human brain diseases, along with protein/gene level expression of alarming 
          brain tumors and neurodegenerative disorders. BrainProt also allows users to access the clinical 
          trial information of drugs associated with dreadful brain diseases and provides identification of 
          potential therapeutic compounds. BrainProt believes in collaboration, 
          teamwork, and networking to expand the magnitude and limits of knowledge of the Human Brain. 
          The objectives of BrainProt support and follow the footsteps of the HBPP (Human Brain 
          Proteome Project) of the <a href="https://www.hupo.org/" target='blank'>Human Proteome Organization</a> (HUPO). 
          <br />
          <br />
            The name and the logo of BrainProt were filed for a trademark on 18th August 2022.
          </h5>
          </div>
          <br />
          <h1 id='cite' style={{textAlign: "center"}}>Citing BrainProt</h1>
          <br />
          <div style={{
            borderRadius: 20,
            background: "white",
            padding: 30,
            color: "black",
          }}>
            <h5>For IBPM, please cite the following paper:</h5>
            <p>Biswas, D., Shenoy, S.V., Chetanya, C., Lachén-Montes, M., Barpanda, A., Athithyan, A.P., Ghosh, S., Ausín, K., Zelaya, M.V., Fernández-Irigoyen, J. and Manna, A., 2021. Deciphering the Interregional and Interhemisphere Proteome of the Human Brain in the Context of the Human Proteome Project. Journal of Proteome Research.</p>
            <a href='https://pubmed.ncbi.nlm.nih.gov/34714085/'>PubMed</a>, <a href='https://doi.org/10.1021/acs.jproteome.1c00511'>DOI</a>
            <br />
            <h5>For IBPM PhosphoMap:</h5>
            <p>Biswas D, Kumari N, Lachén-Montes M, Dutta S, Agrawal I, Samanta D, Shenoy SV, Halder A, Fernández-Irigoyen J, Padhye AR, Santamaría E, Srivastava S. Deep Phosphoproteome Landscape of Interhemispheric Functionality of Neuroanatomical Regions of the Human Brain. J Proteome Res. 2022 Nov 1.</p>
            <a href='https://pubmed.ncbi.nlm.nih.gov/36317652/'>PubMed</a>, <a href='https://doi.org/10.1021/acs.jproteome.2c00244'>DOI</a>

          </div>
          </Container>
      </>
    );
  }
}