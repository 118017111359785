import React from 'react';
import { useParams } from "react-router-dom";
import { barPlotDataConverter, BarPlotLH, BarPlotRegion, BarPlotRH } from '../../components/charts/barPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Table, Container } from 'reactstrap';
import classnames from 'classnames';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
//import PhosphoHeatMap from '../../components/charts/heatMap';
import LollipopPlotLH from '../../components/charts/lollipopPlot';
import PhosphoHeatMap from '../../components/charts/heatMap';
import { LineSeparator } from '../../components/common/separatorLine';
import IBPMNotFound from '../../components/ibpm/notfound';
import HeatmapLegend from '../../components/chartLegends/heatmapLegend';
import LollipopPlotLegend from '../../components/chartLegends/lollipopPlotLegend';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { MDBDataTableV5 } from 'mdbreact';
import { isInteger } from 'mathjs';
/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function IBPMPhosphoResult() {
  let params = useParams();
  let proteinName = params.proteinName;
  return (
    <>
        <MainSearchBar defPortal='IBPM' />
        <LineSeparator />
        <PhosphoResult proteinName={proteinName}/>
    </>
  );

}

export class PhosphoResult extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            peptideDataJSX: null,
            proteinMetadataJSX: null,
            peptideMetadataJSX: null,
            plotDataJSX: null,
            proteinResourceHubJSX: null,
            activeTab: '1',
            found: false
        };

        this.proteinData = null;
        this.geneName = null;
        this.proteinName = null;
        this.chromosome = null;
        this.proteinMass = null;
        this.proteinLength = null;
        
        this.rawPeptideData = null;
        this.peptideDataLH = null;
        this.peptideDataRH = null;
        this.tableDataLH = null;
        this.tableDataRH = null;
        this.peptideNum = null;
        this.peptideTableData = {columns:[
            {
                label: 'Peptide Sequence',
                field: 'peptideSequence',
                width: 350
            },
            {
                label: 'Length',
                field: 'length',
                width: 150
            },
            {
                label: 'Missed Cleavages',
                field: 'missedCleavages',
                width: 150
            },
            {
                label: 'Phosphorylation Site',
                field: 'phosphoAminoAcid',
                width: 150
            },
            {
                label: 'Phosphorylation Position',
                field: 'phosphoPos',
                width: 150
            }
        ],
            rows: []
        }

        this.hmHeight = null;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    componentDidMount() {
        let searchTerm = "";
        if (this.props.proteinName) {
        this.proteinName = this.props.proteinName.toUpperCase();
        console.log()
        searchTerm = this.proteinName;
        fetch(`https://api.brainprot.org/api/ibpm/phosphomap/proteins/${searchTerm}`)
        .then((res) => {
            if (res.status == 200) {
                this.setState({
                    found: true,
                });
            };
            return res.json();
        })
        .then(
            (result) => {
                this.setupData(result);
                this.setState({
                    isLoaded: true,
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            }
        )
        };
    }
    
    setupData(rawAPIResult) {
        if (this.state.found) {
            this.proteinData = rawAPIResult.proteinData;
            this.geneName = this.proteinData.geneName;
            this.proteinName = this.proteinData.protName;
            this.chromosome = this.proteinData.chromosome;
            this.proteinMass = this.proteinData.protMass;
            this.proteinLength = this.proteinData.protLength;

            this.rawPeptideData = rawAPIResult.phosphoData;
            this.peptideNum = this.rawPeptideData.length;

            this.hmHeight = this.peptideNum*11.5;

            this.setState({
                peptideDataJSX: this.setupPeptideData(),
                peptideMetadataJSX: this.setupPeptideMetadata(),
                proteinMetadataJSX: this.setupProteinMetadata(),
                plotDataJSX: this.setupPlotData(),
                proteinResourceHubJSX: this.setupProteinResourceHub()
            });
        } else {
            this.setState({
                peptideDataJSX: null,
                peptideMetadataJSX: null,
                proteinMetadataJSX: null,
                plotDataJSX: null,
                proteinResourceHubJSX: null
            });
        }
    }

    getColor(value) {
		if (value >=0 && value <=0.125) {
			return '#005beabf';
		} else if (value >0.125 && value <=0.25) {
			return '#3d54b5bf';
		}
        else if (value >0.25 && value <=0.375) {
			return '#5b509abf';
		}
        else if (value >0.375 && value <=0.50) {
			return '#7a4c80bf';
		}
        else if (value >0.50 && value <=0.625) {
			return '#984865bf';
		}
        else if (value >0.625 && value <=0.75) {
			return '#b6454abf';
		}
        else if (value >0.75 && value <=0.875) {
			return '#d54130bf';
		}
        else if (value >0.875 && value <=1.0) {
			return '#f33d15bf';
		}
    }

    fixDecimal(value) {
        if(!isNaN(value) && !isInteger(value)){
            return value.toFixed(2);
        }
        else{
            return value
        }
    }

    setupProteinMetadata(){
        return (
            <>  
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <h5 style={{textAlign: 'center'}}><b>Protein: {this.proteinName}</b></h5>
                    <Container fluid>
                        <Table hover borderless>
                        <tbody>
                            <tr key={"totality"}>
                                <th scope='row' key={"col-1-1"}>
                                    Gene Name:
                                </th>
                                <td key={"col-2-1"}>
                                    {this.geneName}
                                </td>
                            </tr>
                            <tr key={"length"}>
                                <th scope='row' key={"col-1-2"}>
                                    Protein Length:
                                </th>
                                <td key={"col-2-2"}>
                                    {this.proteinLength}
                                </td>
                            </tr>
                            <tr key={"mass"}>
                                <th scope='row' key={"col-1-3"}>
                                    Protein Mass:
                                </th>
                                <td key={"col-2-3"}>
                                    {this.proteinMass}
                                </td>
                            </tr>
                            <tr key={"chromosome"}>
                                <th scope='row' key={"col-1-4"}>
                                    Chromosome:
                                </th>
                                <td key={"col-2-4"}>
                                    {this.chromosome}
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    setupProteinResourceHub(){
        return (
            <>  
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <h5 style={{textAlign: 'center'}}><b>Protein Resource Hub: {this.proteinName}</b></h5>
                    <Container fluid>
                        <Table hover borderless>
                        <tbody>
                            <tr key={"up"}>
                                <th scope='row' key={"col-1-6"}>
                                    UniProt:
                                </th>
                                <td key={"col-2-6"}>
                                    <a href={`https://www.uniprot.org/uniprot/${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"np"}>
                                <th scope='row' key={"col-1-7"}>
                                    neXtProt:
                                </th>
                                <td key={"col-2-7"}>
                                    <a href={`https://www.nextprot.org/proteins/search?query=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"hpa"}>
                                <th scope='row' key={"col-1-8"}>
                                    HPA(Brain Atlas):
                                </th>
                                <td key={"col-2-8"}>
                                    <a href={`https://www.proteinatlas.org/search/${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"string"}>
                                <th scope='row' key={"col-1-9"}>
                                    STRING:
                                </th>
                                <td key={"col-2-9"}>
                                    <a href={`https://string-db.org/api/image/network?identifiers=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"pdb"}>
                                <th scope='row' key={"col-1-10"}>
                                    ProteomeDB:
                                </th>
                                <td key={"col-2-10"}>
                                    <a href={`https://www.proteomicsdb.org/proteomicsdb/#protein/search/query?protein_name=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"aba"}>
                                <th scope='row' key={"col-1-10"}>
                                    Allen Brain Atlas:
                                </th>
                                <td key={"col-2-10"}>
                                    <a href={`http://human.brain-map.org/microarray/search/show?exact_match=false&search_term=${this.proteinName}&search_type=gene`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"gtex"}>
                                <th scope='row' key={"col-1-11"}>
                                    Genotype-Tissue Expression (GTEx):
                                </th>
                                <td key={"col-2-11"}>
                                    <a href={`https://gtexportal.org/home/browseEqtls?location=${this.geneName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"bratx"}>
                                <th scope='row' key={"col-1-12"}>
                                    Brain Transcriptome Database (BrainTx):
                                </th>
                                <td key={"col-2-12"}>
                                    <a href={`https://www.cdtdb.neuroinf.jp/CDT/SearchTop.do?searchKeyword=${this.geneName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"hbt"}>
                                <th scope='row' key={"col-1-13"}>
                                    Human Brain Transcriptome:
                                </th>
                                <td key={"col-2-13"}>
                                    <a href={`https://hbatlas.org/hbtd/basicSearch.pl?geneNameType=Gene+official+symbol&geneName=${this.geneName}&region=Brain+regions`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"braexp"}>
                                <th scope='row' key={"col-1-13"}>
                                    Brain Express:
                                </th>
                                <td key={"col-2-13"}>
                                    <a href={`https://brainexp.org`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    setupPeptideMetadata() {
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <Container fluid>
                        <Table hover borderless>
                            <tbody>
                                <tr key={"totality"}>
                                    <th scope='row' key={"pep-col-1-2"}>
                                        No. of Peptides
                                    </th>
                                    <td key={"pep-col-2-2"}>
                                        {this.peptideNum}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    setupPlotData() {
        this.peptideDataLH = [];
        this.peptideDataRH = [];
        this.tableDataLH = [];
        this.tableDataRH = [];
        for (var peptide in this.rawPeptideData) {
            var entryLH = {};
            var entryRH = {};
            // Missed Cleavage Issue (Muliple Lollipops on one stick)
            //var missedCleavages = this.rawPeptideData[peptide].missedCleavages;
            //if (missedCleavages == 0) {
            //    continue;
            //}
            var tableEntryLH = {}
            var tableEntryRH = {}
            console.log("keys:", Object.keys(this.rawPeptideData[peptide]));
            for(var region of Object.keys(this.rawPeptideData[peptide])){
                if(region.includes("L_Phos")){
                    tableEntryLH[region.slice(0, -5)] = this.rawPeptideData[peptide][region]
                }
                if(region.includes("R_Phos")){
                    tableEntryRH[region.slice(0, -5)] = this.rawPeptideData[peptide][region]
                }
            }
            tableEntryLH["Amino Acid"] = this.rawPeptideData[peptide].phosphoAminoAcid 
            tableEntryLH["Position"] = this.rawPeptideData[peptide].phosphoPos 
            tableEntryRH["Amino Acid"] = this.rawPeptideData[peptide].phosphoAminoAcid 
            tableEntryRH["Position"] = this.rawPeptideData[peptide].phosphoPos 
            entryLH.y = this.rawPeptideData[peptide].Phospho_LH_Average;
            entryRH.y = this.rawPeptideData[peptide].Phospho_RH_Average;
            entryLH.x = this.rawPeptideData[peptide].phosphoPos;
            entryRH.x = this.rawPeptideData[peptide].phosphoPos;
            entryLH.z = this.rawPeptideData[peptide].phosphoAminoAcid;
            entryRH.z = this.rawPeptideData[peptide].phosphoAminoAcid;
            entryLH.peptide = this.rawPeptideData[peptide].peptideSequence;
            entryRH.peptide = this.rawPeptideData[peptide].peptideSequence;
            this.tableDataLH.push(tableEntryLH);
            this.tableDataRH.push(tableEntryRH);
            this.peptideDataLH.push(entryLH);
            this.peptideDataRH.push(entryRH);
        }
        return (
            <>
                <h3>Left Hemisphere</h3>
                    <p>Here is a summary of phosphorylation for <b>{this.proteinName}</b> in Left Hemisphere. </p>
                    <LollipopPlotLegend />
                <div style={{height: 500,}}>
                    <ParentSize>
                        {({ width, height }) => <LollipopPlotLH data={this.peptideDataLH} width={width} height={height} labelX={"Position"} labelY={"Localization Probability"}/>}
                    </ParentSize>
                </div>
                <br/>
                <LineSeparator />
                <h3>Right Hemisphere</h3>
                    <p>Here is a summary of phosphorylation for <b>{this.proteinName}</b> in Right Hemisphere. </p>
                    <LollipopPlotLegend />
                <div style={{height: 500}}>
                    <ParentSize>
                        {({ width, height }) => <LollipopPlotLH data={this.peptideDataRH} width={width} height={height} labelX={"Position"} labelY={"Localization Probability"}/>}
                    </ParentSize>
                </div>
                <br/>
                <LineSeparator />
                {console.log(this.rawPeptideData)}
                <h3>Phosphorylation Map</h3>

                    <HeatmapLegend />

                    <p>Here is a summary of phosphorylation for <b>{this.proteinName}</b> in Left Hemisphere.</p>
                    
                {/* <div style={{height: this.peptideNum*100}}>
                    <ParentSize>
                        {({ width, height }) => <PhosphoHeatMap rawPeptideData={this.rawPeptideData} width={width} height={height} />}
                    </ParentSize>
                </div> 
                <LineSeparator /> */}
                <div style={{
                        borderRadius: 20,
                        padding: "20px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        background: "white",
                        position: 'relative',
                        width: "96%"
                    }}
                >
                    <Table hover bordered>
                        <tr key={"header"} style={{margin: 'auto', border: "1px solid"}}>
                            {Object.keys(this.tableDataLH[0]).map((key) => (   
                                <th center style={{margin: 'auto', border: "1px solid", textAlign:"center"}}>{key}</th>    
                            ))}
                        </tr>
                        {this.tableDataLH.map((item) => (
                            <tr key={item.Position} style={{margin: 'auto', border: "1px solid"}}>
                            {Object.values(item).map((val) => (
                                <td style={{margin: 'auto', border: "1px solid", textAlign:"center", backgroundColor: this.getColor(val)}}>{this.fixDecimal(val)}</td>
                            ))}
                            </tr>
                        ))}
                    </Table>
                </div>  
                <br />
                <p>Here is a summary of phosphorylation for <b>{this.proteinName}</b> in Right Hemisphere.</p>
                {/* <div style={{height: this.peptideNum*100}}>
                    <ParentSize>
                        {({ width, height }) => <PhosphoHeatMap rawPeptideData={this.rawPeptideData} width={width} height={height} />}
                    </ParentSize>
                </div> 
                <LineSeparator /> */}
               <div style={{
                        borderRadius: 20,
                        padding: "20px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        background: "white",
                        position: 'relative',
                        width: "96%"
                    }}
                >
                    <Table hover bordered>
                        <tr key={"header"} style={{margin: 'auto', border: "1px solid"}}>
                            {Object.keys(this.tableDataRH[0]).map((key) => (   
                                <th center style={{margin: 'auto', border: "1px solid", textAlign:"center"}}>{key}</th>    
                            ))}
                        </tr>
                        {this.tableDataRH.map((item) => (
                            <tr key={item.Position} style={{margin: 'auto', border: "1px solid"}}>
                            {Object.values(item).map((val) => (
                                <td style={{margin: 'auto', border: "1px solid", textAlign:"center", backgroundColor: this.getColor(val)}}>{this.fixDecimal(val)}</td>
                            ))}
                            </tr>
                        ))}
                    </Table>
                </div>                
            </>
        );
    }

    setupPeptideData() {
        this.peptideTableData.rows = this.rawPeptideData; 
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <Container fluid>
                        <MDBDataTableV5 
                            entries={10}
                            scrollX
                            hover
                            data = {this.peptideTableData}
                        />
                    </Container>
                </div>            
            </>
        );
    }

    render() {
        const { error, isLoaded, found } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
            );
        } else if (isLoaded && !(found)) {
            return (
                <>
                    <IBPMNotFound />
                </>      
            );
        } else if (isLoaded && (found)) {
        return (
            <>
                <Container fluid>
                <div>
                    <img className='phospho-center' src='/images/ibpm/phosphomap_logo.jpg' />
                    <LineSeparator />
                    <h1>IBPM PhosphoMap Entry for Protein: {this.proteinName}</h1>
                    <Nav pills justified>
                    <NavItem>
                        <NavLink
                        style={{fontSize: "22px"}}
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                        >
                        Summary and Plots
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        style={{fontSize: "22px"}}
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                        >
                        Peptide Information
                        </NavLink>
                    </NavItem>
                    </Nav>
                    <br />
                    <TabContent activeTab={this.state.activeTab} color='primary'>
                    <TabPane tabId="1">
                        <Row>
                        <h4>Summary: {this.proteinName}</h4>
                        <Col sm="3">
                            {this.state.proteinMetadataJSX}
                            <br />
                            {this.state.proteinResourceHubJSX}
                            <br />    
                        </Col>
                        <Col sm="9">
                            {this.state.plotDataJSX}
                        </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <h4>Viewing Peptide Information for Protein: {this.proteinName}</h4>
                        <Col sm="2">
                            {this.state.peptideMetadataJSX}
                            <br />
                        </Col>
                        <Col sm="10">
                            {this.state.peptideDataJSX}
                        </Col>
                        </Row>
                    </TabPane>
                    </TabContent>
                </div>
                </Container>
            </>      
            );
        }
    }
}