import React, { useMemo } from 'react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { GradientPinkBlue, GradientPinkRed, GradientTealBlue, LinearGradient } from '@visx/gradient'
import letterFrequency, {
  LetterFrequency,
} from '@visx/mock-data/lib/mocks/letterFrequency'
import { scaleBand, scaleLinear } from '@visx/scale'


export function barPlotDataConverter(dictData) {
    let convertedDict = [];
		for (var key of Object.keys(dictData)) {
            var item = {};
            item.x = key;
            item.y = dictData[key];
            convertedDict.push(item);
        }
    return convertedDict;
}

export function BarPlotLH({ data, width, labelX, labelY, height, events = true }) {
    // margins
    const verticalMargin = 120;
    const margins = {
        left: 80,
        right: 70,
    }
    // accessors
    const getXData = (d) => d.x;
    const getYData = (d) => Number(d.y);

    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;

    // scales, memoize for performance
    const xScale = useMemo(
        () =>
        scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(getXData),
            padding: 0.4,
        }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map(getYData))],
        }),
        [yMax],
    );

    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <GradientTealBlue id="teal" />
        <LinearGradient to="#accbee" from="#e7f0fd" id='febink'/>;
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={verticalMargin / 2} left={margins.left} right={margins.right}>
            {data.map((d) => {
            const letter = getXData(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYData(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
                <Bar
                key={`bar-${letter}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#4c96e5"
                onClick={() => {
                    if (events) {
                        alert(`Region: ${(Object.values(d)[0])}, Log-transformed Intensity: ${(Object.values(d)[1])}`);
                    }
                }}
                style={{cursor: "pointer"}}
                />
            )
            })}
            <AxisBottom
            numTicks={data.length}
            label={labelX}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 50,
                textAnchor: 'top',
            }}
            top={yMax}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'middle',
                angle: 45,
            })}
            />
            
            <AxisLeft
            scale={yScale.nice()}
            label={labelY}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                x: -320, 
                textAnchor: 'top',
            }}
            numTicks={5}
            top={0}
            tickLabelProps={(e) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'end',
                x: -12,
                y: (yScale(e) ?? 0) + 3,
            })}
            />
        </Group>
        </svg>
    </>
    )
}

export function BarPlotRH({ data, width, labelX, labelY, height, events = true }) {
    // margins
    const verticalMargin = 120;
    const margins = {
        left: 80,
        right: 70,
    }
    // accessors
    const getXData = (d) => d.x;
    const getYData = (d) => Number(d.y);
    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;

    // scales, memoize for performance
    const xScale = useMemo(
        () =>
        scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(getXData),
            padding: 0.4,
        }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map(getYData))],
        }),
        [yMax],
    );

    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <LinearGradient to="#f093fb" from="#f5576c" id='pink'/>;
        <LinearGradient to="#feada6" from="#f5efef" id='freshmilk'/>;
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={verticalMargin / 2} left={margins.left} right={margins.right}>
            {data.map((d) => {
            const letter = getXData(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYData(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
                <Bar
                key={`bar-${letter}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#f36691"
                onClick={() => {
                    if (events) {
                        alert(`Region: ${(Object.values(d)[0])}, Log-transformed Intensity: ${(Object.values(d)[1])}`);
                    }
                }}
                style={{cursor: "pointer"}}
                />
            )
            })}
            <AxisBottom
            numTicks={data.length}
            label={labelX}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 50,
                textAnchor: 'top',
            }}
            top={yMax}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 11,
                textAnchor: 'top',
                angle: 45,
            })}
            />
            
            <AxisLeft
            scale={yScale.nice()}
            label={labelY}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                x: -320, 
                textAnchor: 'top',
            }}
            numTicks={5}
            top={0}
            tickLabelProps={(e) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'end',
                x: -12,
                y: (yScale(e) ?? 0) + 3,
            })}
            />
        </Group>
        </svg>
    </>
    )
}

export function BarPlotRegion({ data, width, labelX, labelY, height, events = true }) {
    // margins
    const verticalMargin = 120;
    const margins = {
        left: 80,
        right: 70,
    }
    // accessors
    const getXData = (d) => d.x;
    const getYData = (d) => Number(d.y);

    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;

    // scales, memoize for performance
    const xScale = useMemo(
        () =>
        scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(getXData),
            padding: 0.4,
        }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map(getYData))],
        }),
        [yMax],
    );

    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <LinearGradient to="#0ba360" from="#3cba92" id='grownearly'/>;
        <LinearGradient to="#c1dfc4" from="#deecdd" id='softgrass'/>;
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={verticalMargin / 2} left={margins.left} right={margins.right}>
            {data.map((d) => {
            const letter = getXData(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYData(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
                <Bar
                key={`bar-${letter}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="url(#grownearly)"
                onClick={() => {
                    if (events) {
                        alert(`Region: ${(Object.values(d)[0])}, Log-transformed Intensity: ${(Object.values(d)[1])}`);
                    }
                }}
                style={{cursor: "pointer"}}
                />
            )
            })}
            <AxisBottom
            numTicks={data.length}
            label={labelX}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 50,
                textAnchor: 'top',
            }}
            top={yMax}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 11,
                textAnchor: 'top',
                angle: 45,
            })}
            />
            
            <AxisLeft
            scale={yScale.nice()}
            label={labelY}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                x: -320, 
                textAnchor: 'top',
            }}
            numTicks={5}
            top={0}
            tickLabelProps={(e) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'end',
                x: -12,
                y: (yScale(e) ?? 0) + 3,
            })}
            />
        </Group>
        </svg>
    </>
    )
}

/*
 ************************************ 
 * BDTM
 ************************************ 
*/

export function BDTMBarPlotDataConverter(processedGroupData) {
    // only doing it for control v/s disease
    // iterate over all groups
    var dataToBeConverted = processedGroupData[0];
    let convertedDict = [];
    for (var [choice, choiceData] of Object.entries(dataToBeConverted)){
        for (var [sampleId, value] of Object.entries(choiceData)){
            var item = {};
            item.x = sampleId;
            item.y = value;
            item.z = choice;
            convertedDict.push(item);
        }
    }
    return convertedDict;
}

export function scaleData(unscaledDict) {
    var allValues = []
    var scaledDict = {}
    for(var key of Object.keys(unscaledDict)) {
        allValues = allValues.concat(Object.values(unscaledDict[key]))
    }
    var minVal = Math.min(...allValues)
    //console.log("minimun:" , minVal)
    var maxVal = Math.max(...allValues)
    //console.log("maximum:" , maxVal)
    for(var key of Object.keys(unscaledDict)) {
        scaledDict[key] = {}
        /*Object.keys(unscaledDict[key]).forEach((item) => {
            unscaledDict[key][item] = (unscaledDict[key][item] - minVal / (maxVal - minVal))
            console.log("Scaled Value:", unscaledDict[key][item])
        })*/
        for (var subKey of Object.keys(unscaledDict[key])) {
            scaledDict[key][subKey] = ((unscaledDict[key][subKey] - minVal) / (maxVal - minVal))
        }
    }

    return scaledDict
}

export function BDPMBarPlotDataConverter(processedGroupData) {
    // only doing it for control v/s disease
    // iterate over all groups
    var dataToBeConverted = processedGroupData[0];
    //console.log("Barplot Data input:", processedGroupData[0])
    var scaledDataToBeConverted = scaleData(dataToBeConverted)
    //console.log("Barplot Scaled input:", scaledDataToBeConverted)

    let convertedDict = [];
    for (var [choice, choiceData] of Object.entries(scaledDataToBeConverted)){
        for (var [sampleId, value] of Object.entries(choiceData)){
            var item = {};
            item.x = sampleId;
            item.y = value;
            item.z = choice;
            convertedDict.push(item);
        }
    }
    return convertedDict;
}

export function BDDFPlotDataConverter(bddfData, countKey){
    var itemArray = bddfData.map(function (item) { return item[countKey]; });
    const count = {};

    for (const element of itemArray) {
        if (count[element]) {
            count[element] += 1;
        } else {
            count[element] = 1;
        }
    }
    var barData = []
    for(var i of Object.keys(count)){
        var barItem = {}
        barItem.x = i
        barItem.y = count[i]
        barData.push(barItem);
    }
    //console.log("BarPlot Data", barData)
    return [barData,count];
}

export function BarPlotBDTM({ data, width, labelX, labelY, height, events = true }) {
    const numBars = data.length;
    if (numBars > 60) {
        let barPerWidth = width/numBars;
        width += 4*numBars*barPerWidth;
    }

    // margins
    const verticalMargin = 120;
    const margins = {
        left: 80,
        right: 70,
    }
    // accessors
    const getXData = (d) => d.x;
    const getYData = (d) => Number(d.y);
    const getZData = (d) => d.z;

    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;
    


    // scales, memoize for performance
    const xScale = useMemo(
        () =>
        scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(getXData),
            padding: 0.4,
        }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map(getYData))],
        }),
        [yMax],
    );

    function barColor(z){
        if (z == "Control"){
            return "url(#grownearly)";
        }
        else {
            return "url(#red)";
        }
    }

    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <GradientTealBlue id="teal" />
        <GradientPinkRed id="red" />
        <LinearGradient to="#accbee" from="#e7f0fd" id='febink'/>;
        <LinearGradient to="#0ba360" from="#3cba92" id='grownearly'/>;
        <LinearGradient id="bdtmbarplot" to="#c4b1fc" from="#bbfae9" />
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={verticalMargin / 2} left={margins.left} right={margins.right}>
            {data.map((d) => {
            const letter = getXData(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYData(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            const fillColor = barColor(getZData(d));
            return (
                <Bar
                key={`bar-${letter}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill = {fillColor}
                onClick={() => {
                    if (events) {
                        alert(`SampleID: ${(Object.values(d)[0])}, Log-transformed Intensity: ${(Object.values(d)[1])}`);
                    }
                }}
                style={{cursor: "pointer"}}
                />
            )
            })}
            <AxisBottom
            numTicks={data.length}
            label={labelX}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 50,
                textAnchor: 'middle',
            }}
            top={yMax}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'top',
                angle: 45,
            })}
            />
            
            <AxisLeft
            scale={yScale.nice()}
            label={labelY}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                x: -320, 
                textAnchor: 'top',
            }}
            numTicks={5}
            top={0}
            tickLabelProps={(e) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'end',
                x: -12,
                y: (yScale(e) ?? 0) + 3,
            })}
            />
        </Group>
        </svg>
    </>
    )
}

export function BarPlotBDDF({ data, width, labelX, labelY, height, events = true }) {
    // margins
    const verticalMargin = 130;
    const margins = {
        left: 70,
        right: 50,
    }
    // accessors
    const getXData = (d) => d.x;
    const getYData = (d) => Number(d.y);

    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;

    // scales, memoize for performance
    const xScale = useMemo(
        () =>
        scaleBand({
            range: [0, xMax],
            round: true,
            domain: data.map(getXData),
            padding: 0.4,
        }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
        scaleLinear({
            range: [yMax, 0],
            round: true,
            domain: [0, Math.max(...data.map(getYData))],
        }),
        [yMax],
    );

    return width < 10 ? null : (
    <>
        <svg width={width} height={height}>
        <GradientTealBlue id="teal" />
        <LinearGradient to="#accbee" from="#e7f0fd" id='febink'/>;
        <rect width={width} height={height} fill="white" rx={14} />
        <Group top={verticalMargin/3} left={margins.left} right={margins.right}>
            {data.map((d) => {
            const letter = getXData(d);
            const barWidth = xScale.bandwidth();
            const barHeight = yMax - (yScale(getYData(d)) ?? 0);
            const barX = xScale(letter);
            const barY = yMax - barHeight;
            return (
                <Bar
                key={`bar-${letter}`}
                x={barX}
                y={barY}
                width={barWidth}
                height={barHeight}
                fill="#44a3e3"
                onClick={() => {
                    if (events) {
                        alert(`${labelX}: ${(Object.values(d)[0])}, ${labelY}: ${(Object.values(d)[1])}`);
                    }
                }}
                style={{cursor: "pointer"}}
                />
            )
            })}
            <AxisBottom
            numTicks={data.length}
            label={labelX}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 60,
                x: width/3,
                textAnchor: 'top',
            }}
            top={yMax}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 16,
                textAnchor: 'middle',
                angle: 20,
            })}
            />
            
            <AxisLeft
            scale={yScale.nice()}
            label={labelY}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                x: -(height-verticalMargin)/2 - 80,
                textAnchor: 'top',
            }}
            numTicks={5}
            top={0}
            tickLabelProps={(e) => ({
                fill: '#000000',
                fontSize: 16,
                textAnchor: 'end',
                x: -12,
                y: (yScale(e) ?? 0) + 3,
            })}
            />
        </Group>
        </svg>
    </>
    )
}