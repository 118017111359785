import React from "react";
import { scaleLinear, scaleLog, scaleSqrt, scaleOrdinal } from "@visx/scale";
import { Text } from '@visx/text';
import { Circle } from "@visx/shape";
import { Group } from "@visx/group";
import { Axis, AxisLeft } from "@visx/axis";
import { GridRows, GridColumns } from "@visx/grid";
import { LegendOrdinal } from "@visx/legend";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { useTooltip, TooltipWithBounds, defaultStyles } from "@visx/tooltip";
import { useRef, useMemo, useCallback } from "react";
import { localPoint } from "@visx/event";
import { voronoi } from "@visx/voronoi";
import { colors } from "@material-ui/core";

//console.log(wbData);

const scatterData = [{
  "geneName": "EFGR",
  "DISGENET_Score": 0.025,
  "Pubpular_Score": 0.482,
  "Harmonizome_Score": 0.118,
  "Bionda_Score": 0.91,
  "disease": "Meningioma"
},
{
  "geneName": "NEFM",
  "DISGENET_Score": 0.625,
  "Pubpular_Score": 0.782,
  "Harmonizome_Score": 0.018,
  "Bionda_Score": 0.61,
  "disease": "Meningioma"
}]

/*
function getCoordsFromData(x,y) {
  xCoords = []
  yCoords = []
}
*/

function nullPoint(condition){
  if(condition==true){
    return 0
  }
  else {
    return 0.8
  }
}

function pointer(condition){
  if(condition==true){
    return {cursor: "default"}
  }
  else {
    return {cursor: "pointer"}
  }
}

export const ScatterPlot = ({
  data = scatterData,
  x_score = "DISGENET_Score",
  y_score = " Harmonizome_Score",
  colorDot = "#2cb67d",
  width,
  height,
  margin = { top: 50, left: 60, right: 40, bottom: 50 }
}) => {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const x = (d) => d[x_score];
  const y = (d) => d[y_score];
  const color = (d) => d.geneName;

  var removeByAttr = function(arr, attr, value){
    var i = arr.length;
    while(i--){
        if((!arr[i][x_score]) || (!arr[i][y_score])){ 

            arr.splice(i,1);

        }
    }
    return arr;
  }
  removeByAttr(data)
  function getMaxMinVal(x_score, y_score) {
    var xVals = data.map(function (el) { return el[x_score] });
    var yVals = data.map(function (el) { return el[y_score] });

    var xscaleMax = Math.max(...xVals)
    var yscaleMax = Math.max(...yVals)
    var xscaleMin = Math.min(...xVals)
    var yscaleMin = Math.min(...yVals)

    var dict = {xMax: xscaleMax, yMax:yscaleMax, xMin:xscaleMin, yMin: yscaleMin}
    //console.log(dict);
    return dict

  }

  const xScale = scaleLinear({
    range: [margin.left, innerWidth + margin.left],
    domain: [getMaxMinVal(x_score, y_score).xMin, getMaxMinVal(x_score, y_score).xMax + 0.05],
    nice: true
  });

  const yScale = scaleLinear({
    range: [innerHeight + margin.top, margin.top],
    domain: [getMaxMinVal(x_score, y_score).yMin, getMaxMinVal(x_score, y_score).yMax + 0.05],
    nice: true
  });

  /* function displayLabel(x,y,point){
    return (
      <Text
        x={x}
        y={y}
        textAnchor="start"
        verticalAnchor="middle"
      >
        {point.geneName}
        {console.log("Displayed Text!", point.geneName)}
      </Text>
    )
  } */

  /*const colorScale = scaleOrdinal({
    range: ["#ff8906", "#3da9fc", "#ef4565", "#7f5af0", "#2cb67d"],
    domain: [...new Set(data.map(color))]
  }); */

  // Sort the data
  data = data.sort((a, b) => b.x_score - a.x_score);
  //var x_data = data.map(function (el) { return el[x_score] });
  //var y_data = data.map(function (el) { return el[y_score] });
  //var x_nonZero = x_data.filter(v => !v).length;
  //var y_nonZero = y_data.filter(v => !v).length;
  //console.log("y_Data", y_data);
  
  if(data.length>5){
  return (
    <>
      
      <svg width={width} height={height}>
        <rect
          x={margin.left}
          y={margin.top}
          width={innerWidth}
          height={innerHeight}
          fill="#ffffff"
        />
        <AxisLeft scale={yScale} left={margin.left} label={y_score} />
        { /*<Axis
          orientation="top"
          scale={xScale}
          top={margin.top}
        /> */}
        <Axis
          orientation="bottom"
          scale={xScale}
          top={innerHeight + margin.top}
          label={x_score}
        />
        <GridColumns
          top={margin.top}
          scale={xScale}
          height={innerHeight}
          strokeOpacity={0.8}
          pointerEvents="none"
          
        />
        <GridRows
          left={margin.left}
          scale={yScale}
          strokeOpacity={0.8}
          width={innerWidth}
          pointerEvents="none"
        />
        <Group>
          {data.map((point, i) => (
            <Circle
              key={i}
              cx={xScale(x(point))}
              cy={yScale(y(point))}
              r={8}
              fill={colorDot}
              fillOpacity={nullPoint(!x(point) || !y(point))}
              stroke="black"
              strokeOpacity={nullPoint(!x(point) || !y(point))}
              /*stroke={
                tooltipData === point ? "#ffffff" : "black"
              }*/
              //onMouseOver
              onClick={() => {
                    alert(`Gene ID: ${point.geneName} \n DisGeNet: ${point.DISGENET_Score} \n Harmonizome: ${point.Harmonizome_Score} \n PubPular: ${point.Pubpular_Score} \n CTD: ${point.CTD_Score} \n Disease 2.0: ${point.D2_Score}`);
            }}
            style={pointer(!x(point) || !y(point))}
            />
          ))}
        </Group>
      </svg>
    </>
  );
  }
  else{
    return (
      <Group>
        <Text
          x={width / 2}
          y={height / 2}
          width={width}
          verticalAnchor="start"
          textAnchor="middle"
          style={{ fontSize: '60px'}}
        >
          No Preview!
        </Text>
      </Group>
    )
  }
};