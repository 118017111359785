import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, UncontrolledCarousel} from 'reactstrap';
import { Hero } from '../components/common/hero';
import MainAccordion from '../components/common/mainAccordion';
import { MainFooter } from '../components/common/mainFooter';
import { MainNavBar } from '../components/common/mainNavBar';
import { MainSearchBar } from '../components/common/mainSearchBar';
import { LineSeparator } from '../components/common/separatorLine';
import useMediaQuery from '../hooks/useMediaQuery';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

const carouselItems = [
    {
        src: '/images/HBDA_Icon.png',
        altText: 'Human Brain Disease Atlas (HBDA)',
        header: 'Human Brain Disease Atlas (HBDA)',
        key: 1,
        caption: 'This domain serves as the index and navigator of BrainProt, which provides a catalogue of different IDs for more than 50 Brain associated diseases and allows easy access to other domains of a query disease'
    },
    {
        src: '/images/IBPM_Icon.png',
        altText: 'Inter-Hemisphere Brain Proteome Map (IBPM)',
        header: 'Inter-Hemisphere Brain Proteome Map (IBPM)',
        key: 1,
        caption: 'This domain deciphers the expression profile of proteins and phosphosites identified in the left and right hemispheres of different neuroanatomical regions and sub-regions of the Human Brain'
    },
    {
        src: '/images/BDMC_Icon.png',
        altText: 'Brain Disease Marker Curator (BDMC)',
        header: 'Brain Disease Marker Curator (BDMC)',
        key: 1,
        caption: 'This domain allows users to identify and select human brain disease associated markers to accelerate biomarker discovery and therapeutic target identification'
    },
    {
        src: '/images/BDTM_Icon.png',
        altText: 'Brain Disease Transcriptome Map (BDTM)',
        header: 'Brain Disease Transcriptome Map (BDTM)',
        key: 2,
        caption: 'This domain allows users to explore the gene expression profile of alarming Brain tumours and Neurodegenerative disorders mined from public repositories and databases'
    },
    {
        src: '/images/BDPM_Icon.png',
        altText: 'Brain Disease Proteome Map (BDPM)',
        header: 'Brain Disease Proteome Map (BDPM)',
        key: 3,
        caption: 'This domain allows users to explore the protein expression profile of alarming Brain tumours and Neurodegenerative disorders mined from public repositories and databases'
    },
    {
        src: '/images/BDDF_Icon.png',
        altText: 'Brain Disease Drug Finder (BDDF)',
        header: 'Brain Disease Drug Finder (BDDF)',
        key: 4,
        caption: 'This domain allows users to curate, select and identify probable drug and chemical compounds against the query disease or (protein/gene) search.'
    }
];

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isBootstrapMD: true
        };
    }

    componentDidMount(){
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
          // fade out
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        }
    }

    render() {
        const svgPath = this.state.isBootstrapMD ? "/images/brainprot_icons_nav.svg" : "/images/brainprot_icons.svg";
        //if (!isBootstrapMD) {
        //    alert("BrainProt is best viewed on a desktop. BrainProt is functional on mobile devices, however the plots are not well optimized for mobile screens.");
        //}
        return (
            <>
                <MainNavBar />
                <Hero />
                <MainSearchBar />
                <Container>
                    <br />
                    <h6 className='text-center'>Search examples: "p07197", "gfap", "lung", "olfactory marker protein"</h6>
                </Container>
                <LineSeparator />
                
                    <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <object className="center-portalnavicon-home" data={svgPath} type="image/svg+xml" />
                    </div>
                    <h4 className="text-center">(Click on an icon above to access the corresponding portal)</h4>
                <br />
                
                <LineSeparator /> 
                <Container>
                    {/* <h4 className="text-center">Click on a tab below to know more about the corresponding portal.</h4> */}
                    <br />
                    <Container>
                        <UncontrolledCarousel id = "hbdaCarousel" style={{height: 200}} items={carouselItems} interval={5000}/>
                    </Container>
                    <br />
                    <div className='text-center' style={{
                        borderRadius: 20,
                        background: "white",
                        padding: 30,
                        color: "black",
                    }}>
                        <h3>New to BrainProt? Check out the <Link to={'/help'}>Help Section</Link> to get started!</h3>

                    </div>
                    <br />
                </Container>
                <MainFooter />
                
            </>
        );
    }
}