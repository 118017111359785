import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Table, Button, UncontrolledCollapse } from 'reactstrap';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { LineSeparator } from '../../components/common/separatorLine';

export default function BDMCLanding() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };

    return (
        <>
            <br />
            <h2 style={{textAlign: "center"}}>Welcome to the Brain Disease Marker Curator (BDMC)</h2>
            <LineSeparator />
            <Container>
            <Row>
                <Col sm='12'>
                    <figure>
                            <img src="/images/bdmc/bdmc_2.svg" alt="BDMC_Image" className="center img_home" style={{'maxHeight': 700}}/>
                    </figure>

                    <div className='center text-center'>
                        <Button style={{backgroundColor: "#fcee21", color: "black"}} href="/bdmc/disease_table"><h3>Disease Table Index</h3><h5>(Click here)</h5></Button>
                    </div>
                    <br />
                    <h2 style={{textAlign: "left"}}>About BDMC</h2>
                    <p style={{fontSize:"20px"}}>
                    Brain Disease Marker Curator (BDMC) allows users to select and identify disease associated markers of more 
                    than 50 Human Brain Diseases. BDMC integrates the power of popular knowledgebases and public 
                    resources like &nbsp;<a href="https://www.disgenet.org/" target='blank'>DisGeNET</a>,&nbsp;  
                    <a href="https://maayanlab.cloud/Harmonizome/dataset/DISEASES+Text-mining+Gene-Disease+Assocation+Evidence+Scores" target='blank'>Harmonizome 3.0</a>,&nbsp; 
                    <a href="https://heart.shinyapps.io/PubPular/" target='blank'>PubPular 3.1</a>,&nbsp;<a href="http://ctdbase.org/" target='blank'>CTD</a>, &nbsp;
                    <a href="https://diseases.jensenlab.org/Search" target='blank'>Disease 2.0</a>,
                    &nbsp;<a href="http://bionda.mpc.ruhr-uni-bochum.de/start.php" target="blank">BIONDA</a>, 
                    and&nbsp;<a href="http://edgar.biocomp.unibo.it/gene_disease_db/" target='blank'>eDGAR</a>, &nbsp;to calculate the BDMC score. 
                    The domain aims to accelerate the quest of search for brain disease biomarker.
                    </p>
                    <br />
                    <h5>Last Update </h5>
                    15 May 2023
                    <br />
                    <br />
                    <h4>Publications</h4>
                    No Publication Yet!
                </Col>
                </Row>
            </Container>              
           
        </>
    );
}