export function LegendTemplate({ title, children }) {
    return (
      <div className="legend">
        <div className="legend-title">{title}</div>
        {children}
        <style jsx>{`
          .legend {
            line-height: 0.9em;
            color: #efefef;
            font-size: 20px;
            font-family: arial;
            padding: 10px 10px;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            margin: 5px 5px;
          }
          .legend-title {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 100;
            text-align: center;
          }
          .legends {
            font-family: arial;
            font-weight: 900;
            border-radius: 14px;
            padding: 10px 10px 10px 10px;
            overflow-y: auto;
            flex-grow: 1;
            width: 300px;
          }
          .chart h2 {
            margin-left: 0px;
          }
        `}</style>
      </div>
    );
  }