import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Container,
  UncontrolledCarousel
} from 'reactstrap';

const items = [
  {
    id: 1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    src: 'https://picsum.photos/id/123/1200/600'
  },
  {
    id: 2,
    altText: 'Slide 2',
    caption: 'Slide 2',
    src: 'https://picsum.photos/id/456/1200/600'
  },
  {
    id: 3,
    altText: 'Slide 3',
    caption: 'Slide 3',
    src: 'https://picsum.photos/id/678/1200/600'
  },
];

export default function Gallery() {
  const ele = document.getElementById('ipl-progress-indicator');
  if(ele){
      ele.classList.add('available');
      setTimeout(() => {
          ele.remove();
      }, 2000)
  };
  return (
    <>
        <Container fluid>
            <h1 style={{textAlign: "center"}}>Gallery</h1>
            <h4 style={{textAlign: "center"}}>Some candid moments of the BrainProt Team</h4>
            <br />
            <Container>
                <UncontrolledCarousel
                items={[
                    {
                        altText: 'BrainProt Preview Presentation',
                        caption: 'Prof. Sanjeeva Srivastava showcasing demo of the new BrainProt',
                        key: 1,
                        src: '/images/concourse/bp_preview.jpg'
                    },
                    {
                        altText: 'Error 500: Internal Error',
                        caption: 'When our development environment crashed ...',
                        key: 2,
                        src: '/images/concourse/server_crash.jpg'
                    },
                    {
                        altText: 'New v/s Old',
                        caption: 'Hard at work updating BrainProt ;)',
                        key: 3,
                        src: '/images/concourse/transition.jpeg'
                    },
                    {
                        altText: 'BrainFood',
                        caption: 'BrainProt requires BrainFood :p',
                        key: 4,
                        src: '/images/concourse/bp_lunch.jpg'
                    }
                ]}
                />
            </Container>    
        </Container>
    </>
  );
}