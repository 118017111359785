import './App.css';
import { ThemeContext, themes } from './contexts/themeContext';
import React from 'react';
import { Base } from './components/base';
import { MainFooter } from './components/common/mainFooter';
import { Outlet, Link } from "react-router-dom";
import { LineSeparator } from './components/common/separatorLine';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default class App extends React.Component {
  
  render() {
  return (
    <>
        <Base />
        <Outlet />
        <MainFooter />
    </>
  );
  }
}