import React from 'react';
import { Button, Container, UncontrolledCollapse, Row, Col, Table } from 'reactstrap';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function BDTMLanding() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };
    return (
        <>
            <br />
            <h1 style={{textAlign: "center"}}>Welcome to the Brain Disease Transcriptome Map (BDTM)</h1>
            <LineSeparator />
            <MainSearchBar defPortal={'BDTM'}/>
            <LineSeparator />
            <Container>
                <figure>
                    <img src="/images/bdtm/bdtm_home.png" alt="BDTM Main Graphic" className="center"/>
                </figure>
                <p style={{fontSize: "20px"}}>
                Brain Disease Transcriptome Map (BDTM) integrates transcriptomics profile of alarming brain diseases like Alzheimer's disease, 
                Parkinson's disease, Multiple sclerosis, Huntington’s diseases, Amyotrophic lateral sclerosis, Fronto-temporal lobar degeneration, 
                Meningioma, Medulloblastoma, Glioma/Glioblastoma and Pituitary adenoma. This domain of BrainProt allows users to compare, visualise 
                and understand the expression of genes in different brain diseases acquired from public repositories like GEO, OmicsDI and ArrayExpress 
                through simplified, interactive visualisation plots. 
                </p>
                
                <h5>Last Updated:</h5>
                20 April 2023
                <br />
                <br />
                <h4>Publications</h4>
                <h5>Coming Soon!</h5>
            </Container>
        </>
    );
}