import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

function MainAccordion(props) {
  const [open, setOpen] = useState('0');
  const toggle = (id) => {
    if (open === id) {
      setOpen('0');
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion open={open} toggle={toggle} className='bg-dark'>
        <AccordionItem id='hbda-accordion'>
          <AccordionHeader targetId="6"><h3>Human Brain Disease Atlas (HBDA)</h3></AccordionHeader>
          <AccordionBody accordionId="6">
          This domain serves as the index and navigator of BrainProt, 
          which provides a catalogue of different IDs for more than 50 Brain associated diseases and 
          allows easy access to other domains of a query disease.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='ibpm-accordion'>
          <AccordionHeader targetId="1"><h3>Inter-Hemisphere Brain Proteome Map (IBPM) with PhosphoMap</h3></AccordionHeader>
          <AccordionBody accordionId="1">
            This domain deciphers the expression profile of proteins and phosphosites
            identified in the left and right hemispheres of different neuroanatomical regions and sub-
            regions of the Human Brain.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdtm-accordion'>
          <AccordionHeader targetId="2"><h3>Brain Disease Transcriptome Map (BDTM)</h3></AccordionHeader>
          <AccordionBody accordionId="2">
            This domain of BrainProt shows the gene expression profile of alarming Brain tumors and
            Neurodegenerative disorders mined from public repositories like ArrayExpress and Gene
            Expression Omnibus (GEO).
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdpm-accordion'>
          <AccordionHeader targetId="3"><h3>Brain Disease Proteome Map (BDPM)</h3></AccordionHeader>
          <AccordionBody accordionId="3">
            This domain of BrainProt shows the protein expression profile and identified peptides of
            alarming Brain tumors and Neurodegenerative disorders mined from public repositories like
            ProteomeXchange and OmicsDi.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdmc-accordion'>
          <AccordionHeader targetId="4"><h3>Brain Disease Marker Curator (BDMC)</h3></AccordionHeader>
          <AccordionBody accordionId="4">
          This domain allows users to identify and select human brain disease associated markers to 
          accelerate biomarker discovery and therapeutic target identification.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bddf-accordion'>
          <AccordionHeader targetId="5"><h3>Brain Disease Drug Finder (BDDF)</h3></AccordionHeader>
          <AccordionBody accordionId="5">
            This domain of BrainProt allows users to curate, select and identify probable drug and
            chemical compounds against the query (protein/gene) search. BDDF is a platform that
            integrates popularly available drug repositories and knowledgebases.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default MainAccordion;