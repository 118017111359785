import 'mathjs';
import React from "react";
import { Group } from "@visx/group";
import { Text } from '@visx/text';
import { BoxPlot, ViolinPlot } from "@visx/stats";
import { LinearGradient } from "@visx/gradient";
import { scaleBand, scaleLinear } from "@visx/scale";
import { AxisBottom, AxisLeft } from '@visx/axis'
import { VerticalAlignCenter } from '@material-ui/icons';

export function boxPlotDataConverter(studyId, processedGroupData){
  // FOR NOW ONLY GROUP I
  // for all groups loop over processed groupData
  var dataToBeConverted = processedGroupData[0];
  var boxLabels = [];
  var unconvertedDataList = [];
  var outputData = [];
  //console.log(studyId);
  //console.log("data", processedGroupData);
  
  
  for (var [choice, choiceData] of Object.entries(dataToBeConverted)){ 
    boxLabels.push(choice);
    unconvertedDataList.push(choiceData);
  };

  for (var choiceIndex = 0; choiceIndex < unconvertedDataList.length; choiceIndex++){
    
    let choiceData = Object.values(unconvertedDataList[choiceIndex]).sort((a,b) => a-b);
    //console.log("choicedatalen", choiceData.length);

    // Prevention of Third Quartile not to exit the length of array
    // for length of data is 2
    if (choiceData.length < 3) {
      //console.log("choicedData", choiceData);
      continue
    } else {
      var thirdQuartile = choiceData[Math.round(3 * choiceData.length / 4)];
      var firstQuartile = choiceData[Math.round(choiceData.length / 4)];
    };
    var IQR = thirdQuartile - firstQuartile;
    var min = firstQuartile - 1.5 * IQR;
    var max = thirdQuartile + 1.5 * IQR;
    var outliers = [];
    var nonOutliers = [];
    for (var j = 0; j <= choiceData.length; j += 1){
      if(choiceData[j]<min || choiceData[j]>max){
        outliers.push(choiceData[j]);
      } else{
        nonOutliers.push(choiceData[j]);
      }
    }
    var binWidth = 2 * IQR * Math.pow(choiceData.length - outliers.length, -1 / 3);
    try {
      var binNum = Math.round((max - min) / binWidth);
      var actualBinWidth = (max - min) / binNum;
      var bins = new Array(binNum + 2).fill(0);
      //console.log("BoxLabels:", boxLabels, "bins:", bins);
      var values = new Array(binNum + 2).fill(min);
    } catch (error) {
      continue;
    }
    
    for (var ii = 1; ii <= binNum; ii += 1) {
      values[ii] += actualBinWidth * (ii - 0.5);
    }

    values[values.length - 1] = max;
    nonOutliers.forEach(function (p) {
    bins[Math.floor((p - min) / actualBinWidth) + 1] += 1;
    });
    var binData = values.map(function (v, index) {
    return {
        value: v,
        count: bins[index]
    };
    });

    var boxPlot = {
    x: boxLabels[choiceIndex],
    min: min,
    firstQuartile: firstQuartile,
    median: choiceData[Math.round(choiceData.length / 2)],
    thirdQuartile: thirdQuartile,
    max: max,
    outliers: outliers
    };
    
    outputData.push({
    boxPlot: boxPlot,
    binData: binData
    });
  };

  //console.log("OutputData:", outputData)
  return outputData;
}


/*const boxdata = [{boxPlot: {x: "Disease", min: 2.6139110401778707, firstQuartile: 9.130884065122737,
median: 11.111866632289649, thirdQuartile: 13.475532748419315, max: 19.992505773364183, 
outliers: [-0.42727291849413795, 20.059528784611402]}, binData: [{value: 2.6139110401778707, count:0},
{value:3.0483759085075284, count:2}, {value: 3.917305645166844, count:7}]}, {boxPlot: {x: "Control", 
min: 3.8447207263622065, firstQuartile: 9.393268751255551, median: 11.18595803719787, 
thirdQuartile: 13.092300767851114, max: 18.640848792744457, 
outliers:[2.407056972653118, 2.532767812191537, 18.9231530646086, 20.79934178009067]},
binData: [{value: 3.8447207263622065, count:0}, {value:4.214623928021763, count:5}, 
{value: 4.954430331340875, count:8}, {value: 5.694236734659988, count:20}]}]*/

const arr1 = [7.110791661,  7.378615902, 6.51002193]
  const arr2 = [ 7.368286885, 7.276967038, 0, 0, 7.024772913, 7.268086304, 7.33241826, 6.717845184, 0, 7.256862006, 0, 7.103290472, 0, 6.805595998, 0, 7.495530462, 0, 7.391993072, 7.443372525, 0, 7.06483222, 7.67182056]
  var ttest2 = require( '@stdlib/stats-ttest2' )
  var ttest = ttest2(arr1,arr2);
  var p=ttest['pValue']

  function getStars(p) {
    if (p < 0.001) {
      return "***";
    } else if (p < 0.01) {
      return "**";
    } else if (p < 0.05) {
      return "*";
    } else {
      return "ns";
    }
  }


const x = (d) => d.boxPlot.x;
const min = (d) => d.boxPlot.min;
const max = (d) => d.boxPlot.max;
const median = (d) => d.boxPlot.median;
const firstQuartile = (d) => d.boxPlot.firstQuartile;
const thirdQuartile = (d) => d.boxPlot.thirdQuartile;
const outliers = (d) => d.boxPlot.outliers;

export function BDTMBoxPlot({width, height, data, labelBox, events = true, pValue = p}) {
    
    // bounds
    const xMax = width;
    const yMax = height - 100;
    const yaxisMax = yMax;
    const margins = {
      left: 20,
      right: 70,
  }

    // scales
    const xScale = scaleBand({
      range: [0, xMax],
      round: true,
      domain: data.map(x),
      padding: 0.4
    });

    
    const values = data.reduce((allValues, { boxPlot }) => {
      allValues.push(boxPlot.min, boxPlot.max);
      return allValues;
    }, []);
    const minYValue = Math.min(...values);
    const maxYValue = Math.max(...values);

    const domainWidth = maxYValue - minYValue;
    const offsetWidth = 0.1*domainWidth;

    const yScale = scaleLinear({
      range: [yMax, 0],
      round: true,
      domain: [minYValue-offsetWidth, maxYValue + offsetWidth]
    });

    /*function getYScale(d){
      const scaleOutliers = [].concat(outliers(d));
      scaleOutliers.push(min(d), max(d));
      const scaleMin = Math.min(...scaleOutliers);

      const scaleMax = Math.max(...scaleOutliers);
      const domainWidth = scaleMax - scaleMin;
      const offsetWidth = 0.05*domainWidth;
      const yScale = scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [scaleMin-offsetWidth, scaleMax+offsetWidth]
      });
      return yScale;
    }*/

    const boxWidth = xScale.bandwidth();
    const numBoxes = data.length;
    const boxWidthOffset = 30;
    const violinWidthOffset = boxWidthOffset*0.1;
    const topOffset = 40;
    // const constrainedWidth = boxWidth;

    function boxColor(x){
      if (x == "Control"){
          return "url(#grownearly)";
      }
      else {
          return "url(#red)";
      }
    }
    /*
      <ViolinPlot
        data={d.binData}
        stroke="#FFFFFF"
        strokeWidth={1}
        left={xScale(x(d)) + violinWidthOffset/numBoxes}
        width={boxWidth - violinWidthOffset}
        valueScale={getYScale(d)}
        fill={boxColor(x(d))}
        fillOpacity={0.3}
      />
    */
  if (data.length>1){
    return width < 10 ? null : (
      <div style={{ position: "relative" }}>
        <svg width={width} height={height}>
          <LinearGradient to="#accbee" from="#e7f0fd" id='febink'/>;
          <rect width={width} height={height} fill="white" rx={14} />
          <Group top={topOffset} left={margins.left}>
            {data.map((d, i) => (
              <g key={i}>
                <BoxPlot
                  min={min(d)}
                  max={max(d)}
                  left={xScale(x(d)) + boxWidthOffset/numBoxes - margins.left}
                  firstQuartile={firstQuartile(d)}
                  thirdQuartile={thirdQuartile(d)}
                  median={median(d)}
                  boxWidth={boxWidth - boxWidthOffset}
                  fill = {boxColor(x(d))}
                  fillOpacity={1}
                  stroke="#000000"
                  strokeWidth={2}
                  valueScale={yScale}
                  outliers={outliers(d)}
                  boxProps = {{
                    onClick: () => {
                      if (events) {
                          alert(`Maximum: ${max(d)} \n Third Quartile: ${thirdQuartile(d)} \n Median: ${median(d)} \n First Quartile: ${firstQuartile(d)} \n Minimum: ${min(d)} \n Outliers: ${outliers(d)}`);
                      }
                    },
                    style: {cursor: "pointer"}
                  }}
                />
              </g>
            ))}
                <AxisLeft
                  scale={yScale}
                  left={xScale(data[0].boxPlot.x) - boxWidthOffset/numBoxes}
                  top={0}
                  label="Processed Intensity"
                  labelProps={() => ({
                    fill: '#000000',
                    fontSize: 20,
                    x: -320,
                    textAnchor: 'middle',
                })}
                  tickLabelProps={(e) => ({
                      fill: '#000000',
                      fontSize: 12,
                      textAnchor: 'end',
                      x: -12,
                  })}
                />
          </Group>

          <line 
            x1={xScale(data[0].boxPlot.x) + boxWidth / 2} 
            y1={yScale(maxYValue)}
            x2={xScale(data[1].boxPlot.x) +boxWidth / 2} 
            y2={yScale(maxYValue)}
            stroke="black"
            strokeWidth="2"
          />
          <line 
            x1={xScale(data[1].boxPlot.x) +boxWidth / 2}
            x2={xScale(data[1].boxPlot.x) +boxWidth / 2}
            y1 = {yScale(maxYValue) - 8}
            y2 = {yScale(maxYValue) + 8}
            stroke="black"
            strokeWidth="2"
          />
          <line 
            x1={xScale(data[0].boxPlot.x) + boxWidth / 2}
            x2={xScale(data[0].boxPlot.x) + boxWidth / 2}
            y1 = {yScale(maxYValue) - 8}
            y2 = {yScale(maxYValue) + 8}
            stroke="black"
            strokeWidth="2"
          />
          {p && (
            <g transform={`translate(${((xScale(data[0].boxPlot.x) + boxWidth / 2) +(xScale(data[1].boxPlot.x) +boxWidth / 2)) / 2}, ${yScale((maxYValue)) })`}>
              <text
                textAnchor="middle"
                fontSize="20"
              >
                {getStars(pValue)}
              </text>
            </g>
          )}
          <AxisBottom
            hideTicks
            numTicks={data.length}
            label={labelBox}
            labelProps = {{
                fill: '#000000',
                fontSize: 20,
                y: 50,
                textAnchor: 'middle',
            }}
            top={yaxisMax + topOffset}
            scale={xScale}
            tickLabelProps={(value, index) => ({
                fill: '#000000',
                fontSize: 12,
                textAnchor: 'middle',
                angle: 0,
            })}
            />
        </svg>
      </div>
    );
  } else{
    return (
      <div style={{
        borderRadius: 20,
        background: "white",
        height: 700
      }}>
        <Text
          x={width / 2}
          y={height / 2}
          width={width}
          verticalAnchor="start"
          textAnchor="middle"
          style={{ fontSize: '30px'}}
        >
          No Preview!
        </Text>
      </div>
    );
  }
}