import React, { useMemo } from 'react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { GradientPinkBlue, GradientPurpleRed, GradientTealBlue, LinearGradient } from '@visx/gradient'
import letterFrequency, {
    LetterFrequency,
} from '@visx/mock-data/lib/mocks/letterFrequency'
import { scaleBand, scaleLinear } from '@visx/scale'

export const pumpkin = '#f5810c';
export const blue = '#0acffe';
export const green = '#9be15d';

/*

TODO: Generalize this

export function lollipopPlotDataConverter(dictData) {
    this.peptideDataLH = [];
        this.peptideDataRH = [];
        for (var peptide in this.rawPeptideData) {
            var entryLH = {};
            var entryRH = {};
            entryLH.y = this.rawPeptideData[peptide].Phospho_LH_Average;
            entryRH.y = this.rawPeptideData[peptide].Phospho_RH_Average;
            entryLH.x = this.rawPeptideData[peptide].phosphoPos;
            entryRH.x = this.rawPeptideData[peptide].phosphoPos;
            entryLH.z = this.rawPeptideData[peptide].phosphoAminoAcid;
            entryLH.z = this.rawPeptideData[peptide].phosphoAminoAcid;
            this.peptideDataLH.push(entryLH);
            this.peptideDataRH.push(entryRH);
        }
}
*/

export function getZColor(z) {
    switch (z) {
        case 'S':
            return pumpkin;
        case 'T':
            return blue;
        case 'Y':
            return green;
        default:
            console.error("Out of Bounds");
    }
}

export default function LollipopPlotLH({ data, width, labelX, labelY, height, events = true }) {
    // margins
    const verticalMargin = 120;
    const margins = {
        left: 80,
        right: 70,
    }
    // accessors
    const getXData = (d) => Number(d.x);
    const getYData = (d) => Number(d.y);
    const getZData = (d) => getZColor(d.z);


    // bounds
    const xMax = width - margins.left;
    const yMax = height - verticalMargin;

    // scales, memoize for performance
    const xScale = useMemo(
        () =>
            scaleBand({
                range: [0, xMax],
                round: true,
                domain: data.map(getXData).sort(function(a, b) {
                    return a - b;
                  }),
                padding: 0.4,
            }),
        [xMax],
    );

    const yScale = useMemo(
        () =>
            scaleLinear({
                range: [yMax, 0],
                round: true,
                domain: [0, Math.max(...data.map(getYData))],
            }),
        [yMax],
    );

    return width < 10 ? null : (
        <>
            <svg width={width} height={height}>
                <LinearGradient to="#e6e9f0" from="#eef1f5" id='snowagain' />;
                <LinearGradient to="#434343" from="#000000" id='premiumdark' />;
                <rect width={width} height={height} fill="white" rx={14} id="rect1" />
                <Group top={verticalMargin / 2} left={margins.left} right={margins.right}>
                <AxisBottom
                        numTicks={data.length}
                        hideTicks
                        label={labelX}
                        labelProps={{
                            fill: '#000000',
                            fontSize: 20,
                            y: 50,
                            textAnchor: 'top',
                        }}
                        top={yMax}
                        scale={xScale}
                        tickLabelProps={(value, index) => ({
                            fill: '#000000',
                            fontSize: 11,
                            textAnchor: 'middle',
                            angle: 0,
                            transform: ""
                        })}
                    />

                    <AxisLeft
                        scale={yScale.nice()}
                        label={labelY}
                        labelProps={{
                            fill: '#000000',
                            fontSize: 20,
                            x: -290,
                            textAnchor: 'top',
                        }}
                        numTicks={5}
                        top={0}
                        tickLabelProps={(e) => ({
                            fill: '#000000',
                            fontSize: 12,
                            textAnchor: 'end',
                            x: -12,
                            y: (yScale(e) ?? 0) + 3,
                        })}
                    />
                    {data.map((d) => {
                        const letter = getXData(d);
                        const barWidth = 4;
                        const barHeight = yMax - (yScale(getYData(d)) ?? 0);
                        const barX = xScale(letter) +(xScale.bandwidth() / 2) - (barWidth/2);
                        const barY = yMax - barHeight;
                        return (
                            <>
                                <Bar
                                    key={`bar-${letter}`}
                                    x={barX}
                                    y={barY}
                                    width={barWidth}
                                    height={barHeight}
                                    fill="url(#premiumdark)"
                                    onClick={() => {
                                        if (events) {
                                            alert(`Peptide: ${(Object.values(d)[3])}, Probability: ${(Object.values(d)[0])}, Position: ${(Object.values(d)[1])}, Amino Acid: ${(Object.values(d)[2])}`);
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                                <circle key={`radar-point-${barX}`} cx={barX + (barWidth / 2)} cy={barY} r={8} fill={getZData(d)}
                                    onClick={() => {
                                        if (events) {
                                            alert(`Peptide: ${(Object.values(d)[3])}, Probability: ${(Object.values(d)[0])}, Position: ${(Object.values(d)[1])}, Amino Acid: ${(Object.values(d)[2])}`);
                                        }
                                    }}
                                    style={{ cursor: "pointer", zIndex: 9999 }}
                                />
                            </>
                        )
                    })}
                    
                </Group>
            </svg>
        </>
    )
}
