import React from 'react';
import { useSearchParams } from "react-router-dom";
import { Container, Table } from 'reactstrap';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';
/*
SOLR FIX CORS
http://marianoguerra.org/posts/enable-cors-in-apache-solr/
*/

export default function BDTMSearch() {
    const [searchParams] = useSearchParams();
    return (
        <>
            <MainSearchBar defPortal={'BDTM'}/>
            <LineSeparator />
            <h4>Top 10 results for search query: {searchParams.get('q')}</h4>
            <SearchResult query={searchParams.get('q')} />
        </>
    );
}

export class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            activeTab: '1',
            resultJSX: null
        };
        this.query = this.props.query;
        this.docs = null;
    }

    componentDidMount() {
        if (this.props.query) {
            var searchTerm = this.query;
            fetch(`https://api.brainprot.org/search/${searchTerm}`)
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            resultJSX: this.setupData(result)
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    }
                )
        };
    }

    setupData(result) {
        this.docs = result.response.docs;
        if (this.docs.length == 0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        } else {
            
            return (
                <>
                    <div style={{
                        borderRadius: 20,
                        background: "white",
                        padding: 10,
                        color: "black",
                    }}>
                        <Container fluid>
                            <Table hover borderless>
                            <thead>
                                <tr key={"header"}>
                                    <th key={"bdtm-link"}>
                                        BDTM Link (View Results Here)
                                    </th>
                                    <th key={"pid"}>
                                        Protein ID
                                    </th>
                                    <th key={"gname"}>
                                        Gene Name
                                    </th>
                                    <th key={"length"}>
                                        Protein Length
                                    </th>
                                    <th key={"mass"}>
                                        Protein Mass
                                    </th>
                                    <th key={"protfname"}>
                                        Protein (Full) Name(s)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.docs.map((element) =>
                                    (<tr key={`${element.protein_id}-tr`}>
                                        <td key={`${element.protein_id}-bdtm-link`}><a href={`/bdtm/entry/${element.gene_name}`}>Click Here</a></td>
                                        <th scope="row" key={`${element.protein_id}-th`}>{element.protein_id}</th>
                                        <th scope="row" key={`${element.gene_name}`}>{element.gene_name}</th>
                                        <td key={element.length}>{element.length}</td>
                                        <td key={element.mass}>{element.mass}</td>
                                        <td key={JSON.stringify(element.protein_name_list)}>{JSON.stringify(element.protein_name_list).replace('["','').replace('"]','')}</td>
                                    </tr>)
                                )}
                            </tbody>
                            </Table>
                        </Container>
                    </div>            
                </>
            );
        }
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {
        return (
            <>
                {resultJSX}
            </>
        );
        }
    }
}
//http://localhost:8983/solr/brainprot/select?q=gene_name_list%3AOMP&wt=json

