import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Table, TabPane, Card, CardBody, Button } from 'reactstrap';
import { ScatterPlot} from '../../components/charts/hbdaScatterPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';
import { MDBDataTableV5 } from 'mdbreact';

export default function HBDALanding() {

    return(
        <>

            <LineSeparator />
            <HBDATable />
        </>
    )

}

export class HBDATable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            diseaseTableJSX: null,
        };
        this.diseaseTable = null;
        this.diseaseTableKeys = null;
        this.tableData = {columns:[
            {
                label: 'Disease Name',
                field: 'diseaseName',
                width: 300
            },
            {
                label: 'MeSH ID',
                field: 'MESHID',
                width: 100
            },
            {
                label: 'MedGen UID',
                field: 'MedGenUID',
                width: 100
            },
        ],
            rows: []
        }
        
    }

    componentDidMount() {
        fetch(`https://api.brainprot.org/api/hbda/disease_table`)
        .then((res) => res.json())
        .then((result) => {
                this.setState({
                    isLoaded: true,
                    diseaseTableJSX: this.setupDiseaseData(result),
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            }
        )
    
    }

    

    setupDiseaseData(result){
        this.diseaseTable = result.diseaseTableData;
        this.diseaseTableKeys = Object.keys(result.diseaseTableData[0]);
        this.diseaseTable.map((item, index) => {
            var url_hbda = `/hbda/${item.MESHID}`
            item.diseaseName = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_hbda, "_self")}
                    >
                        {item.diseaseName}
                    </div>
                </div>
            );
            var url_mesh = `https://www.ncbi.nlm.nih.gov/mesh/${item.MESHID}`
            item.MESHID = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_mesh, '_blank')}
                    >
                        {item.MESHID}
                    </div>
                </div>
            );
            var url_medgen = `https://www.ncbi.nlm.nih.gov/medgen/${item.MedGenUID}`
            item.MedGenUID = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_medgen, '_blank')}
                    >
                        {item.MedGenUID}
                    </div>
                </div>
            );
            })
            this.tableData.rows = this.diseaseTable;

            if (this.diseaseTable.length == 0) {
                return (
                    <>
                        <main style={{ padding: "1rem" }}>
                            <h3>Our Search Duck didn't find anything!</h3>
                            <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                            <img src='/images/duck_conf.gif' />
                        </main>
                    </>
                )
            } else {
                
                return (
                    <>
                        <Container className='center'>
                            <br />
                            <Button href='/hbda' style={{backgroundColor: "#f15a24", color: "black"}}><h5>Go back to HBDA Homepage</h5></Button>
                            <LineSeparator />
                            <Card>
                                <CardBody style={{'color': 'black'}}>
                                    <h2 style={{textAlign: 'center'}}>Human Brain Disease Atlas - Index Table</h2>
                                    <h5 style={{textAlign: 'center'}}>Click on any disease's name to access its data in our portal!</h5>
                                    
                                        { /*<Table hover bordered>
                                        <thead>
                                            <tr key={"header"}>
                                                <th>Disease Name (Click to access)</th>
                                                <th>MeSH ID</th>
                                                <th>MedGen UID</th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            {this.unpackDiseaseData(this.diseaseTable)}
                                        </tbody>
                        
                                        </Table> */}
                                        
                                        <MDBDataTableV5 
                                            entries={10}
                                            scrollX
                                            hover
                                            data = {this.tableData}
                                        />
                                </CardBody>
                            </Card>
                        </Container>         
                    </>
                );
            }
    }
    
    /* unpackDiseaseData(diseaseData){
            return(
                <>
                    {diseaseData.map((eachDisease) => (  
                        <tr key={`${eachDisease['MESHID']}-`}>
                            <td key={`${eachDisease['MESHID']}-1`}><a href={`/hbda/${eachDisease['MESHID']}`}>{eachDisease['diseaseName']}</a></td>
                            <td key={`${eachDisease['MESHID']}-2`}><a href={`https://www.ncbi.nlm.nih.gov/mesh/?term=${eachDisease['MESHID']}`} target='blank'>{eachDisease['MESHID']}</a></td>
                            <td key={`${eachDisease['MESHID']}-3`}><a href={`https://www.ncbi.nlm.nih.gov/medgen/?term=${eachDisease['MedGenUID']}`} target='blank'>{eachDisease['MedGenUID']}</a></td>
                        </tr>
                    ))}
                </>
            )
    } */


    render() {

        const { error, isLoaded, diseaseTableJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {

        return(
        <>
            {diseaseTableJSX}   
        </>
        );
    }
    }
}