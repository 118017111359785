import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function BDPMLanding() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };
    return (
        <>
            <br />
            <h1 style={{textAlign: "center"}}>Welcome to the Brain Disease Proteome Map (BDPM)</h1>
            <LineSeparator />
            <MainSearchBar defPortal='BDPM'/>
            <LineSeparator />
            <Container>
                <figure>
                    <img src="/images/bdpm/bdpm_home.png" alt="BDPM Main" className="center"/>
                </figure>
                <p style={{fontSize: "20px"}}>
                Brain Disease Proteome Map (BDPM) allows users to compare, visualise and comprehend the expression of proteins in different brain 
                diseases like Alzheimer's disease, Parkinson's disease, Amyotrophic lateral sclerosis, Meningioma, Glioma/Glioblastoma and Medulloblastoma 
                mined from various public repositories such as <a href='https://www.proteomexchange.org/' target='blank'>ProteomeXchange</a>,&nbsp;
                <a href='https://www.omicsdi.org/' target='blank'>OmicsDI</a>, and <a href='https://www.ebi.ac.uk/pride/' target='blank'>PRIDE</a>. This 
                domain allows users to visualize processed intensity for each dataset. For a better understanding of the disease conditions, users can 
                also access the sample meta-data of each 
                dataset. 
                </p>

                { /*<div className='text-center'>
                <Button color="info" style={{ marginBottom: '1rem' }} id={`datasheet-toggle`}>
                        <h3>View Data Sheet</h3>
                </Button>
                <UncontrolledCollapse toggler={`#datasheet-toggle`}>
                    <figure>
                        <img src="/images/bdpm/bdpm_Table.jpg" style={{width: "95%"}} alt="BDPM Data Sheet" className="center"/>
                    </figure>
                </UncontrolledCollapse>
                </div>  */ }
                
                <h5>Last Updated:</h5>
                20 April 2023
                <br />
                <br />
                <h4>Publications</h4>
                <h5>Coming Soon!</h5>
            </Container>
        </>
    );
}