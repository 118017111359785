import React from 'react';
import { Link, Outlet, useParams } from "react-router-dom";
import { Container, TabContent, Nav, NavItem, NavLink, Table, TabPane, Col, Row } from 'reactstrap';
import classnames from 'classnames';
import { ScatterPlot } from '../../components/charts/scatterPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { LineSeparator } from '../../components/common/separatorLine';
import { HorizontalBar } from '../../components/charts/horizontalBar';
import { MDBDataTableV5 } from 'mdbreact';
import diseaseMap from '../../DiseaseId_Mapping_Sheet.json';
import ReadMoreReact from 'read-more-react';

export default function BDMCResult() {
    let params = useParams();
    let diseaseId = params.diseaseId
    let disease = diseaseMap.find(x => x["MESHID"] === diseaseId);
    let diseaseName = disease["diseaseName"]
    let diseaseDescription = disease["description"]

    return (
        <>
            <br />
            <h4>Result for Disease: {diseaseName} ({diseaseId})</h4>
            <p>
                <ReadMoreReact 
                    text={diseaseDescription  + "  Read Less..."}
                    min={500}
                    ideal={500}
                    max={diseaseDescription.length} 
                    readMoreText="Read More..."
                />
            </p>
            <LineSeparator />
            <Result query={diseaseId} />
        </>
    );
}

function getScoreType(x_score, y_score){
    var scoreType = "DP_Score"
    if((x_score == "DISGENET_Score" && y_score == "Harmonizome_Score") || (y_score == "DISGENET_Score" && x_score == "Harmonizome_Score")) {
    	scoreType = "DH_Score"
    }
    else if((x_score == "DISGENET_Score" && y_score == "Pubpular_Score") || (y_score == "DISGENET_Score" && x_score == "Pubpular_Score")) {
    	scoreType = "DP_Score"
    }
    else if ((x_score == "DISGENET_Score" && y_score == "CTD_Score") || (y_score == "DISGENET_Score" && x_score == "CTD_Score")) {
    	scoreType = "DC_Score"
    }
    else if ((x_score == "Pubpular_Score" && y_score == "Harmonizome_Score") || (y_score == "Pubpular_Score" && x_score == "Harmonizome_Score")) {
    	scoreType = "HP_Score"
    }
    else if ((x_score == "Pubpular_Score" && y_score == "CTD_Score") || (y_score == "Pubpular_Score" && x_score == "CTD_Score")) {
    	scoreType = "PC_Score"
    }
    else if ((x_score == "Harmonizome_Score" && y_score == "CTD_Score") || (y_score == "Harmonizome_Score" && x_score == "CTD_Score")) {
    	scoreType = "HC_Score"
    }
    else if ((x_score == "D2_Score" && y_score == "DISGENET_Score") || (y_score == "D2_Score" && x_score == "DISGENET_Score")) {
    	scoreType = "D2D_Score"
    }
    else if ((x_score == "D2_Score" && y_score == "Pubpular_Score") || (y_score == "D2_Score" && x_score == "Pubpular_Score")) {
    	scoreType = "D2P_Score"
    }
    else if ((x_score == "D2_Score" && y_score == "CTD_Score") || (y_score == "D2_Score" && x_score == "CTD_Score")) {
    	scoreType = "D2C_Score"
    }
    else if ((x_score == "D2_Score" && y_score == "Harmonizome_Score") || (y_score == "D2_Score" && x_score == "Harmonizome_Score")) {
    	scoreType = "D2H_Score"
    }
    else {
    	scoreType = x_score
    }

    return scoreType
}

function getTopGenes(dicts, n , x_score, y_score){
    var scoreType = getScoreType(x_score, y_score)

    var sortedDicts  = dicts.sort((a,b) => b.scoreType - a.scoreType)
    
    /*var tempArr = []
    var topNDict = []
    var topN = (dicts.length < n) ? dicts.length : n
    for( let i = 0; i < dicts.length; i++){
        tempArr.push([dicts[i].geneName, dicts[i][scoreType]]);
    }

    tempArr.sort((a,b) => b[1]-a[1]);

    for(let j = 0; j < topN; j++){
        topNDict.push({geneName:tempArr[j][0], scoreType:tempArr[j][1]})
    }
    console.log("Top Markers list", topNDict);*/

    var topNDict = sortedDicts.slice(0,n);
    return topNDict;
}

function getMarkerDicts(inputList, markerType) {
	var resultList = []
	for( let i = 0; i < inputList.length; i++){
		if(inputList[i][markerType] == 1){
			resultList.push(inputList[i])
		}
	}
	return resultList;
}


export class Result extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            activeTab: 'a',
            activeSubTab: '1',
            resultJSX: null,
            selectedXOption: "DISGENET_Score",
            selectedYOption: "Harmonizome_Score",
            defPortal: "BDMC",
            n : 50,
        };
        this.query = this.props.query;
        this.result = null;
        this.data = null;
        this.tableData = {columns:[
            {
                label: 'Gene Name',
                field: 'geneName',
            },
            {
                label: 'UniProt ID',
                field: 'SwissProt_Accessions',
            },
            {
                label: 'DISGENET Score',
                field: 'DISGENET_Score',
            },
            {
                label: 'Harmonizome Score',
                field: 'Harmonizome_Score',
            },
            {
                label: 'Pubpular Score',
                field: 'Pubpular_Score',
            },
            {
                label: 'CTD Score',
                field: 'CTD_Score',
            },
            {
                label: 'Disease 2.0 Score',
                field: 'D2_Score',
            },
            {
                label: 'BDMC Score',
                field: 'BDMC_Score',
            },
            {
                label: 'Frequency',
                field: 'Frequency',
            },
            {
                label: 'CTD Marker',
                field: 'CTD_Marker',
            },
            {
                label: 'eDGAR Marker',
                field: 'eDGAR_Marker',
            },
            {
                label: 'Disease 2.0 Marker',
                field: 'Dis_2_Marker',
            },
        ],
        rows: []
        }
        this.onChangeXValue = this.onChangeXValue.bind(this);
        this.onChangeYValue = this.onChangeYValue.bind(this);
        this.onChangeNValue = this.onChangeNValue.bind(this);
    }
    
    onChangeNValue(event) {
    this.setState({
        n: parseInt(event.target.value)
        });
    }

    onChangeXValue(event) {
        this.setState({
            selectedXOption: event.target.value
        });
    }
    onChangeYValue(event) {
        this.setState({
            selectedYOption: event.target.value
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }
    toggleSub(subTab) {
        if (this.state.activeSubTab !== subTab) {
          this.setState({
            activeSubTab: subTab
          });
        }
    }

    componentDidMount() {
        if (this.props.query) {
            var diseaseId = this.query;
            fetch(`https://api.brainprot.org/api/bdmc/disease/${diseaseId}`)
            .then((res) => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            resultJSX: this.setupData(result)
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    },
                   
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    }
                )
        };
    }
    

    setupData(result) {
        this.result = result;
        this.data = result.geneList;
        
        if (this.data.length == 0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        } else {

            this.data.map((item, index) => {
                var url = `https://www.uniprot.org/uniprotkb/${item.SwissProt_Accessions}/entry`
                item.SwissProt_Accessions = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url, '_blank')}
                        >
                            {item.SwissProt_Accessions}
                        </div>
                    </div>
                );
            });
            this.tableData.rows = this.data
            
            return (
                <>      
                </>
            );
        }
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {
        return (
            
            <>
                <Container fluid>
                <div>
                    <h5>Select Axis for Scatter Plot:</h5>
                    <b>X</b>
                    &ensp;&ensp;&ensp;&ensp; 

                    <label>
                        <input 
                        type="radio"
                        value="DISGENET_Score"
                        checked={this.state.selectedXOption == "DISGENET_Score"}
                        onChange={this.onChangeXValue}
                        />DISGENET Score
                    </label>

                    &ensp;&ensp;&ensp;&ensp; 
                    
                    <input 
                    type="radio"
                    value="Pubpular_Score"
                    checked={this.state.selectedXOption === "Pubpular_Score"}
                    onChange={this.onChangeXValue} 
                    /> <label> Pubpular Score </label>
                    
                    &ensp;&ensp;&ensp;&ensp; 

                    <input 
                    type="radio"
                    value="Harmonizome_Score"
                    checked={this.state.selectedXOption === "Harmonizome_Score"}
                    onChange={this.onChangeXValue} 
                    /> <label> Harmonizome Score </label>

                    &ensp;&ensp;&ensp;&ensp;
                    
                    <input 
                    type="radio"
                    value="D2_Score"
                    checked={this.state.selectedXOption === "D2_Score"}
                    onChange={this.onChangeXValue} 
                    /> <label> Disease 2.0 Score </label>

                    &ensp;&ensp;&ensp;&ensp;
                    <input 
                    type="radio"
                    value="CTD_Score"
                    checked={this.state.selectedXOption === "CTD_Score"}
                    onChange={this.onChangeXValue} 
                    /> <label>CTD Score</label>

                    <br />

                    <b>Y</b>
                    &ensp;&ensp;&ensp;&ensp; 

                    <label>
                        <input 
                        type="radio"
                        value="DISGENET_Score"
                        checked={this.state.selectedYOption == "DISGENET_Score"}
                        onChange={this.onChangeYValue}
                        />DISGENET Score</label>

                    &ensp;&ensp;&ensp;&ensp; 
                    
                    <input 
                    type="radio"
                    value="Pubpular_Score"
                    checked={this.state.selectedYOption === "Pubpular_Score"}
                    onChange={this.onChangeYValue} 
                    /> <label> Pubpular Score </label>
                    
                    &ensp;&ensp;&ensp;&ensp;
                    
                    <input 
                    type="radio"
                    value="Harmonizome_Score"
                    checked={this.state.selectedYOption === "Harmonizome_Score"}
                    onChange={this.onChangeYValue} 
                    /> <label> Harmonizome Score </label>

                    &ensp;&ensp;&ensp;&ensp;
                    
                    <input 
                    type="radio"
                    value="D2_Score"
                    checked={this.state.selectedYOption === "D2_Score"}
                    onChange={this.onChangeYValue} 
                    /> <label> Disease 2.0 Score </label>

                    &ensp;&ensp;&ensp;&ensp;
                    <input 
                    type="radio"
                    value="CTD_Score"
                    checked={this.state.selectedYOption === "CTD_Score"}
                    onChange={this.onChangeYValue} 
                    /> <label> CTD Score </label>
                    
                    <LineSeparator />
                    
                    <Row>
                    	<Col sm="2">
                            <div className='text-center' style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 10,
                            color: "black",
                            }}>
                                <h3>Markers</h3>
                                <h6>Click to view the markers curated from:</h6>
                                <Nav pills justified vertical>
                                    <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeSubTab === '1' })}
                                onClick={() => { this.toggleSub("1"); }}
                                >
                                All Markers    
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeSubTab === '2' })}
                                onClick={() => { this.toggleSub("2"); }}
                                >
                                CTD Markers    
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeSubTab === '3' })}
                                onClick={() => { this.toggleSub("3"); }}
                                >
                                eDGAR Markers    
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={classnames({ active: this.state.activeSubTab === '4' })}
                                onClick={() => { this.toggleSub("4"); }}
                                >
                                Disease 2.0 Markers    
                                </NavLink>
                            </NavItem>
                            </Nav>
                            </div>
                        </Col>
                        
                        <Col sm="6">
                        <TabContent activeTab={this.state.activeSubTab} color='primary'>
                            <TabPane tabId="1">
                                <div style={{ marginTop: 10, marginLeft: "2%", marginBottom: "20px" }}>
                                    <label htmlFor="nValue">Top N Markers:</label>
                                    <input
                                        id="nValue"
                                        type="number"
                                        min="1"
                                        value={this.state.n}
                                        onChange={this.onChangeNValue}
                                        style={{ width: '100px', marginLeft: "10px", marginRight: "10px" }}
                                    />
                                    (Select the number of top markers to be displayed in the scatter plot)
                                </div>
                                <div style={{
                                borderRadius: 20,
                                marginLeft: "2%",
                                marginRight: "2%",
                                background: "white",
                                height: 600,
                                position: 'relative'}}
                                >
                                    <ParentSize>
                                        {({ width, height }) => <ScatterPlot disease = {this.result.disease} x_score = {this.state.selectedXOption} y_score = {this.state.selectedYOption} data ={this.data.sort((a,b) => (b[getScoreType(this.state.selectedXOption, this.state.selectedYOption)] - a[getScoreType(this.state.selectedXOption,this.state.selectedYOption)])).slice(0,this.state.n)} width={width} height={height} />}
                                    </ParentSize>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div style={{ marginTop: 10, marginLeft: "2%", marginBottom: "20px" }}>
                                    <h4>All CTD Markers</h4>
                                </div>
                                <div style={{
                                borderRadius: 20,
                                marginLeft: "2%",
                                marginRight: "2%",
                                background: "white",
                                height: 600,
                                position: 'relative'}}
                                >
                                    <ParentSize>
                                        {({ width, height }) => <ScatterPlot disease = {this.result.disease} x_score = {this.state.selectedXOption} y_score = {this.state.selectedYOption} data ={getMarkerDicts(this.data, "CTD_Marker")} width={width} height={height} />}
                                    </ParentSize>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div style={{ marginTop: 10, marginLeft: "2%", marginBottom: "20px" }}>
                                    <h4>All eDGAR Markers</h4>
                                </div>
                                <div style={{
                                borderRadius: 20,
                                marginLeft: "2%",
                                marginRight: "2%",
                                background: "white",
                                height: 600,
                                position: 'relative'}}
                                >
                                    <ParentSize>
                                        {({ width, height }) => <ScatterPlot disease = {this.result.disease} x_score = {this.state.selectedXOption} y_score = {this.state.selectedYOption} data ={getMarkerDicts(this.data, "eDGAR_Marker")} width={width} height={height} />}
                                    </ParentSize>
                                </div>
                            </TabPane>
                            <TabPane tabId="4">
                                <div style={{ marginTop: 10, marginLeft: "2%", marginBottom: "20px" }}>
                                    <h4>All Disease 2.0 Markers</h4>
                                </div>
                                <div style={{
                                borderRadius: 20,
                                marginLeft: "2%",
                                marginRight: "2%",
                                background: "white",
                                height: 600,
                                position: 'relative'}}
                                >
                                    <ParentSize>
                                        {({ width, height }) => <ScatterPlot disease = {this.result.disease} x_score = {this.state.selectedXOption} y_score = {this.state.selectedYOption} data ={getMarkerDicts(this.data, "Dis_2_Marker")} width={width} height={height} />}
                                    </ParentSize>
                                </div>
                            </TabPane>
                        </TabContent>
                        </Col>
	                
                        <Col sm='4'>
                        <div style={{ marginTop: 10, marginBottom: "20px", textAlign: "center"}}>
                            <h4>Top 10 Markers based on Combined Score</h4>
                        </div>
                        <div style={{
                            borderRadius: 20,
                            marginLeft: "2%",
                            marginRight: "2%",
                            background: "white",
                            height: 600,
                            position: 'relative'}}
                        >
                            <Row style={{height: 600,}}>
                                <ParentSize>
                                    {({ width, height }) => <HorizontalBar data ={getTopGenes(this.data, Math.min(10, this.data.length), this.state.selectedXOption, this.state.selectedYOption)} scoreType={getScoreType(this.state.selectedXOption, this.state.selectedYOption)} x_score = {this.state.selectedXOption} y_score={this.state.selecteYOption} labelX={"Score"} width={width} height={height} />}
                                </ParentSize>
                            </Row> 
                        </div>
                            
                        </Col>
	                </Row>
                    <br />
                    <br />
                    <Row>
                        <div style={{
                            borderRadius: 20,
                            paddingTop: "20px",
                            marginLeft: "2%",
                            marginRight: "2%",
                            background: "white",
                            position: 'relative',
                            width: "96%"
                        }}
                            >
                            <MDBDataTableV5 
                                entries={10}
                                hover
                                searchTop 
                                searchBottom={false}
                                data = {this.tableData}
                            />
                        </div>
                    </Row>
                    <br />
                    <Row>
                        <div style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 20,
                            color: "black",
                            }}>
                            <ul>
                                <b><li>Click on the scatter points to see more details</li>
                                <li>Top 50 markers are displayed in the scatterplot as default</li>
                                <li>The markers for barplot have been ranked based on combination of scores selected by user for the scatterplot.</li>
                                <li>Select the tabs on side to see CTD, eDGAR and Disease 2.0 curated markers in the scatterplot</li></b>
                            </ul>
                        </div>
                    </Row>
                </div>
                </Container>
            </>
        );
        }
    }
}
//http://localhost:8983/solr/brainprot/select?q=gene_name_list%3AOMP&wt=json

