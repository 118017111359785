import React from 'react';
import { useParams } from "react-router-dom";
import { BarPlotBDTM } from '../../components/charts/barPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Container, Card, CardBody, UncontrolledCollapse, Button, Table } from 'reactstrap';
import classnames from 'classnames';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import { LineSeparator } from '../../components/common/separatorLine';
import { BDTMBarPlotDataConverter } from '../../components/charts/barPlot';
import { boxPlotDataConverter } from '../../components/charts/boxPlot';
import { BDTMBoxPlot } from '../../components/charts/boxPlot';
import BDTMNotFound from '../../components/bdtm/notfound';
import { MainSearchBar } from '../../components/common/mainSearchBar';
/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function BDTMResult() {
  let params = useParams();
  let geneName = params.geneName;
  return (
    <>
        <MainSearchBar defPortal={'BDTM'}/>
        <LineSeparator />
        <Result geneName={geneName}/>
    </>
  );

}

export class Result extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            activeTab: '1',
            //metadataIsOpen: true,
            found1: false,
            found2: false,
            allDataJSX: null,
            allPval: null,
          };
        this.geneName = null;
        this.diseaseIdMapping= [
            {MESHID: "D000544",
            diseaseName: "Alzheimer's Disease"},
            {MESHID: "D000690",
            diseaseName: "Amyotrophic Lateral Sclerosis"},
            {MESHID: "D057174",
            diseaseName: "Frontal Temporal Lobar Degeneration"},
            {MESHID: "D005909",
            diseaseName: "Glioblastoma"},
            {MESHID: "D005910",
            diseaseName: "Glioma"},
            {MESHID: "D006816",
            diseaseName: "Huntington's Disease"},
            {MESHID: "D008527",
            diseaseName: "Medulloblastoma"},
            {MESHID: "D008579",
            diseaseName: "Meningioma"},
            {MESHID: "D009103",
            diseaseName: "Multiple Sclerosis"},
            {MESHID: "D010300",
            diseaseName: "Parkinson's Disease"},
            {MESHID: "D049912",
            diseaseName: "Pituitary Adenoma"}
        ]
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }
     /*
    metadataToggle () {
        var currentState = this.state.metadataIsOpen;
        this.setState({
            metadataIsOpen: !currentState
        });
        alert(this.state.metadataIsOpen);
    }
    */

    componentDidMount() {
        let searchTerm = "";
        if (this.props.geneName) {
        this.geneName = this.props.geneName.toUpperCase();
        searchTerm = this.geneName;
        Promise.all([
            fetch(`https://api.brainprot.org/api/bdtm/genes/${searchTerm}`)
            .then((res) => {
                var resjson = res.json();
                if (res.status == 200) {
                    this.setState({
                        found1: true,
                    });
                };
            return resjson;}),
            fetch(`https://api.brainprot.org/api/bdtm/pvalue/${searchTerm}`)
            .then((resPval) => {
                    var resPvaljson = resPval.json();
                    if (resPval.status == 200) {
                        this.setState({
                            found2: true,
                        });
                    };
                return resPvaljson;})
            ])
            .then(
                ([res, resPval]) => {
                    this.setState({
                        isLoaded: true,
                        resultJSX: this.setupData([res, resPval])
                    });
                    const ele = document.getElementById('ipl-progress-indicator');
                    if(ele && this.state.isLoaded){
                        ele.classList.add('available');
                        setTimeout(() => {
                            ele.remove();
                        }, 2000)
                    };
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    const ele = document.getElementById('ipl-progress-indicator');
                    if(ele && this.state.isLoaded){
                        ele.classList.add('available');
                        setTimeout(() => {
                            ele.remove();
                        }, 2000)
                    };
                }
            )
        /* fetch(`https://api.brainprot.org/api/bdtm/genes/${searchTerm}`)
        .then((res) => {
            var resjson = res.json();
            if (res.status == 200) {
                this.setState({
                    found: true,
                });
            };
            return resjson;
        })
        .then(
            (result) => {
                this.setupData(result);
                this.setState({
                    isLoaded: true,
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            }
        ) */
        };
        
    }
    
    setupData(rawAPIResult) {
        if (this.state.found1 && this.state.found2) {
            const rawEntireData = rawAPIResult[0].data;
            const rawPvalData = rawAPIResult[1].data;
            console.log(rawPvalData)
            this.unpackGeneData(rawEntireData, rawPvalData);
        } else {
            this.setState({
                found1: false,
                found2: false
            });
        }
    };

    unpackGeneData(entireDiseaseData, entirePValData){
        var allDataJSX = [];
        var allPval = entirePValData[0];
        console.log("Pval Array", allPval)
        var tabCounter = 1;
        for (var rawFullDiseaseData of entireDiseaseData){
            var processedDiseaseData = this.unpackDiseaseData(rawFullDiseaseData, allPval);
            var diseaseDataJSX = this.getDiseaseDataJSX(tabCounter, processedDiseaseData, allPval);
            allDataJSX.push(diseaseDataJSX);
            tabCounter++;
        }
        this.setState({
            allDataJSX: allDataJSX,
            allPVal: allPval
        });
    };

    unpackDiseaseData(fullDiseaseData, allPval) {
        // As of now only worrying about control v/s disease
        var studyNums = fullDiseaseData.diseaseData.length;
        var metadata = fullDiseaseData.diseaseMetadata;
        var data = fullDiseaseData.diseaseData;
        var disease = fullDiseaseData.disease;
    
        var processedDiseaseGroupData = {}
    
        for (var studyIndex=0; studyIndex < studyNums; studyIndex += 1){
            // ASSUMPTION for the API: The studys of diseaseData and diseaseMetadata
            // are arranged in the same order
            // We have still put a verification check post
            // because I don't trust the backend :P
            var studyIDdata = data[studyIndex].studyId;
            var studyIDmetadata = metadata[studyIndex].studyId;
            var studyData = data[studyIndex].data;
            var studyMetadata = metadata[studyIndex];
            var numGroups = studyMetadata.numGroups;
            var groupChoices = studyMetadata.groupChoices;
            var unprocessedStudyMetadata = studyMetadata.data;
        
            var allNullSwitch = false;
            var processedGroupData = [];
        
            if (studyIDdata === studyIDmetadata){
                for (var groupIndex=0; groupIndex < numGroups; groupIndex++){
                    var thisGroupChoices = groupChoices[groupIndex];
                    var thisGroupNumChoices = groupChoices[groupIndex].length;
            
                    var groupSamplesData = {};
                    for (var choiceIndex=0; choiceIndex < thisGroupNumChoices; choiceIndex++){
                        var choiceLabel = thisGroupChoices[choiceIndex];
                        var choiceSamples = [];
                        var choiceSamplesData = {};
                        
                        // first get the sampleIds corresponding to this group choice
                        for (var sampleInformation of unprocessedStudyMetadata){
                            if (sampleInformation.groupInformation[groupIndex] === choiceLabel){
                                choiceSamples.push(sampleInformation.sampleId);
                            } else {
                
                            }
                        }
                        // now get data of those samples which belong to this group choice
                        for (var [sampleId, value] of Object.entries(studyData)){
                            // Null Kill Switch
                            // If this gets set, this study data wont be stored!
                            // this will save us from operating over null data
                            // which breaks the code
                            if (value === null){
                                allNullSwitch = true;
                                break;
                            }
                            if (choiceSamples.includes(sampleId)){
                                choiceSamplesData[sampleId] = value;
                            }
                        }
                        // push information about this choice to the group
                        groupSamplesData[choiceLabel] = choiceSamplesData;
                    }
                    // push data of this group to the study
                    processedGroupData.push(groupSamplesData);
                }
                // push data of the study to the disease
                if (!allNullSwitch){
                    processedDiseaseGroupData[studyIDmetadata] = {"processedGroupData" : processedGroupData, "allData": studyData, "metadata": studyMetadata.data};
                } else {
                    processedDiseaseGroupData[studyIDmetadata] = null;
                };
            } else {
                throw Error("Well, shouldn't have trusted the backend");
            }
        };
        //console.log({"disease": disease,  "data": processedDiseaseGroupData});
        return({"disease": disease,  "data": processedDiseaseGroupData});
    };

    getDiseaseDataJSX(tabCounter, repackedDiseaseData, allPval){
        var allStudyData = repackedDiseaseData.data;
        // var allStudyMetadata
        var disease = repackedDiseaseData.disease;
        // Note we will only consider control vs disease for now
        var allPlotJSXList = [];
      
        //var plotsJSX = getStudyPlotJSX(disease, "GSE19332", allStudyData["GSE19332"]);
        for (var [studyId, studyData] of Object.entries(allStudyData)){
            var studyPvalue = allPval[studyId]
            if (studyData !== null) {
                var plotsJSX = this.getStudyPlotJSX(disease, studyId, studyData, studyPvalue);
            } else {
                var plotsJSX = (<><h3>The gene {this.geneName} was not found in the study with GEO Accession ID: {studyId}</h3></>);
            }
            allPlotJSXList.push(plotsJSX);
        }
        
        var navItemJSX = this.getDiseaseNavItemJSX(tabCounter, disease);
        var tabContentJSX = this.getDiseaseTabContentJSX(tabCounter, disease, allPlotJSXList);

        return [navItemJSX, tabContentJSX];
    }

    getDiseaseNavItemJSX(tabCounter, disease){
        var isActive = this.state.activeTab === tabCounter.toString();
        return(
            <>
                <NavItem>
                    <NavLink
                    className={classnames({ active: false})}
                    onClick={() => { this.toggle(tabCounter.toString()); }}
                    >
                        {this.getFullDiseaseName(disease)}    
                    </NavLink>
                </NavItem>
            </>
        );
    }

    getFullDiseaseName(disease){
        switch (disease) {
            case "als":
                return "Amyotrophic Lateral Sclerosis";
            case "alzheimer":
                return "Alzheimer's Disease";
            case "ftld":
                return "Frontal Temporal Lobar Degeneration";
            case "glioblastoma":
                return "Glioblastoma";
            case "glioma":
                return "Glioma";
            case "huntington":
                return "Huntington's Disease";
            case "medulloblastoma":
                return "Medulloblastoma";
            case "meningioma":
                return "Meningioma";
            case "multiple_sclerosis":
                return "Multiple Sclerosis";
            case "parkinson":
                return "Parkinson's Disease";
            case "pituitary_adenoma":
                return "Pituitary Adenoma";
            default:
                return "";
        }
    }
    
    getDiseaseInformation(disease){
        switch (disease) {
            case "als":
                return "Amyotrophic Lateral Sclerosis (ALS) is a prevalent neurodegenerative disorder, often called a motor neuron disease. Common clinical manifestations include muscle rigidity, muscular fasciculations, weakness, and muscle atrophy. Degeneration at all levels of the motor neuron system impacts voluntary muscle movement.";
            case "alzheimer":
                return "Alzheimer's disease (AD) is a progressive neurodegenerative disorder characterized by memory loss, cognitive decline, and behavioral changes. It is the most common cause of dementia in older adults, affecting approximately 50 million worldwide.";
            case "ftld":
                return "Frontotemporal lobar degeneration (FTLD) is a neurodegenerative disorder that affects the frontal and temporal lobes of the brain. It is a heterogenous disorder with several different subtypes, each characterized by a distinct set of clinical and pathological features.";
            case "glioblastoma":
                return "Glioblastoma is the most common form of malignant tumour that originates in the astrocytes of the brain. Glioblastoma is characterized by genetic alterations that lead to the activation of multiple signalling pathways involved in cell growth, proliferation, and survival.";
            case "glioma":
                return "Glioma is the type of brain tumour that arises in the glial cells of brain and spinal cord. They are the most common type of primary brain tumour, accounting for approximately 80% of malignant brain tumours.";
            case "huntington":
                return "Huntington's disease (HD) is a hereditary neurodegenerative disorder that affects the basal ganglia, that is responsible for voluntary movements, cognition, and emotions. As the disease advances, frequency of uncoordinated movements increase which might lead to person not being able to talk.";
            case "medulloblastoma":
                return "Medulloblastoma is a type of brain tumour that primarily affects children. It arises from the cerebellum, which is the part of the brain that controls movement and coordination. It is said to originate from immature cells called neural progenitor cells, which normally give rise to various types of brain cells.";
            case "meningioma":
                return "Meningioma is a slow growing tumour that affects the meninges, the membranes that surround and protect the brain and spinal cord. It is the most common type of primary brain tumour, accounting for about 30% of all cases.";
            case "multiple_sclerosis":
                return "Multiple sclerosis is the most common form of demyelinating disease that damages the myelin sheath covering of neurons in brain and spinal cord. A combination of genetic, infectious and environmental factors are responsible for triggering the autoimmune response that leads to multiple sclerosis.";
            case "parkinson":
                return "Parkinson’s disease is a chronic neurodegenerative disorder that mainly affects the motor coordination system. It is characterized by the progressive loss of dopamine-producing neurons in the substantia nigra region of the brain. A combination of genetic as well as environmental factors play a role in the manifestation of this disease.";
            case "pituitary_adenoma":
                return "Pituitary adenomas are benign tumors that develop in the pituitary gland, which regulates hormone production. Most pituitary adenomas are caused by mutations or alterations in genes that regulate cell growth and division.";
            default:
                return "";
        }
    }

    getDiseaseTabContentJSX(tabCounter, disease, allStudyPlotsJSXList){
        var diseaseMapping = this.diseaseIdMapping.find(obj => {
            return obj.diseaseName === this.getFullDiseaseName(disease)
        })
        return(
            <>
                <TabPane tabId={`${tabCounter}`}>
                    <h3>
                    <a href={`/hbda/${diseaseMapping.MESHID}`}>{this.getFullDiseaseName(disease)}</a>
                    </h3>
                    <div style={{
                        borderRadius: 20,
                        background: "white",
                        padding: 30,
                        color: "black",
                    }}>
                        {this.getDiseaseInformation(disease)}
                    </div>
                    <br />
                    {allStudyPlotsJSXList.map((row) => (
                        <>
                            {row}
                            <LineSeparator />
                        </>
                    ))}
                    
                    
                </TabPane> 
            </>
        )
    }

    unpackMetadataTable(metadataKeys, element){
        if (metadataKeys.indexOf("gender") > -1) {
            var gender = element.gender
            delete element.gender;
            if (gender == 0) {
                element["gender"] = "Male"
            } else {
                element["gender"] = "Female"
            }      
        }
        return (
            <>
            {metadataKeys.slice(0,-1).map((metadataElement) => 
                <td key={`${element[metadataElement]}`}>{element[metadataElement]}</td>
            )}
            <td key={`${element.accession}-groupInfo`}>
                <ul>
                    {element.groupInformation.map((groupElement) =>
                        <li key={`${element.accession}-${element.groupInformation[groupElement]}`}>{groupElement}</li>    
                    )}
                </ul>
            </td>
        </>
        )
    }
    getStudyPlotJSX(disease, studyId, studyData, pValue){
        var boxPlotData = boxPlotDataConverter(studyId, studyData.processedGroupData)
        var barPlotData = BDTMBarPlotDataConverter(studyData.processedGroupData)

        var ttest2 = require( '@stdlib/stats-ttest2' )
        var key1 = Object.keys(studyData.processedGroupData[0])
        var arr1 = Object.values(studyData.processedGroupData[0][key1[0]])
        var arr2 = Object.values(studyData.processedGroupData[0][key1[1]])
        if(arr1.length == 0  || arr2.length == 0){
            pVal = 1
        }
        else{
            //var ttest = ttest2(arr1,arr2);
            //var pVal = ttest['pValue'] 
            var pVal = pValue
        }

        var metadata = studyData.metadata;
        var testMetadataObject = metadata[0];
        var metadataKeys = Object.keys(testMetadataObject);
        // Making sure Group Infomation is at the end
        var groupInformationIndex = metadataKeys.indexOf("groupInformation");
        metadataKeys.splice(groupInformationIndex, 1);
        metadataKeys.push("groupInformation");

        //console.log(metadata);
    
        return (
            <>
                    <Row>
                        <h4>Viewing Summary for Gene Expression of {this.geneName} in the Study with GEO Accession ID: <a href={`https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=${studyId.split('_')[0]}`} target='blank'>{studyId}</a></h4>
                        <Col sm="4">
                            <div style={{height: 700}}>
                                <ParentSize>
                                    {({ width, height }) => <BDTMBoxPlot data={boxPlotData} width={width} height={height} labelBox={`Box Plot - ${studyId}`} pValue={pVal}/>}
                                </ParentSize>
                            </div>
                        </Col>
                        <Col sm="8">
                            <div style={{height: 700, overflowX: "auto"}} >
                                <ParentSize>
                                    {({ width, height }) => <BarPlotBDTM data={barPlotData} width={width} height={height} labelX={"Sample IDs"} labelY={"Processed Intensity"}/>}
                                </ParentSize>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Button color="primary" style={{ marginBottom: '1rem' }} id={`${studyId}-toggle`}>
                        View Metadata
                    </Button>
                    <UncontrolledCollapse toggler={`#${studyId}-toggle`}>
                        <Card>
                        <CardBody>
                            <Container fluid>
                                <Table hover bordered>
                                <thead>
                                    <tr key={"header"}>
                                        {metadataKeys.map((element) => 
                                        <th key={`${element}`}>{element}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {metadata.map((element) => 
                                        <tr key={`${element.accession}-tr`}>
                                            {this.unpackMetadataTable(metadataKeys, element)}
                                        </tr>
                                    )}
                                </tbody>
                
                                </Table>
                            </Container>
                        </CardBody>
                        </Card>
                    </UncontrolledCollapse>
            </>
        );
      }

    render() {
        const { error, isLoaded } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}. Please reach out to our team in this scenario!</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
            );
        } else if (isLoaded && (!(this.state.found1) && !(this.state.found2))) {
            return (
                <>
                    <BDTMNotFound />
                </>      
            );
        } else if (isLoaded && (this.state.found1 && this.state.found1)) {
        return (
            <>
                <Container fluid>
                 <div>
                    <h1>Entry for Gene: {this.geneName}</h1>
                    <br />
                    <Row>
                        <Col sm="2">
                        <div className='text-center' style={{
                        borderRadius: 20,
                        background: "white",
                        padding: 10,
                        color: "black",
                        }}>
                            <h3>Diseases</h3>
                            <h6>Click to view the particular disease.</h6>
                            <Nav pills justified vertical>
                                {this.state.allDataJSX.map((JSXDiseaseData) => (
                                    <>
                                            {JSXDiseaseData[0]}
                                    </>
                                ))}
                            </Nav>
                        </div>
                        <br />
                        <div style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 30,
                            color: "black",
                        }}>
                            Significance mapping: 
                            <br />
                            "***" = p-value &lt; 0.001 
                            <br />
                            "**" = p-value &lt; 0.01
                            <br />
                            "*" = p-value &lt; 0.05
                            <br />
                            "ns" = p-value &gt; 0.05
                        </div>
                        </Col>
                        <Col sm="10">
                        <TabContent activeTab={this.state.activeTab} color='primary'>
                            {this.state.allDataJSX.map((JSXDiseaseData) => (
                                <>
                                        {JSXDiseaseData[1]}
                                </>
                            ))}
                        </TabContent>
                        </Col>
                    </Row>
                </div>
                </Container>
            </>      
        );
        } 
    }
}