import React from "react";
import { useState } from "react";
import { Pie} from "@visx/shape";
import {
  LegendOrdinal,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { Group } from "@visx/group";
import {Text} from "@visx/text";
import { scaleOrdinal } from "@visx/scale";
import { GradientPinkBlue, LinearGradient } from '@visx/gradient';

const diseaseNames = ["Meningioma", "Glioma", "Medulloblastoma", "Pituitary"];

const DiseaseData = [{"Meningioma": '0.67', "Glioma": '0.25', "Medulloblastoma": '0.083', "Pituitary": '0'}];

const disease = diseaseNames.map((name) => ({
  label: name,
  count: Number(DiseaseData[0][name])
}));
const count = (d) => d.count;

export function PieChartDataConverter(bddfData, countKey){
  var itemArray = bddfData.map(function (item) { return item[countKey]; });
  const count = {};

  for (const element of itemArray) {
      if (count[element]) {
          count[element] += 1;
      } else {
          count[element] = 1;
      }
  }
  var pieData = []
  for(var i of Object.keys(count)){
      var pieItem = {}
      pieItem.label = i
      pieItem.count = count[i]
      pieData.push(pieItem);
  }
  return [pieData,count];
}

const defaultMargin = { top: 30, right: 2, bottom: 30, left: 2 };

export function PieChart({data, width,height, margin=defaultMargin}) {

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const pieSortValues = (a, b) => b - a;

  // color scales
  const getPieColor = scaleOrdinal({
    domain: ['Not yet recruiting', 'Recruiting', 'Enrolling by invitation', 'Active, not recruiting', 
    'Suspended', 'Terminated', 'Completed', 'Withdrawn', 'Unknown status', 'Available', 'No longer available'],
    range: [
      "rgba(82, 215, 38, 0.95)",
      "rgba(255, 236, 0, 0.95)",
      "rgba(255, 115, 0, 0.95)",
      "rgba(213, 45, 183, 0.95)",
      "rgba(255, 0, 0, 0.95)",
      "rgba(0, 126, 214, 0.95)",
      "rgba(96, 80, 220, 0.95)",
      "rgba(128, 128, 0, 0.95)",
      "rgba(93,30,91, 0.95)",
      "rgba(124, 221, 221, 0.95)"
    ]
  });


  return (
    <svg width={width} height={height}>
      <GradientPinkBlue id="visx-pie-gradient" />
      <LinearGradient to="#accbee" from="#e7f0fd" id='febink'/>;
      <rect
        rx={14}
        width={width}
        height={height}
        fill="white"
      />
      <Group top={top} left={left}>
        <Pie
          data={data}
          pieValue={count}
          pieSortValues={pieSortValues}
          outerRadius={radius}
          padAngle={0.01}
        >
          {(pie) => {
            return pie.arcs.map((arc) => {
              const { label } = arc.data;
              const arcPath = pie.path(arc);
              const [centroidX, centroidY] = pie.path.centroid(arc);
              const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.06;
              const arcFill = getPieColor(label);
              return (
                <g key={`arc-${label}`}>
                  <path d={arcPath} fill={arcFill} />
                  {hasSpaceForLabel && (
                    <text
                      x={2.2*centroidX}
                      y={2.2*centroidY}
                      dy=".33em"
                      fill="#000000"
                      fontSize={18}
                      textAnchor="middle"
                      pointerEvents="none"
                    >
                      {arc.data.count}
                    </text>
                  )}
                </g>
              );
            });
          }}
        </Pie>

      </Group>
    </svg>
  );
}

