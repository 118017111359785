import React from 'react';
import { format } from 'd3-format';
import { scaleLinear, scaleOrdinal, scaleThreshold, scaleQuantile } from '@visx/scale';
import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { LegendTemplate } from './legendTemplate';

export const pumpkin = '#f5810c';
export const blue = '#0acffe';
export const green = '#9be15d';

const ordinalColorScale = scaleOrdinal({
  domain: ['S', 'T', 'Y'],
  range: [pumpkin, blue, green],
});



const legendGlyphSize = 20;

export default function LollipopPlotLegend({ events = false }) {
  return (
    <>
    <div className="center legends">
      <LegendTemplate title="Lollipop Plot Legend">
        <LegendOrdinal scale={ordinalColorScale} labelFormat={(label) => `${label.toUpperCase()}`}>
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'row' }} className='center'>
              {labels.map((label, i) => (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="0 5px"
                  onClick={() => {
                    if (events) alert(`clicked: ${JSON.stringify(label)}`);
                  }}
                >
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <circle
                      fill={label.value}
                      r={legendGlyphSize / 2}
                      cx={legendGlyphSize / 2}
                      cy={legendGlyphSize / 2}
                    />
                  </svg>
                  <LegendLabel align="center" margin="0 0 0 0">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
      </LegendTemplate>
    </div>
    </>
  );
}