import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import ThemeContextWrapper from './theme/themeContextWrapper.js';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// Pages for Routes
import App from './App';
import About from './pages/about';
import Help from './pages/help';
import Contact from './pages/contact';
import Home from './pages/home';
import './index.css';
import IBPMSearch from './pages/ibpm/search';
import IBPMResult from './pages/ibpm/ibpmResult';
import IBPMLanding from './pages/ibpm/home';
import IBPMPhosphoResult from './pages/ibpm/phosphoMap';
import BDTMSearch from './pages/bdtm/search';
import BDTMResult from './pages/bdtm/bdtmResult';
import APIHelp from './pages/api';
import Page404 from './pages/404';
import Gallery from './pages/gallery';
import BDTMLanding from './pages/bdtm/home';
import Maintenance from './pages/maintenance';
import BDPMResult from './pages/bdpm/bdpmResult';
import BDPMSearch from './pages/bdpm/search';
import BDPMLanding from './pages/bdpm/home';
import HBDALanding from './pages/hbda/hbdaHome';
import HBDAResult from './pages/hbda/hbdaResult';
import BDMCLanding from './pages/bdmc/bdmcHome';
import BDMCResult from './pages/bdmc/bdmcResult';
import BDDFLanding from './pages/bddf/bddfHome';
import BDDFProteinResult from './pages/bddf/bddfProteinResult';
import { HBDATable } from './pages/hbda/hbdaDiseaseTable';
import BDDFSearch from './pages/bddf/search';
import { BDMCTable } from './pages/bdmc/bdmcDiseaseTable';
import BDDFDiseaseClinicalResult from './pages/bddf/bddfDiseaseClinicalResult';
import { BDDFTable } from './pages/bddf/bddfDiseaseTable';
import { BDDFStaticTable } from './pages/bddf/bddfStaticDiseaseTable';
import BDDFDiseaseResult from './pages/bddf/bddfDiseaseResult';


if (!Object.keys) {
  Object.keys = (function () {
    var hasOwnProperty = Object.prototype.hasOwnProperty,
        hasDontEnumBug = !({toString: null}).propertyIsEnumerable('toString'),
        dontEnums = [
          'toString',
          'toLocaleString',
          'valueOf',
          'hasOwnProperty',
          'isPrototypeOf',
          'propertyIsEnumerable',
          'constructor'
        ],
        dontEnumsLength = dontEnums.length;

    return function (obj) {
      if (typeof obj !== 'object' && typeof obj !== 'function' || obj === null) throw new TypeError('Object.keys called on non-object');

      var result = [];

      for (var prop in obj) {
        if (hasOwnProperty.call(obj, prop)) result.push(prop);
      }

      if (hasDontEnumBug) {
        for (var i=0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) result.push(dontEnums[i]);
        }
      }
      return result;
    };
  })();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
/*
Maintenance Comment

              
*/
root.render(
  <>
    <ThemeContextWrapper>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/" element={<App />}>
              <Route path="about" element={<About />} />
              <Route path="help" element={<Help />} />
              <Route path="contact" element={<Contact />} />
              <Route path="api" element={<APIHelp />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="ibpm">
                <Route path="" element={<IBPMLanding />} />
                <Route path="search" element={<IBPMSearch />}/>
                <Route path='entry/:geneName' element={<IBPMResult />} />
                <Route path='phosphomap/entry/:proteinName' element={<IBPMPhosphoResult />} />
              </Route>
              <Route path="bdtm">
                <Route path="" element={<BDTMLanding />} />
                <Route path="search" element={<BDTMSearch />}/>
                <Route path='entry/:geneName' element={<BDTMResult />} />
              </Route>
              <Route path="bdpm">
                <Route path="" element={<BDPMLanding />} />
                <Route path="search" element={<BDPMSearch />}/>
                <Route path='entry/:geneName'>
                  <Route path='protein/:proteinId' element={<BDPMResult />} />
                </Route>
              </Route>
              <Route path="hbda">
                <Route path="" element={<HBDALanding />} />
                <Route path="disease_table" element={<HBDATable />} />
                <Route path=":diseaseId" element={<HBDAResult />}/>
              </Route>
              <Route path="bdmc">
                <Route path="" element={<BDMCLanding />} />
                <Route path="disease_table" element={<BDMCTable />} />
                <Route path=":diseaseId" element={<BDMCResult />}/>
              </Route>
              <Route path="bddf">
                <Route path="" element={<BDDFLanding />} />
                <Route path="search" element={<BDDFSearch />}/>
                <Route path="disease_table" element={<BDDFTable />} />
                <Route path="clinical/:diseaseId" element={<BDDFDiseaseClinicalResult/>}/>
                <Route path="entry/:proteinId" element={<BDDFProteinResult />}/>
                <Route path="disease_static_table" element={<BDDFStaticTable />} />
                <Route path="disease/:diseaseId" element={<BDDFDiseaseResult />} />
              </Route>
              <Route
                path="*"
                element={<Page404 />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    </ThemeContextWrapper>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
