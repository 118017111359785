import { useState } from 'react';

// BootStrap/ReactStrap
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
  Container,
  InputGroup,
  Input,
  ButtonDropdown,
// FormGroup,
  Label,
  Form
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import { useParams } from 'react-router-dom';

// SearchBar Component for BrainProt
export function MainSearchBar({ defPortal="BDTM" }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchPortal, setSearchPortal] = useState(defPortal);
  const [formAction, setFormAction] = useState(searchAction(defPortal));
  const [inputColor, setInputColor] = useState(searchColor(defPortal));
  const [searchPlaceholder, setSearchPlaceholder] = useState(`Search genes/proteins in ${defPortal}`);
  
  function searchColor(page){
    if(page=='IBPM'){
      return 'primary';
    } else if(page=='BDDF'){
      return 'warning'
    } else if(page=='BDPM'){
      return 'success'
    } else if(page=='BDTM'){
      return 'info'
    } else {
      return 'primary'
    }
  }
  
  function searchAction(page){
      return `/${page.toLowerCase()}/search`
  }

  function toggle() {
    return (
        setDropdownOpen((prevState) => !prevState)
    );
  }

  function searchDestination(newPortal) {
    setSearchPortal(newPortal);
    setSearchPlaceholder(`Search genes/proteins in ${newPortal}`);
    setFormAction(searchAction(newPortal));
    setInputColor(searchColor(newPortal));
    }
  
  
    return (
        // Check why Arrow Functions are required in onClick
        <>
          <br />
          <Container fluid style={{maxWidth: 500,}}>
          <Form action={formAction}>
          <InputGroup>
          <UncontrolledDropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret color={inputColor} key={"dropd-toggle"}>{searchPortal}</DropdownToggle>
            <DropdownMenu key={"dropd-menu"}>
              <DropdownItem key={"portals"} header>Portals</DropdownItem>
              <DropdownItem key={"ibpm"} onClick={() => searchDestination('IBPM')}>Interhemispheric Brain Proteome Map (IBPM)</DropdownItem>
              <DropdownItem key={"bdtm"} onClick={() => searchDestination('BDTM')}>Brain Disease Transcriptome Map (BDTM)</DropdownItem>
              <DropdownItem key={"bdpm"} onClick={() => searchDestination('BDPM')}>Brain Disease Proteome Map (BDPM)</DropdownItem>
              <DropdownItem key={"bddf"} onClick={() => searchDestination('BDDF')}>Brain Disease Drug Finder (BDDF)</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
            <Input
              id="q"
              name="q"
              placeholder={searchPlaceholder}
              />
          <Button color={inputColor}><i className='fa fa-search'/></Button>
        </InputGroup>
        </Form>
        </Container>
      </>
    );
  }
  