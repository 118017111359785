import { useState } from 'react';

// BootStrap/ReactStrap
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
//  NavLink,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
  Container,
  InputGroup,
  Input,
  ButtonDropdown,
// FormGroup,
  Label,
  Form
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import { Link, NavLink } from 'react-router-dom';

// Main NavBar Component for BrainProt
// Add props for Active Links?
export function MainNavBar() {
    const [collapsed, setCollapsed] = useState(true);
   
    function toggleNavbar() {
      return setCollapsed(!collapsed);
    }
    
    let activeClassName = "active";

    return (
      <div>
          <Navbar className='sticky-top' color='dark' expand='md' dark>
                
                  <Link to="">
                  <img
                    style={{
                            objectFit: 'contain',
                            display: 'block',
                            border: 'none',
                            marginRight: 10
                          }}
                    src="/images/bp_navicon.png"
                  />
                  </Link>
                
                <NavbarToggler onClick={toggleNavbar} className="me-2" />
                <Collapse isOpen={!collapsed} navbar>
                  <Nav className="me-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret key={"dropd-toggle"}>
                        Portals
                      </DropdownToggle>
                      <DropdownMenu end key={"dropd-menu"}>
                        <DropdownItem header key={"portals"}>Portals</DropdownItem>
                        <DropdownItem key={"portals-bdpm"}> <NavLink to={"/hbda"} className={"dropdown-item"}>Human Brain Disease Atlas (HBDA)</NavLink></DropdownItem>
                        <DropdownItem key={"portals-ibpm"}> <NavLink to={"/ibpm"} className={"dropdown-item"}>Inter-hemisphere Brain Proteome Map (IBPM)</NavLink></DropdownItem>
                        <DropdownItem key={"portals-bdmc"}> <NavLink to={"/bdmc"} className={"dropdown-item"}>Brain Disease Marker Curator (BDMC)</NavLink></DropdownItem>
                        <DropdownItem key={"portals-bdtm"}> <NavLink to={"/bdtm"} className={"dropdown-item"}>Brain Disease Transcriptome Map (BDTM)</NavLink></DropdownItem>
                        <DropdownItem key={"portals-bdpm"}> <NavLink to={"/bdpm"} className={"dropdown-item"}>Brain Disease Proteome Map (BDPM)</NavLink></DropdownItem>
                        <DropdownItem key={"portals-bddf"}> <NavLink to={"/bddf"} className={"dropdown-item"}>Brain Disease Drug Finder (BDDF)</NavLink></DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                    <NavLink to="/about" className={"nav-link"}>
                      {({ isActive }) => (
                        <span
                          className={
                            isActive ? activeClassName : undefined
                          }
                        >
                          About
                        </span>
                      )}
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to="/help" className={"nav-link"}>
                      {({ isActive }) => (
                        <span
                          className={
                            isActive ? activeClassName : undefined
                          }
                        >
                          Help
                        </span>
                      )}
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to="/api" className={"nav-link"}>
                      {({ isActive }) => (
                        <span
                          className={
                            isActive ? activeClassName : undefined
                          }
                        >
                          API Help
                        </span>
                      )}
                    </NavLink>
                    </NavItem>
                    <NavItem>
                    <NavLink to="/contact" className={"nav-link"}>
                      {({ isActive }) => (
                        <span
                          className={
                            isActive ? activeClassName : undefined
                          }
                        >
                          BrainProt Team
                        </span>
                      )}
                    </NavLink>
                    </NavItem>
                    {/* <NavItem>
                    <NavLink to="/gallery" className={"nav-link"}>
                      {({ isActive }) => (
                        <span
                          className={
                            isActive ? activeClassName : undefined
                          }
                        >
                          Gallery
                        </span>
                      )}
                    </NavLink>
                    </NavItem> */}
                    <NavItem>
                    <a href='https://proteomicslabiitbs8.wixsite.com/brainprotcommunity' target={"blank"} className={"nav-link"}>
                        <span>
                          BrainProt Community
                        </span>
                    </a>
                    </NavItem>
                  </Nav>
                  { /*<NavbarText>
                    <a className="navbar-logo" href="http://www.iitb.ac.in">
                      <img style={{
                                  objectFit: 'contain',
                                  display: 'block',
                                  margin: '0 auto',
                                  border: 'none'
                                  }}
                            src="/images/concourse/iitb_2.png"
                            height={30}
                      />
                    </a>
                    </NavbarText> */}
                </Collapse>
          </Navbar>
      </div>
    );
  } 
  