import React from 'react';
import { useParams} from "react-router-dom";
import classnames from 'classnames';
import { Row, Col, Container, TabContent, Nav, NavItem, NavLink, Table, TabPane } from 'reactstrap';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';
import { barPlotDataConverter, BarPlotBDDF } from '../../components/charts/barPlot';
import { MDBDataTableV5 } from 'mdbreact';
/*
SOLR FIX CORS
http://marianoguerra.org/posts/enable-cors-in-apache-solr/
*/

export default function BDDFProteinResult() {
    let params = useParams();
    let proteinId = params.proteinId
    return (
        <>
            <MainSearchBar defPortal={'BDDF'}/>
            <LineSeparator />
            <h4>Result for Protein: {proteinId} </h4>
            <LineSeparator />
            <ProteinResult query={proteinId} />
        </>
    );
}
/*
function getTopDrugs(dict, n){
    var tempArr = []
    var topNDict = []
    for( let i = 0; i < dict.length; i++){
        tempArr.push([dict[i].molecule_chembl_id, dict[i].pIC50, dict[i].IC50, dict[i].units]);
    };
    
    tempArr.sort((a,b) => b[1]-a[1]);

    for(let j = 0; j < n; j++){
        topNDict.push({molecule_chembl_id:tempArr[j][0], pIC50:tempArr[j][1], IC50:tempArr[j][2], units:tempArr[j][3]})
    }
    //console.log(topNDict);
    if(dict.length<=10){
        return dict;
    }
    else{
        return topNDict;
    }
    
}

function getBarDict(dicts){
    var tempDict ={}
    for( let i = 0; i < dicts.length; i++){
        var key = dicts[i].molecule_chembl_id;
        var value = dicts[i].pIC50;
        tempDict[key] = value;
    }
    return tempDict;
}
*/

export class ProteinResult extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            activeTab: 'a',
            resultJSX: null
        };
        //this.query = this.props.query;
        this.data = null;
        this.bindingDBData = null;
        this.chemblData = null;
        this.stitchData = null;
        this.ttdData = null;
        this.clueData = null;
        this.searchTerm = this.props.query;
        this.tableBindingDB = {columns:[
            {
                label: 'Smiles ID',
                field: 'Smiles_ID',
            },
            {
                label: 'pIC50',
                field: 'pIC50',
                width: 150
            },
        ],
        rows: []
        };
        this.tableChembl= {columns:[
            {
                label: 'ChEMBL ID',
                field: 'molecule_chembl_id',
                width: 150
            },
            {
                label: 'Smiles ID',
                field: 'smiles',
            },
            {
                label: 'pIC50',
                field: 'pIC50',
                width: 150
            },
        ],
        rows: []
        };
        this.tableStitch = {columns:[
            {
                label: 'Chemical ID',
                field: 'Chemical_ID',
            },
            {
                label: 'Stitch Combined Score',
                field: 'Combined_Score',
            },
        ],
        rows: []
        };
        this.tableTTD = {columns:[
            {
                label: 'Drug ID',
                field: 'DrugID',
            },
            {
                label: 'IC50',
                field: 'Activity_IC50',
                width: 350
            },
            {
                label: 'Mechanism of Action',
                field: 'MOA',
                width: 350
            },
            {
                label: 'Highest Status',
                field: 'Highest_Status',
                width: 350
            },
        ],
        rows: []
        };
        this.tableClue = {columns:[
            {
                label: 'ChEMBL ID',
                field: 'Chembl_ID',
            },
            {
                label: 'Drug Name',
                field: 'Drug_Name',
            },
            {
                label: 'Clinical Phase',
                field: 'Clinical_Phase',
            },
            {
                label: 'Mechanism of Action',
                field: 'MOA',
            },
        ],
        rows: []
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    componentDidMount() {
        if (this.props.query) {
            var searchTerm = this.props.query;
            fetch(`https://api.brainprot.org/api/bddf/protein/all/${searchTerm}`)
            .then((res) => res.text())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            resultJSX: this.setupData(JSON.parse(result))
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                        const ele = document.getElementById('ipl-progress-indicator');
                        if(ele && this.state.isLoaded){
                            ele.classList.add('available');
                            setTimeout(() => {
                                ele.remove();
                            }, 2000)
                        };
                    }
                )
        };
    }

    setupData(result) {
        this.data = result;
        this.bindingDBData = this.data.BindingDB;
        this.chemblData = result.Chembl;
        this.stitchData = result.Stitch;
        this.ttdData = result.TTD;
        this.clueData = result.Clue;

        if (this.bindingDBData.length > 0){
            this.bindingDBData.map((item, index) => {
                var smiles = item.Smiles_ID.split('|').shift()
                var url_smiles = `https://pubchem.ncbi.nlm.nih.gov/#query=${smiles}`
                var encoded_url_smiles = encodeURI(url_smiles)
                item.Smiles_ID = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_smiles, '_blank')}
                        >
                            {smiles}
                        </div>
                    </div>
                );
                item.pIC50 = (item.pIC50.toFixed(3));
            });
            this.tableBindingDB.rows = this.bindingDBData;
        }

        if (this.chemblData.length > 0){
            var smilesKey = "smiles"
            if(Object.keys(this.chemblData[0]).includes("smiles")){
                smilesKey = "smiles"
            }
            else if(Object.keys(this.chemblData[0]).includes("Smiles_ID")){
                smilesKey = "Smiles_ID"
            }
            this.chemblData.map((item, index) => {
                var url_chembl = `https://www.ebi.ac.uk/chembl/compound_report_card/${item.molecule_chembl_id}/`
                item.molecule_chembl_id = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_chembl, '_blank')}
                        >
                            {item.molecule_chembl_id}
                        </div>
                    </div>
                );
                
                var smiles = item[smilesKey].split('|').shift()
                var url_smiles = `https://pubchem.ncbi.nlm.nih.gov/#query=${smiles}`
                var encoded_url_smiles = encodeURI(url_smiles)
                item.smiles = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_smiles, '_blank')}
                        >
                            {smiles}
                        </div>
                    </div>
                );
                item.pIC50 = (item.pIC50.toFixed(3));
            });
            this.tableChembl.rows= this.chemblData;
        }
        if (this.clueData.length > 0){
            this.clueData.map((item, index) => {
                var url_chembl = `https://www.ebi.ac.uk/chembl/compound_report_card/${item.Chembl_ID}/`
                item.Chembl_ID = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_chembl, '_blank')}
                        >
                            {item.Chembl_ID}
                        </div>
                    </div>
                );
            });
            this.tableClue.rows = this.clueData;
        }

        if (this.ttdData.length > 0){        
            this.ttdData.map((item, index) => {
                //var smiles = item.DrugID.split('|').shift()
                var url_ttd = `https://db.idrblab.net/ttd/data/drug/details/${item.DrugID}`
                //var encoded_url_smiles = encodeURI(url_smiles)
                item.DrugID = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_ttd, '_blank')}
                        >
                            {item.DrugID}
                        </div>
                    </div>
                );
            });
            this.tableTTD.rows = this.ttdData;
        }

        if (this.stitchData.length > 0){
            this.stitchData.map((item, index) => {
                var url_chemicalID = `https://pubchem.ncbi.nlm.nih.gov/compound/${item.Chemical_ID}`
                item.Chemical_ID = (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{
                            cursor: "pointer",
                            color: "blue",
                            }}
                            onClick={() => window.open(url_chemicalID, '_blank')}
                        >
                            {item.Chemical_ID}
                        </div>
                    </div>
                );
            });
            this.tableStitch.rows = this.stitchData;
        }

        if (this.data.length == 0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        } else {
            
            return (
                <>      
                </>
            );
        }

        

        //var barPlotData = barPlotDataConverter(getBarDict(getTopDrugs(this.data, 10)))
        
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {
            return (
                <>
                <Container fluid>
                    <Row>
                        <Col sm="2">
                            <div className='text-center' style={{
                            borderRadius: 20,
                            background: "white",
                            padding: 10,
                            color: "black",
                            }}>
                                <h3>Sources</h3>
                                <h6>Click to view the Drug scores from:</h6>
                                <Nav pills justified vertical>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === 'a' })}
                                        onClick={() => { this.toggle("a"); }}
                                        >
                                        ChEMBL    
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === 'b' })}
                                        onClick={() => { this.toggle("b"); }}
                                        >
                                        BindingDB
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === 'c' })}
                                        onClick={() => { this.toggle("c"); }}
                                        >
                                        Stitch   
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === 'd' })}
                                        onClick={() => { this.toggle("d"); }}
                                        >
                                        TTD   
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                        className={classnames({ active: this.state.activeTab === 'e' })}
                                        onClick={() => { this.toggle("e"); }}
                                        >
                                        Clue   
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Col>
                        
                        <Col sm="10">
                            <TabContent activeTab={this.state.activeTab} color='primary'>
                                <TabPane tabId="a">
                                    <div style={{
                                        borderRadius: 20,
                                        paddingTop: "20px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                        background: "white",
                                        position: 'relative',
                                        width: "96%"
                                    }}
                                        >
                                        <MDBDataTableV5 
                                            entries={10}
                                            hover
                                            scrollX
                                            searchTop 
                                            searchBottom={false}
                                            data = {this.tableChembl}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="b">
                                    <div style={{
                                            borderRadius: 20,
                                            paddingTop: "20px",
                                            marginLeft: "2%",
                                            marginRight: "2%",
                                            background: "white",
                                            position: 'relative',
                                            width: "96%"
                                        }}
                                            >
                                            <MDBDataTableV5 
                                                entries={10}
                                                scrollX
                                                hover
                                                searchTop 
                                                searchBottom={false}
                                                data = {this.tableBindingDB}
                                            />
                                        </div>
                                </TabPane>
                                <TabPane tabId="c">
                                    <div style={{
                                        borderRadius: 20,
                                        paddingTop: "20px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                        background: "white",
                                        position: 'relative',
                                        width: "96%"
                                    }}
                                        >
                                        <MDBDataTableV5 
                                            entries={10}
                                            scrollX
                                            hover
                                            searchTop 
                                            searchBottom={false}
                                            data = {this.tableStitch}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="d">
                                    <div style={{
                                        borderRadius: 20,
                                        paddingTop: "20px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                        background: "white",
                                        position: 'relative',
                                        width: "96%"
                                    }}
                                        >
                                        <MDBDataTableV5 
                                            entries={10}
                                            scrollX
                                            hover
                                            searchTop 
                                            searchBottom={false}
                                            data = {this.tableTTD}
                                        />
                                    </div>
                                </TabPane>
                                <TabPane tabId="e">
                                    <div style={{
                                        borderRadius: 20,
                                        paddingTop: "20px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                        background: "white",
                                        position: 'relative',
                                        width: "96%"
                                    }}
                                        >
                                        <MDBDataTableV5 
                                            entries={10}
                                            scrollX
                                            hover
                                            searchTop 
                                            searchBottom={false}
                                            data = {this.tableClue}
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>
                        </Col> 
                    </Row>
                </Container>         
                </>
            );
        }
    }
}



