import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import HelpAccordion from '../components/common/helpAccordion';
import { LineSeparator } from '../components/common/separatorLine';
import YoutubeEmbed from '../components/common/youTubeEmbed';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function Help() {
  const ele = document.getElementById('ipl-progress-indicator');
  if(ele){
      ele.classList.add('available');
      setTimeout(() => {
          ele.remove();
      }, 2000)
  };
  return (
    <>
        <Container>
          <h1 style={{textAlign: "center"}}>Help</h1>
          <br />
          <div style={{
                borderRadius: 20,
                background: "white",
                padding: 30,
                color: "black",
                }}>
            <h5>
            Begin the exploration of BrainProt and its various domains using this page as a starting point. At BrainProt, we aim to provide accessible and 
            comprehensible information about the human brain and its associated diseases. We eagerly await your valuable feedback and suggestions; kindly 
            let us know through email. Additionally, if you have unresolved queries or have some bugs to report, please contact us by emailing
            &nbsp;<a href='mailto:support@brainprot.org'>support@brainprot.org</a>.
            </h5>
            <h6>*Please use Google Chrome for best view</h6>
          </div>
          <br />
          <h3 style={{textAlign: "center"}}>Frequently Asked Questions (FAQ)</h3>
          <br />
          <div style={{
                borderRadius: 20,
                background: "white",
                padding: 50,
                color: "black",
                }}>
            
            <h5>What is BrainProt?</h5>
              <p>
              BrainProt is an integrative and simplified knowledge base that integrates omics-based information about the human brain and its associated 
              diseases. It provides insights into the expression level of genes/proteins in the human brain and its alteration in the associated diseases. 
              It also fosters the identification and selection of brain disease-associated markers and therapeutic compounds. For more comprehensive 
              information, please refer to the <Link to='/about'>About </Link> section.
              </p> 
            
            <h5>How can I access data available in BrainProt? </h5>
              <p>
                The data available in the BrainProt can be accessed using our API. Please visit the <Link to="/api">API Help Section</Link> for more information.
              </p>

            <h5>What is the scope and aim of BrainProt? </h5>
              <p>BrainProt aims to provide accessible, rapid, and robust accessibility of different information related to the Human Brain and its 
                associated diseases. It intends to aid neuroscience research by guiding neuroscientists and researchers around the globe through better 
                understanding of the brain 
                disease pathophysiology.
              </p>

            <h5>What is the source of data available in BrainProt? </h5>
              <p>
              Brainprot, a knowledgebase of 6 domains with various functionality, includes data from renowned databases and repositories. BrainProt also 
              integrates analyzed in-house omics datasets generated by&nbsp; <a href="https://www.bio.iitb.ac.in/~sanjeeva/facility/" target="blank">MASSFIITB</a>,
              IIT Bombay.
              </p>
        
            <h5>Is BrainProt free?</h5>
              <p>BrainProt is a freely available, open-source knowledge base developed to support the advancement of scientific knowledge.
              </p>

            <h5>How do I contribute to BrainProt?</h5>
              <p>
                BrainProt welcomes active participation, collaboration, and networking to expand the magnitude and limits of knowledge of the Human Brain. 
                BrainProt also encourages you to submit your data by providing the link to the dataset. The BrainProt team will process and upload the data 
                in the respective sections of BrainProt in the upcoming updates.
              </p>
    
            <h5>Does BrainProt have a trademark?</h5>
              <p>
                The name and the logo of BrainProt have been registered for a trademark under classes 9, 41, and 42 of International Non-Proprietary Names 
                (INN). IP India Online Website.
                <br />
                <a href='https://ipindiaonline.gov.in/tmrpublicsearch/frmmain.aspx'>IP India Online Website</a>
              </p>
          </div>
          <LineSeparator />
          <h3 style={{textAlign: "center"}}> Portal Specific FAQs</h3>
          <br />
          <HelpAccordion />
          <br />
          <h3>Help Videos</h3>
          <br />
          {/* <figure>
            <img src="/images/hbda/HBDA_Final.gif" alt="HBDA_Help" style={{'width': "80%"}}/>
          </figure> 
          <video width="1000" height="700" controls >
            <source src="/images/hbda/HBDA_Final.mp4" type="video/mp4"/>
          </video> */}
          <h5>HBDA:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/DOVVvFNchM0"/>
          <br />
          <h5>IBPM:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/IcYyyTl5EY8"/>
          <br />
          <h5>BDMC:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/fnl0ufHSeX8"/>
          <br />
          <h5>BDTM:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/wlGXWk_w9i4"/>
          <br />
          <h5>BDPM:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/foa36IydaSM"/>
          <br />
          <h5>BDDF:</h5>
          <YoutubeEmbed url="https://www.youtube.com/embed/FFsPj1oHjH4"/>
        </Container>
    </>
  );
}