import React from 'react';
import { Link, useParams } from "react-router-dom";
import { barPlotDataConverter, BarPlotLH, BarPlotRegion, BarPlotRH } from '../../components/charts/barPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Table, Container } from 'reactstrap';
import classnames from 'classnames';
import 'bootstrap/dist/css/bootstrap.css' 
import '@fortawesome/fontawesome-free/css/all.min.css'
import { LineSeparator } from '../../components/common/separatorLine';
import IBPMNotFound from '../../components/ibpm/notfound';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { MDBDataTableV5 } from 'mdbreact';
/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function IBPMResult() {
  let params = useParams();
  let geneName = params.geneName;
  return (
    <>
        <MainSearchBar defPortal='IBPM' />
        <LineSeparator />
        <Result geneName={geneName}/>
    </>
  );

}

export class Result extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            error: null,
            isLoaded: false,
            proteinDataJSX: null,
            peptideDataJSX: null,
            proteinMetadataJSX: null,
            peptideMetadataJSX: null,
            proteinResourceHubJSX: null,
            activeTab: '1',
            found: false
          };
        this.geneName = null;
        this.proteinName = null;
        this.chromosome = null;
        this.proteinMass = null;
        this.proteinLength = null;
        this.rawProteinData = null;
        this.rawProteinDataLH = {};
        this.rawProteinDataRH = {};
        this.rawProteinDataRegion = {};
        this.proteinDataLH = [];
        this.proteinDataRH = [];
        this.proteinDataRegion = [];

        this.peptideData = null;
        this.peptideTableData = {columns:[
            {
                label: 'Peptide Sequence',
                field: 'peptideSequence',
                width: 350
            },
            {
                label: 'Length',
                field: 'length',
                width: 150
            },
            {
                label: 'Missed Cleavages',
                field: 'missedCleavages',
                width: 150
            }
        ],
            rows: []
        }
        this.peptideNum = null;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    componentDidMount() {
        let searchTerm = "";
        if (this.props.geneName) {
        this.geneName = this.props.geneName.toUpperCase();
        searchTerm = this.geneName;
        fetch(`https://api.brainprot.org/api/ibpm/genes/${searchTerm}`)
        .then((res) => {
            var resjson = res.json();
            if (res.status == 200) {
                this.setState({
                    found: true,
                });
            };
            return resjson;
        })
        .then(
            (result) => {
                this.setupData(result);
                this.setState({
                    isLoaded: true,
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            }
        )
        };
        
    }
    
    setupData(rawAPIResult) {
        if (this.state.found) {
            const rawProteinsData = rawAPIResult.data[0].proteinData;
            const rawPeptideData = rawAPIResult.data[0].peptideData;
            
            if (!(rawProteinsData[1]) && (rawProteinsData.length > 0)){
                const rawProteinData = rawProteinsData[0];
                this.proteinName = rawProteinData.proteinId;
                this.proteinMass = rawProteinData.proteinMass;
                this.proteinLength = rawProteinData.proteinLength;
                this.chromosome = rawProteinData.chromosome;
                delete rawProteinData.proteinId;
                delete rawProteinData.proteinMass;
                delete rawProteinData.proteinLength;
                delete rawProteinData.chromosome;
                this.rawProteinData = rawProteinData;

                //TAKE CARE OF THIS IN API!!!
                var peptideData = rawPeptideData.filter((value, index) => {
                    const _value = JSON.stringify(value);
                    return index === rawPeptideData.findIndex(obj => {
                    return JSON.stringify(obj) === _value;
                    });
                });
                
                this.peptideNum = peptideData.length;
                this.peptideData = peptideData;

                this.setState({
                    peptideDataJSX: this.setupPeptideData(),
                    proteinDataJSX: this.setupProteinData(),
                    peptideMetadataJSX: this.setupPeptideMetadata(),
                    proteinMetadataJSX: this.setupProteinMetadata(),
                    proteinResourceHubJSX: this.setupProteinResourceHub(),
                });
                
            } else {
                // Multiple Protein Issue or Empty Protein!
                this.setState({
                    peptideDataJSX: null,
                    proteinDataJSX: null,
                    peptideMetadataJSX: null,
                    proteinMetadataJSX: null,
                    proteinResourceHubJSX: null,
                });
                this.setState({
                    found: false,
                });
            }
            
        } else {
            this.setState({
                peptideDataJSX: null,
                proteinDataJSX: null,
                peptideMetadataJSX: null,
                proteinMetadataJSX: null,
                proteinResourceHubJSX: null,
            });
        }
         
    }

    setupProteinData() {
        var rawProteinData = this.rawProteinData;
        var rawProteinDataLH = {};
        var rawProteinDataRH = {};
        var rawProteinDataRegion = {};
        Object.keys(rawProteinData).forEach(function(key) {
            if (key.includes("_L_")) {
                let new_key = key.replace("_L_Med","");
                rawProteinDataLH[new_key] = rawProteinData[key]; 
            } else if (key.includes("_R_")) {
                let new_key = key.replace("_R_Med","");
                rawProteinDataRH[new_key] = rawProteinData[key]; 
            } else {
                let new_key = key.replace("_Med","");
                rawProteinDataRegion[new_key] = rawProteinData[key]; 
            }
        });

        this.rawProteinDataLH = rawProteinDataLH;
        this.rawProteinDataRH = rawProteinDataRH;
        this.rawProteinDataRegion = rawProteinDataRegion;
        this.proteinDataLH = barPlotDataConverter(rawProteinDataLH);
        this.proteinDataRH = barPlotDataConverter(rawProteinDataRH);
        this.proteinDataRegion = barPlotDataConverter(rawProteinDataRegion);

        return (
            <>
                <h3>Left Hemisphere</h3>
                    <p>Here is a summary of protein expression for <b>{this.proteinName}</b> in Left Hemisphere. </p>
                <div style={{height: 500,}}>
                    <ParentSize>
                        {({ width, height }) => <BarPlotLH data={this.proteinDataLH} width={width} height={height} labelX={"Regions"} labelY={"Processed Intensity"}/>}
                    </ParentSize>
                </div>
                <br/>
                <LineSeparator />
                <h3>Right Hemisphere</h3>
                <p>Here is a summary of protein expression for <b>{this.proteinName}</b> in Right Hemisphere. </p>
                <div style={{height: 500}}>
                    <ParentSize>
                        {({ width, height }) => <BarPlotRH data={this.proteinDataRH} width={width} height={height} labelX={"Regions"} labelY={"Processed Intensity"}/>}
                    </ParentSize>
                </div>
                <br/>
                <LineSeparator />
                <h3>Brain Regions</h3>
                <p>Here is a summary of protein expression for <b>{this.proteinName}</b> in neuroanatomical regions of the Human Brain. </p>
                <div style={{height: 500}}>
                    <ParentSize>
                        {({ width, height }) => <BarPlotRegion data={this.proteinDataRegion} width={width} height={height} labelX={"Regions"} labelY={"Processed Intensity"}/>}
                    </ParentSize>
                </div>
            </>
        );
    }
    
    setupPeptideData() {
        this.peptideTableData.rows = this.peptideData;
        //console.log("Peptide Data", this.peptideData)
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <Container fluid>
                        <MDBDataTableV5 
                            entries={10}
                            scrollX
                            hover
                            data = {this.peptideTableData}
                        />
                        {/*<Table hover borderless>
                        <thead>
                            <tr key={"header"}>
                                <th key={"seq"} width={500}>
                                    Peptide Sequence
                                </th>
                                <th key={"length"}> 
                                    Length
                                </th>
                                <th key={"missedCleavages"}>
                                    Missed Cleavages
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.peptideData.map((element) =>
                                <tr key={`${element.peptideSequence}-tr`}><th scope="row" key={`${element.peptideSequence}-th`}>{element.peptideSequence}</th><td key={element.length}>{element.length}</td><td key={element.missedCleavages}>{element.missedCleavages}</td></tr>
                            )}
                        </tbody>
                        </Table> */}
                    </Container>
                </div>            
            </>
        );
    }

    setupPeptideMetadata() {
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <Container fluid>
                        <Table hover borderless>
                        <tbody>
                            <tr key={"totality"}>
                                <th scope='row' key={"pep-col-1-2"}>
                                    No. of Peptides
                                </th>
                                <td key={"pep-col-2-2"}>
                                    {this.peptideNum}
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    setupProteinMetadata(){
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <h5 style={{textAlign: 'center'}}><b>Protein: {this.proteinName}</b></h5>
                    <Container fluid>
                        <Table hover borderless>
                        <tbody>
                            <tr key={"totality"}>
                                <th scope='row' key={"col-1-1"}>
                                    Gene Name:
                                </th>
                                <td key={"col-2-1"}>
                                    {this.geneName}
                                </td>
                            </tr>
                            <tr key={"length"}>
                                <th scope='row' key={"col-1-2"}>
                                    Protein Length:
                                </th>
                                <td key={"col-2-2"}>
                                    {this.proteinLength}
                                </td>
                            </tr>
                            <tr key={"mass"}>
                                <th scope='row' key={"col-1-3"}>
                                    Protein Mass:
                                </th>
                                <td key={"col-2-3"}>
                                    {this.proteinMass}
                                </td>
                            </tr>
                            <tr key={"chromosome"}>
                                <th scope='row' key={"col-1-4"}>
                                    Chromosome:
                                </th>
                                <td key={"col-2-4"}>
                                    {this.chromosome}
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    setupProteinResourceHub() {
        return (
            <>
                <div style={{
                    borderRadius: 20,
                    background: "white",
                    padding: 10,
                    color: "black",
                }}>
                    <h5 style={{textAlign: 'center'}}><b>Protein Resource Hub: {this.proteinName}</b></h5>
                    <Container fluid>
                        <Table hover borderless>
                        <tbody>
                            <tr key={"up"}>
                                <th scope='row' key={"col-1-6"}>
                                    UniProt:
                                </th>
                                <td key={"col-2-6"}>
                                    <a href={`https://www.uniprot.org/uniprot/${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"np"}>
                                <th scope='row' key={"col-1-7"}>
                                    neXtProt:
                                </th>
                                <td key={"col-2-7"}>
                                    <a href={`https://www.nextprot.org/proteins/search?query=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"hpa"}>
                                <th scope='row' key={"col-1-8"}>
                                    HPA(Brain Atlas):
                                </th>
                                <td key={"col-2-8"}>
                                    <a href={`https://www.proteinatlas.org/search/${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"string"}>
                                <th scope='row' key={"col-1-9"}>
                                    STRING:
                                </th>
                                <td key={"col-2-9"}>
                                    <a href={`https://string-db.org/api/image/network?identifiers=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"pdb"}>
                                <th scope='row' key={"col-1-10"}>
                                    ProteomeDB:
                                </th>
                                <td key={"col-2-10"}>
                                    <a href={`https://www.proteomicsdb.org/proteomicsdb/#protein/search/query?protein_name=${this.proteinName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"aba"}>
                                <th scope='row' key={"col-1-10"}>
                                    Allen Brain Atlas:
                                </th>
                                <td key={"col-2-10"}>
                                    <a href={`http://human.brain-map.org/microarray/search/show?exact_match=false&search_term=${this.proteinName}&search_type=gene`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"gtex"}>
                                <th scope='row' key={"col-1-11"}>
                                    Genotype-Tissue Expression (GTEx):
                                </th>
                                <td key={"col-2-11"}>
                                    <a href={`https://gtexportal.org/home/browseEqtls?location=${this.geneName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"bratx"}>
                                <th scope='row' key={"col-1-12"}>
                                    Brain Transcriptome Database (BrainTx):
                                </th>
                                <td key={"col-2-12"}>
                                    <a href={`https://www.cdtdb.neuroinf.jp/CDT/SearchTop.do?searchKeyword=${this.geneName}`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"hbt"}>
                                <th scope='row' key={"col-1-13"}>
                                    Human Brain Transcriptome:
                                </th>
                                <td key={"col-2-13"}>
                                    <a href={`https://hbatlas.org/hbtd/basicSearch.pl?geneNameType=Gene+official+symbol&geneName=${this.geneName}&region=Brain+regions`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                            <tr key={"braexp"}>
                                <th scope='row' key={"col-1-13"}>
                                    Brain Express:
                                </th>
                                <td key={"col-2-13"}>
                                    <a href={`https://brainexp.org`} target="_blank">Click Here</a>
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </div>
            </>
        );
    }

    render() {
        const { error, isLoaded } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}. Please reach out to our team in this scenario!</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
            );
        } else if (isLoaded && !(this.state.found)) {
            return (
                <>
                    <IBPMNotFound />
                </>      
            );
        } else if (isLoaded && this.state.found) {
        return (
            <>
                <Container fluid>
                 <div>
                    <h1>Entry for Gene: {this.geneName}</h1>
                    <Nav pills justified>
                    <NavItem>
                        <NavLink
                        style={{fontSize: "22px"}}
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                        >
                        Protein Information
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        style={{fontSize: "22px"}}
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                        >
                        Peptide Information
                        </NavLink>
                    </NavItem>
                    </Nav>
                    <br />
                    <TabContent activeTab={this.state.activeTab} color='primary'>
                    <TabPane tabId="1">
                        <Row>
                        <h4>Viewing Protein Information for Gene: {this.geneName}</h4>
                        <Col sm="4">
                            {this.state.proteinMetadataJSX}
                            <br />
                            {this.state.proteinResourceHubJSX}
                            <br />
                        </Col>
                        <Col sm="8">
                            {this.state.proteinDataJSX}
                        </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <h3> Check Phosphorylation Data in <a href={`/ibpm/phosphomap/entry/${this.proteinName}`}>IBPM PhosphoMap</a></h3>
                        <h4>Viewing Peptide Information for Protein: {this.proteinName}</h4>
                        <Col sm="2">
                            {this.state.peptideMetadataJSX}
                            <br />
                        </Col>
                        <Col sm="10">
                            {this.state.peptideDataJSX}
                        </Col>
                        </Row>
                    </TabPane>
                    </TabContent>
                </div>
                </Container>
            </>      
        );
        } 
    }
}
