import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
  Button,
  Container,
  Table
} from 'reactstrap';

    

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function APIHelp() {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
        if (open === id) {
        setOpen('0');
        } else {
        setOpen(id);
        }
    };
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };
    return (
    <>
        <Container fluid>
            <h1 style={{textAlign: "center"}}>BrainProt API: Progammatic Access</h1>
            <br />
            <div style={{
                borderRadius: 20,
                background: "white",
                padding: 30,
                color: "black",
            }}>
                <h4>
                    BrainProt can be accessed programmatically through our API Endpoint at <a href='https://api.brainprot.org'><code>https://api.brainprot.org</code></a>
                </h4>
                <h5>
                    For IBPM and IBPM PhosphoMap:
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="1"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/genes/{'<GENE_SYMBOL>'}</code>&nbsp; Get IBPM entry data for a particular gene</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <code>{'<GENE_SYMBOL>: '}</code> This works only for primary gene symbol. Alternate gene symbols will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/genes/nefm</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="2"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/genes/{'<GENE_SYMBOL>/region/<REGION_CODE>'}</code>&nbsp; Get IBPM entry data for a particular region, for a particular gene</AccordionHeader>
                    <AccordionBody accordionId="2">
                        <code>{'<REGION_CODE>: '}</code> The following region codes are available: 
                        <Table bordered hover>
                            <thead>
                                <tr key={"header-1"}>
                                    <th key={"seq-1"}>
                                        Region
                                    </th>
                                    <th key={"length-1"}>
                                        <code>{'<REGION_CODE>'}</code>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={"amy-row-1"}>
                                    <td key={"amy-1"}>
                                        Amygdala
                                    </td>
                                    <td key={"amy-code-1"}>
                                        <code>{'amy'}</code>
                                    </td>
                                </tr>
                                <tr key={"bs-row-1"}>
                                    <td key={"bs-1"}>
                                        Brain Stem
                                    </td>
                                    <td key={"bs-code-1"}>
                                        <code>{'bs'}</code>
                                    </td>
                                </tr>
                                <tr key={"cn-row-1"}>
                                    <td key={"cn-1"}>
                                        Caudate Nucleus
                                    </td>
                                    <td key={"cn-code-1"}>
                                        <code>{'cn'}</code>
                                    </td>
                                </tr>
                                <tr key={"cing-row-1"}>
                                    <td key={"cing-1"}>
                                        Cingulum
                                    </td>
                                    <td key={"cing-code-1"}>
                                        <code>{'cing'}</code>
                                    </td>
                                </tr>
                                <tr key={"cv-row-1"}>
                                    <td key={"cv-1"}>
                                        Cerebellar Vermis
                                    </td>
                                    <td key={"cv-code-1"}>
                                        <code>{'cv'}</code>
                                    </td>
                                </tr>
                                <tr key={"dg-row-1"}>
                                    <td key={"dg-1"}>
                                        Dentate Gyrus
                                    </td>
                                    <td key={"dg-code-1"}>
                                        <code>{'dg'}</code>
                                    </td>
                                </tr>
                                <tr key={"fcm-row-1"}>
                                    <td key={"fcm-1"}>
                                        Frontal Cortex Media
                                    </td>
                                    <td key={"fcm-code-1"}>
                                        <code>{'fcm'}</code>
                                    </td>
                                </tr>
                                <tr key={"fwm-row-1"}>
                                    <td key={"fwm-1"}>
                                        Frontal White Matter
                                    </td>
                                    <td key={"fwm-code-1"}>
                                        <code>{'fwm'}</code>
                                    </td>
                                </tr>
                                <tr key={"hip-row-1"}>
                                    <td key={"hip-1"}>
                                        Hippocampus
                                    </td>
                                    <td key={"hip-code-1"}>
                                        <code>{'hip'}</code>
                                    </td>
                                </tr>
                                <tr key={"le-row-1"}>
                                    <td key={"le-1"}>
                                        Lentiform Extern
                                    </td>
                                    <td key={"le-code-1"}>
                                        <code>{'le'}</code>
                                    </td>
                                </tr>
                                <tr key={"li-row-1"}>
                                    <td key={"li-1"}>
                                        Lentiform Intern
                                    </td>
                                    <td key={"li-code-1"}>
                                        <code>{'li'}</code>
                                    </td>
                                </tr>
                                <tr key={"ob-row-1"}>
                                    <td key={"ob-1"}>
                                        Olfactory Bulb
                                    </td>
                                    <td key={"ob-code-1"}>
                                        <code>{'ob'}</code>
                                    </td>
                                </tr>
                                <tr key={"oc-row-1"}>
                                    <td key={"oc-1"}>
                                        Occipital Cortex
                                    </td>
                                    <td key={"oc-code-1"}>
                                        <code>{'oc'}</code>
                                    </td>
                                </tr>
                                <tr key={"pc-row-1"}>
                                    <td key={"pc-1"}>
                                        Parietal Cortex
                                    </td>
                                    <td key={"pc-code-1"}>
                                        <code>{'pc'}</code>
                                    </td>
                                </tr>
                                <tr key={"pons-row-1"}>
                                    <td key={"pons-1"}>
                                        Pons
                                    </td>
                                    <td key={"pons-code-1"}>
                                        <code>{'pons'}</code>
                                    </td>
                                </tr>
                                <tr key={"put-row-1"}>
                                    <td key={"put-1"}>
                                        Putamen
                                    </td>
                                    <td key={"put-code-1"}>
                                        <code>{'put'}</code>
                                    </td>
                                </tr>
                                <tr key={"sn-row-1"}>
                                    <td key={"sn-1"}>
                                        Substantia Nigra
                                    </td>
                                    <td key={"sn-code-1"}>
                                        <code>{'sn'}</code>
                                    </td>
                                </tr>
                                <tr key={"tc-row-1"}>
                                    <td key={"tc-1"}>
                                        Temportal Cortex
                                    </td>
                                    <td key={"tc-code-1"}>
                                        <code>{'tc'}</code>
                                    </td>
                                </tr>
                                <tr key={"thal-row-1"}>
                                    <td key={"thal-1"}>
                                        Thalamus
                                    </td>
                                    <td key={"thal-code-1"}>
                                        <code>{'thal'}</code>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <code>{'<GENE_SYMBOL>: '}</code> This works only for primary gene symbol. Alternate gene symbols will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/genes/nefm/region/amy</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="3"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/proteins/{'<PROTEIN_ID>'}</code>&nbsp; Get IBPM entry data for a particular protein</AccordionHeader>
                    <AccordionBody accordionId="3">
                        <code>{'<PROTEIN_ID>: '}</code> This works only for UniProt IDs. Alternate protein IDs will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/proteins/p07197</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="4"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/proteins/{'<PROTEIN_ID>/region/<REGION_CODE>'}</code>&nbsp; Get IBPM entry data for a particular region, for a particular gene</AccordionHeader>
                    <AccordionBody accordionId="4">
                        <code>{'<REGION_CODE>: '}</code> The following region codes are available: 
                        <Table bordered hover>
                            <thead>
                                <tr key={"header-2"}>
                                    <th key={"seq-2"}>
                                        Region
                                    </th>
                                    <th key={"length-2"}>
                                        <code>{'<REGION_CODE>'}</code>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={"amy-row-2"}>
                                    <td key={"amy-2"}>
                                        Amygdala
                                    </td>
                                    <td key={"amy-code-2"}>
                                        <code>{'amy'}</code>
                                    </td>
                                </tr>
                                <tr key={"bs-row-2"}>
                                    <td key={"bs-2"}>
                                        Brain Stem
                                    </td>
                                    <td key={"bs-code-2"}>
                                        <code>{'bs'}</code>
                                    </td>
                                </tr>
                                <tr key={"cn-row-2"}>
                                    <td key={"cn-2"}>
                                        Caudate Nucleus
                                    </td>
                                    <td key={"cn-code-2"}>
                                        <code>{'cn'}</code>
                                    </td>
                                </tr>
                                <tr key={"cing-row-2"}>
                                    <td key={"cing-2"}>
                                        Cingulum
                                    </td>
                                    <td key={"cing-code-2"}>
                                        <code>{'cing'}</code>
                                    </td>
                                </tr>
                                <tr key={"cv-row-2"}>
                                    <td key={"cv-2"}>
                                        Cerebellar Vermis
                                    </td>
                                    <td key={"cv-code-2"}>
                                        <code>{'cv'}</code>
                                    </td>
                                </tr>
                                <tr key={"dg-row-2"}>
                                    <td key={"dg-2"}>
                                        Dentate Gyrus
                                    </td>
                                    <td key={"dg-code-2"}>
                                        <code>{'dg'}</code>
                                    </td>
                                </tr>
                                <tr key={"fcm-row-2"}>
                                    <td key={"fcm-2"}>
                                        Frontal Cortex Media
                                    </td>
                                    <td key={"fcm-code-2"}>
                                        <code>{'fcm'}</code>
                                    </td>
                                </tr>
                                <tr key={"fwm-row-2"}>
                                    <td key={"fwm-2"}>
                                        Frontal White Matter
                                    </td>
                                    <td key={"fwm-code-2"}>
                                        <code>{'fwm'}</code>
                                    </td>
                                </tr>
                                <tr key={"hip-row-2"}>
                                    <td key={"hip-2"}>
                                        Hippocampus
                                    </td>
                                    <td key={"hip-code-2"}>
                                        <code>{'hip'}</code>
                                    </td>
                                </tr>
                                <tr key={"le-row-2"}>
                                    <td key={"le-2"}>
                                        Lentiform Extern
                                    </td>
                                    <td key={"le-code-2"}>
                                        <code>{'le'}</code>
                                    </td>
                                </tr>
                                <tr key={"li-row-2"}>
                                    <td key={"li-2"}>
                                        Lentiform Intern
                                    </td>
                                    <td key={"li-code-2"}>
                                        <code>{'li'}</code>
                                    </td>
                                </tr>
                                <tr key={"ob-row-2"}>
                                    <td key={"ob-2"}>
                                        Olfactory Bulb
                                    </td>
                                    <td key={"ob-code-2"}>
                                        <code>{'ob'}</code>
                                    </td>
                                </tr>
                                <tr key={"oc-row-2"}>
                                    <td key={"oc-2"}>
                                        Occipital Cortex
                                    </td>
                                    <td key={"oc-code-2"}>
                                        <code>{'oc'}</code>
                                    </td>
                                </tr>
                                <tr key={"pc-row-2"}>
                                    <td key={"pc-2"}>
                                        Parietal Cortex
                                    </td>
                                    <td key={"pc-code-2"}>
                                        <code>{'pc'}</code>
                                    </td>
                                </tr>
                                <tr key={"pons-row-2"}>
                                    <td key={"pons-2"}>
                                        Pons
                                    </td>
                                    <td key={"pons-code-2"}>
                                        <code>{'pons'}</code>
                                    </td>
                                </tr>
                                <tr key={"put-row-2"}>
                                    <td key={"put-2"}>
                                        Putamen
                                    </td>
                                    <td key={"put-code-2"}>
                                        <code>{'put'}</code>
                                    </td>
                                </tr>
                                <tr key={"sn-row-2"}>
                                    <td key={"sn-2"}>
                                        Substantia Nigra
                                    </td>
                                    <td key={"sn-code-2"}>
                                        <code>{'sn'}</code>
                                    </td>
                                </tr>
                                <tr key={"tc-row-2"}>
                                    <td key={"tc-2"}>
                                        Temportal Cortex
                                    </td>
                                    <td key={"tc-code-2"}>
                                        <code>{'tc'}</code>
                                    </td>
                                </tr>
                                <tr key={"thal-row-2"}>
                                    <td key={"thal-2"}>
                                        Thalamus
                                    </td>
                                    <td key={"thal-code-2"}>
                                        <code>{'thal'}</code>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <code>{'<PROTEIN_ID>: '}</code> This works only for UniProt IDs. Alternate protein IDs will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/proteins/p07197/region/amy</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="5"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/proteins/{'<PROTEIN_ID>'}</code>&nbsp; Get IBPM PhosphoMap entry data for a particular protein</AccordionHeader>
                    <AccordionBody accordionId="5">
                        <code>{'<PROTEIN_ID>: '}</code> This works only for UniProt IDs. Alternate protein IDs will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/proteins/p07197</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="6"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/proteins/{'<PROTEIN_ID>/region/<REGION_CODE>'}</code>&nbsp; Get IBPM entry data for a particular region, for a particular protein</AccordionHeader>
                    <AccordionBody accordionId="6">
                    <code>{'<REGION_CODE>: '}</code> The following region codes are available: 
                        <Table bordered hover>
                            <thead>
                                <tr key={"header-phos-1"}>
                                    <th key={"seq-phos-1"}>
                                        Region
                                    </th>
                                    <th key={"length-phos-1"}>
                                        <code>{'<REGION_CODE>'}</code>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={"amy-row-phos-1"}>
                                    <td key={"amy-phos-1"}>
                                        Amygdala
                                    </td>
                                    <td key={"amy-code-phos-1"}>
                                        <code>{'amy'}</code>
                                    </td>
                                </tr>
                                <tr key={"bs-row-phos-1"}>
                                    <td key={"bs-phos-1"}>
                                        Brain Stem
                                    </td>
                                    <td key={"bs-code-phos-1"}>
                                        <code>{'bs'}</code>
                                    </td>
                                </tr>
                                <tr key={"cn-row-phos-1"}>
                                    <td key={"cn-phos-1"}>
                                        Caudate Nucleus
                                    </td>
                                    <td key={"cn-code-phos-1"}>
                                        <code>{'cn'}</code>
                                    </td>
                                </tr>
                                <tr key={"cing-row-phos-1"}>
                                    <td key={"cing-phos-1"}>
                                        Cingulum
                                    </td>
                                    <td key={"cing-code-phos-1"}>
                                        <code>{'cing'}</code>
                                    </td>
                                </tr>
                                <tr key={"cv-row-phos-1"}>
                                    <td key={"cv-phos-1"}>
                                        Cerebellar Vermis
                                    </td>
                                    <td key={"cv-code-phos-1"}>
                                        <code>{'cv'}</code>
                                    </td>
                                </tr>
                                <tr key={"dg-row-phos-1"}>
                                    <td key={"dg-phos-1"}>
                                        Dentate Gyrus
                                    </td>
                                    <td key={"dg-code-phos-1"}>
                                        <code>{'dg'}</code>
                                    </td>
                                </tr>
                                <tr key={"fc-row-phos-1"}>
                                    <td key={"fc-phos-1"}>
                                        Frontal Cortex
                                    </td>
                                    <td key={"fc-code-phos-1"}>
                                        <code>{'fc'}</code>
                                    </td>
                                </tr>
                                <tr key={"hip-row-phos-1"}>
                                    <td key={"hip-phos-1"}>
                                        Hippocampus
                                    </td>
                                    <td key={"hip-code-phos-1"}>
                                        <code>{'hip'}</code>
                                    </td>
                                </tr>
                                <tr key={"mc-row-phos-1"}>
                                    <td key={"mc-phos-1"}>
                                        Motor Cortex
                                    </td>
                                    <td key={"mc-code-phos-1"}>
                                        <code>{'mc'}</code>
                                    </td>
                                </tr>
                                <tr key={"sn-row-phos-1"}>
                                    <td key={"sn-phos-1"}>
                                        Substantia Nigra
                                    </td>
                                    <td key={"sn-code-phos-1"}>
                                        <code>{'sn'}</code>
                                    </td>
                                </tr>
                                <tr key={"sc-row-phos-1"}>
                                    <td key={"sc-phos-1"}>
                                        Spinal Cord
                                    </td>
                                    <td key={"sc-code-phos-1"}>
                                        <code>{'sc'}</code>
                                    </td>
                                </tr>
                                <tr key={"thal-row-phos-1"}>
                                    <td key={"thal-phos-1"}>
                                        Thalamus
                                    </td>
                                    <td key={"thal-code-phos-1"}>
                                        <code>{'thal'}</code>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <code>{'<PROTEIN_ID>: '}</code> This works only for UniProt IDs. Alternate protein IDs will not return any response. 
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/proteins/p07197/region/amy</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="7"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/peptides/{'<PEPTIDE_SEQUENCE>'}</code>&nbsp; Get IBPM PhosphoMap entry data for a particular peptide</AccordionHeader>
                    <AccordionBody accordionId="7">
                        <code>{'<PEPTIDE_SEQUENCE>: '}</code> Amino Acid Sequence of Peptide.
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/peptides/SAKEEIAEYRR</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="8"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/peptides/{'<PEPTIDE_SEQUENCE>/region/<REGION_CODE>'}</code>&nbsp; Get IBPM PhosphoMap entry data for a particular region, for a particular peptide</AccordionHeader>
                    <AccordionBody accordionId="8">
                    <Table bordered hover>
                            <thead>
                                <tr key={"header-phos-2"}>
                                    <th key={"seq-phos-2"}>
                                        Region
                                    </th>
                                    <th key={"length-phos-2"}>
                                        <code>{'<REGION_CODE>'}</code>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={"amy-row-phos-2"}>
                                    <td key={"amy-phos-2"}>
                                        Amygdala
                                    </td>
                                    <td key={"amy-code-phos-2"}>
                                        <code>{'amy'}</code>
                                    </td>
                                </tr>
                                <tr key={"bs-row-phos-2"}>
                                    <td key={"bs-phos-2"}>
                                        Brain Stem
                                    </td>
                                    <td key={"bs-code-phos-2"}>
                                        <code>{'bs'}</code>
                                    </td>
                                </tr>
                                <tr key={"cn-row-phos-2"}>
                                    <td key={"cn-phos-2"}>
                                        Caudate Nucleus
                                    </td>
                                    <td key={"cn-code-phos-2"}>
                                        <code>{'cn'}</code>
                                    </td>
                                </tr>
                                <tr key={"cing-row-phos-2"}>
                                    <td key={"cing-phos-2"}>
                                        Cingulum
                                    </td>
                                    <td key={"cing-code-phos-2"}>
                                        <code>{'cing'}</code>
                                    </td>
                                </tr>
                                <tr key={"cv-row-phos-2"}>
                                    <td key={"cv-phos-2"}>
                                        Cerebellar Vermis
                                    </td>
                                    <td key={"cv-code-phos-2"}>
                                        <code>{'cv'}</code>
                                    </td>
                                </tr>
                                <tr key={"dg-row-phos-2"}>
                                    <td key={"dg-phos-2"}>
                                        Dentate Gyrus
                                    </td>
                                    <td key={"dg-code-phos-2"}>
                                        <code>{'dg'}</code>
                                    </td>
                                </tr>
                                <tr key={"fc-row-phos-2"}>
                                    <td key={"fc-phos-2"}>
                                        Frontal Cortex
                                    </td>
                                    <td key={"fc-code-phos-2"}>
                                        <code>{'fc'}</code>
                                    </td>
                                </tr>
                                <tr key={"hip-row-phos-2"}>
                                    <td key={"hip-phos-2"}>
                                        Hippocampus
                                    </td>
                                    <td key={"hip-code-phos-2"}>
                                        <code>{'hip'}</code>
                                    </td>
                                </tr>
                                <tr key={"mc-row-phos-2"}>
                                    <td key={"mc-phos-2"}>
                                        Motor Cortex
                                    </td>
                                    <td key={"mc-code-phos-2"}>
                                        <code>{'mc'}</code>
                                    </td>
                                </tr>
                                <tr key={"sn-row-phos-2"}>
                                    <td key={"sn-phos-2"}>
                                        Substantia Nigra
                                    </td>
                                    <td key={"sn-code-phos-2"}>
                                        <code>{'sn'}</code>
                                    </td>
                                </tr>
                                <tr key={"sc-row-phos-2"}>
                                    <td key={"sc-phos-2"}>
                                        Spinal Cord
                                    </td>
                                    <td key={"sc-code-phos-2"}>
                                        <code>{'sc'}</code>
                                    </td>
                                </tr>
                                <tr key={"thal-row-phos-2"}>
                                    <td key={"thal-phos-2"}>
                                        Thalamus
                                    </td>
                                    <td key={"thal-code-phos-2"}>
                                        <code>{'thal'}</code>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                        <code>{'<PEPTIDE_SEQUENCE>: '}</code> Amino Acid Sequence of Peptide.
                        <br />
                        Example:&nbsp;<Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/ibpm/phosphomap/peptides/SAKEEIAEYRR/region/amy</code>&nbsp;
                    </AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
                <h5>
                    For HBDA
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="9"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>/api/hbda/disease_table</code>&nbsp; Get the disease table in HBDA</AccordionHeader>
                    <AccordionBody accordionId="9"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
                <h5>
                    For BDTM
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="10"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdtm/genes/{'<GENE_NAME>'}</code>&nbsp; Get BDTM entry data and metadata for a particular gene</AccordionHeader>
                    <AccordionBody accordionId="10"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="11"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdtm/genes/{'<GENE_NAME>'}/disease/{'<DISEASE_ID>'}</code>&nbsp; Get BDTM entry data and metadata for a particular gene and a particular disease</AccordionHeader>
                    <AccordionBody accordionId="11"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="12"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdtm/metadata/{'<DISEASE_ID>'}</code>&nbsp; Get BDTM entry metadata for a particular disease</AccordionHeader>
                    <AccordionBody accordionId="12"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
                <h5>
                    For BDPM
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="13"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdpm/proteins/{'<PROTEIN_ID>'}</code>&nbsp; Get BDPM entry data and metadata for a particular protein</AccordionHeader>
                    <AccordionBody accordionId="13"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="14"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdpm/proteins/{'<PROTEIN_ID>'}/disease/{'<DISEASE_ID>'}</code>&nbsp; Get BDPM entry data and metadata for a particular protein and a particular disease</AccordionHeader>
                    <AccordionBody accordionId="14"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="15"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdpm/metadata/{'<DISEASE_ID>'}</code>&nbsp; Get BDPM entry metadata for a particular disease</AccordionHeader>
                    <AccordionBody accordionId="15"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
                <h5>
                    For BDMC
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="16"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdmc/genes/{'<GENE_NAME>'}</code>&nbsp; Get BDMC entry data for a particular gene</AccordionHeader>
                    <AccordionBody accordionId="16"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="17"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdmc/genes/{'<GENE_NAME>'}/disease/{'<DISEASE_ID>'}</code>&nbsp; Get BDMC entry data for a particular gene and a particular disease</AccordionHeader>
                    <AccordionBody accordionId="17"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="18"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bdmc/disease/{'<DISEASE_ID>'}</code>&nbsp; Get BDMC entry data for a particular disease</AccordionHeader>
                    <AccordionBody accordionId="18"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
                <h5>
                    For BDDF
                </h5>
                <div>
                <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                    <AccordionHeader targetId="19"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/bindingdb/{'<UNIPROT_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from the BindingDB API </AccordionHeader>
                    <AccordionBody accordionId="19"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="20"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/chembl/{'<UNIPROT_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from the CHEMBL API </AccordionHeader>
                    <AccordionBody accordionId="20"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="21"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/ttd/{'<UNIPROT_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from the TTD API </AccordionHeader>
                    <AccordionBody accordionId="21"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="22"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/clue/{'<UNIPROT_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from the CLUE API </AccordionHeader>
                    <AccordionBody accordionId="22"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="23"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/stitch/{'<UNIPROT_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from the Stitch API </AccordionHeader>
                    <AccordionBody accordionId="23"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="24"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/opentargets/chembl/{'<CHEMBL_ID>'}</code>&nbsp; Get BDDF entry data for a particular ChEMBL ID from the OpenTargets API </AccordionHeader>
                    <AccordionBody accordionId="24"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="25"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/protein/all/{'<SMILES_ID>'}</code>&nbsp; Get BDDF entry data for a particular Uniprot ID from combined APIs of all BindingDb, ChemBL, Stitch, TTD and Clue</AccordionHeader>
                    <AccordionBody accordionId="25"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="26"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/disease/{'<DISEASE_ID>'}</code>&nbsp; Get BDDF entry data for a particular disease </AccordionHeader>
                    <AccordionBody accordionId="26"></AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                    <AccordionHeader targetId="27"><Badge color='primary'>GET</Badge>&nbsp;&nbsp;<code>api/bddf/compound/{'<DISEASE_ID>'}</code>&nbsp; Get BDDF clinical trial data for a particular disease </AccordionHeader>
                    <AccordionBody accordionId="27"></AccordionBody>
                    </AccordionItem>
                </Accordion>
                </div>
            </div>
        </Container>
        <div style={{height: 400}} />
    </>
  );
}