import React from 'react';
import { format } from 'd3-format';
import { scaleLinear, scaleOrdinal, scaleThreshold, scaleQuantile } from '@visx/scale';
import { GlyphStar, GlyphWye, GlyphTriangle, GlyphDiamond } from '@visx/glyph';
import {
  Legend,
  LegendLinear,
  LegendQuantile,
  LegendOrdinal,
  LegendSize,
  LegendThreshold,
  LegendItem,
  LegendLabel,
} from '@visx/legend';
import { LegendTemplate } from './legendTemplate';
import { LinearGradient } from '@visx/gradient';

export const pumpkin = '#f5810c';
export const blue = '#0acffe';
export const green = '#9be15d';

const getPieColor = scaleOrdinal({
    domain: ['Not yet recruiting', 'Recruiting', 'Enrolling by invitation', 'Active, not recruiting', 
    'Suspended', 'Terminated', 'Completed', 'Withdrawn', 'Unknown status', 'Available', 'No longer available'],
    range: [
      "rgba(82, 215, 38, 0.95)",
      "rgba(255, 236, 0, 0.95)",
      "rgba(255, 115, 0, 0.95)",
      "rgba(213, 45, 183, 0.95)",
      "rgba(255, 0, 0, 0.95)",
      "rgba(0, 126, 214, 0.95)",
      "rgba(96, 80, 220, 0.95)",
      "rgba(128, 128, 0, 0.95)",
      "rgba(93,30,91, 0.95)",
      "rgba(124, 221, 221, 0.95)"
    ]
  });



const legendGlyphSize = 30;

export default function PieChartLegend(width, height) {
  return (
    <>
    <div>
    <LegendOrdinal scale={getPieColor}>
          {(labels) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {labels.map((label, i) => (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="2px 5px"
                >
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                  </svg>
                  <LegendLabel align="left" margin="0 0 0 4px">
                    {label.text}
                  </LegendLabel>
                </LegendItem>
              ))}
            </div>
          )}
        </LegendOrdinal>
    </div>
    </>
  );
}