import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Table, TabPane, Card, CardBody, Button } from 'reactstrap';
import { LineSeparator } from '../../components/common/separatorLine';
import { MDBDataTableV5 } from 'mdbreact';

export default function HBDALanding() {

    return(
        <>
            <LineSeparator />
            <BDDFTable />
        </>
    )

}

export class BDDFTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            diseaseTableJSX: null,
        };
        this.diseaseTable = null;
        this.diseaseTableKeys = null;
        this.tableData = {columns:[
            {
                label: 'Disease Name',
                field: 'diseaseName',
                width: 300
            },
            {
                label: 'MeSH ID',
                field: 'MESHID',
                width: 100
            }
        ],
            rows: []
        }
        
    }

    componentDidMount() {
        fetch(`https://api.brainprot.org/api/hbda/disease_table`)
        .then((res) => res.json())
        .then((result) => {
                this.setState({
                    isLoaded: true,
                    diseaseTableJSX: this.setupDiseaseData(result),
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
            }
        )
    
    }

    

    setupDiseaseData(result){
        this.diseaseTable = result.diseaseTableData;
        var removeByAttr = function(arr, attr, value){
            var i = arr.length;
            const excludeId = ["D004410", "D009442", "D010235", "D017096"]
            while(i--){
                if((excludeId.includes(arr[i]['MESHID']))){ 
                    arr.splice(i,1);
                }
            }
            return arr;
        }
        removeByAttr(this.diseaseTable )
        this.diseaseTableKeys = Object.keys(result.diseaseTableData[0]);
        this.diseaseTable.map((item, index) => {
            var url_hbda = `/bddf/clinical/${item.MESHID}`
            item.diseaseName = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_hbda, "_self")}
                    >
                        {item.diseaseName}
                    </div>
                </div>
            );
            var url_mesh = `https://www.ncbi.nlm.nih.gov/mesh/${item.MESHID}`
            item.MESHID = (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div
                        style={{
                        cursor: "pointer",
                        color: "blue",
                        }}
                        onClick={() => window.open(url_mesh, '_blank')}
                    >
                        {item.MESHID}
                    </div>
                </div>
            );
            })
            this.tableData.rows = this.diseaseTable;
    }


    render() {
        console.log(this.diseaseTable);
        const { error, isLoaded, diseaseTableJSX } = this.state;
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {

        return(
        <>
            <Container fluid className='center'>
                <br />
                <Button href='/bddf' style={{backgroundColor: "#f7931e", color: "black"}}><h5>Go back to BDDF Homepage</h5></Button>
                <LineSeparator />
                <Card>
                    <CardBody style={{'color': 'black'}}>
                        <h2 style={{textAlign: 'center'}}>Brain Disease Drug Finder</h2>
                        <h5 style={{textAlign: 'center'}}>Click on any disease's name to access its data!</h5>
                        <MDBDataTableV5 
                            entries={10}
                            scrollX
                            hover
                            data = {this.tableData}
                        />
                    </CardBody>
                </Card>
            </Container>        
        </>
        );
    }
    }
}