import { MainNavBar } from "./common/mainNavBar";
import { MainFooter } from "./common/mainFooter";
import { MainSearchBar } from "./common/mainSearchBar";
import { Hero } from "./common/hero";

export function Base() {
    return (
      <>
        <MainNavBar />
      </>
    );
}