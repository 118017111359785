import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Col, Container, Row, Table, TabPane, Card, CardBody, Button } from 'reactstrap';
import { LineSeparator } from '../../components/common/separatorLine';

export default function HBDALanding() {

    return(
        <>

            <LineSeparator />
            <BDDFStaticTable />
        </>
    )

}

export class BDDFStaticTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
        };
        this.diseaseTable = [
            {MESHID: "D000544",
            diseaseName: "Alzheimer's Disease"},
            {MESHID: "D000690",
            diseaseName: "Amyotrophic Lateral Sclerosis"},
            {MESHID: "D057174",
            diseaseName: "Frontotemporal Dementia"},
            {MESHID: "D005909",
            diseaseName: "Glioblastoma"},
            {MESHID: "D005910",
            diseaseName: "Glioma"},
            {MESHID: "D006816",
            diseaseName: "Huntington's Disease"},
            {MESHID: "D008527",
            diseaseName: "Medulloblastoma"},
            {MESHID: "D008579",
            diseaseName: "Meningioma"},
            {MESHID: "D009103",
            diseaseName: "Multiple Sclerosis"},
            {MESHID: "D010300",
            diseaseName: "Parkinson's Disease"},
            {MESHID: "D049912",
            diseaseName: "Pituitary Adenoma"}
        ]
        this.diseaseTableKeys = null;
    }

    componentDidMount() {
        const ele = document.getElementById('ipl-progress-indicator')
        if(ele){
          // fade out
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        }
    }

    setupDiseaseData(result){
        this.diseaseTable = result.diseaseTableData;
        this.diseaseTableKeys = Object.keys(result.diseaseTableData[0]);
    }
    
    unpackDiseaseData(diseaseData){
            return(
                <>
                    {diseaseData.map((eachDisease) => (  
                        <tr key={`${eachDisease['MESHID']}-`}>
                            <td key={`${eachDisease['MESHID']}-1`}><a href={`/bddf/disease/${eachDisease['MESHID']}`}>{eachDisease['diseaseName']}</a></td>
                            <td key={`${eachDisease['MESHID']}-2`}><a href={`https://www.ncbi.nlm.nih.gov/mesh/${eachDisease['MESHID']}`} target='blank'>{eachDisease['MESHID']}</a></td>
                        </tr>
                    ))}
                </>
            )
    }


    render() {
        return(
        <>
            <Container fluid className='center text-center'>
                <br />
                <Button href='/bddf' style={{backgroundColor: "#f7931e", color: "black"}}><h5>Go back to BDDF Homepage</h5></Button>
                <LineSeparator />
                <Card>
                    <CardBody style={{'color': 'black'}}>
                        <h2 style={{textAlign: 'center'}}>Brain Disease Drug Finder</h2>
                        <h5 style={{textAlign: 'center'}}>Click on any disease's name to access its data!</h5>
                            <Table hover bordered>
                            <thead>
                                <tr key={"header"}>
                                    <th>Disease Name (Click to access)</th>
                                    <th>MESH ID</th>
                                </tr>
                            </thead>
                            <tbody> 
                                {this.unpackDiseaseData(this.diseaseTable)}
                            </tbody>
                            </Table>
                    </CardBody>
                </Card>
            </Container>        
        </>
        );
    }
}