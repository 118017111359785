import React from 'react';
import { Col, Container, Row, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { MainSearchBar } from '../../components/common/mainSearchBar';
import { LineSeparator } from '../../components/common/separatorLine';

export default function BDDFLanding() {
    const ele = document.getElementById('ipl-progress-indicator');
    if(ele){
        ele.classList.add('available');
        setTimeout(() => {
            ele.remove();
        }, 2000)
    };

    return (
        <>
            <Container fluid>
            <br />
            <h1 style={{textAlign: "center"}}>Welcome to the Brain Disease Drug Finder (BDDF)</h1>
            <LineSeparator />
            <MainSearchBar defPortal={'BDDF'}/>
            <h6 className='text-center'>Example: <a href='/bddf/entry/P10145'>P10145</a>,&nbsp;
            <a href='/bddf/entry/P56817'>P56817</a>,&nbsp;
            <a href='/bddf/entry/P22303'>ACHE</a>,&nbsp;
            <a href='/bddf/entry/P56817'>BACE1</a></h6>
            <LineSeparator />
            </Container>
            <Container>
            <Row>
                <Col sm='12'>
                    <figure>
                            <img src="/images/bddf/BDDF.svg" alt="BDDF_Image" className="center" style={{'maxHeight': 700}}/>
                    </figure>

                    <Row>
                    <Col sm='6' className='center text-center'>
                            <Button style={{backgroundColor: "#f7931e", color: "black"}} href="/bddf/disease_table"><h3>Clinical Trial Information</h3></Button>
                        </Col>
                        <Col sm='6' className='right'>
                            <Button style={{backgroundColor: "#f7931e", color: "black"}} href="/bddf/disease_static_table"><h3>Disease Therapeutic Compounds</h3></Button>
                        </Col>
                    </Row>
                    { /*<Button color="warning" style={{ marginBottom: '1rem' }} id={`datasheet-toggle`} className="center">
                        <h3>Navigate to a Disease's Top Drugs (Click Here!)</h3>
                    </Button>
                    <UncontrolledCollapse toggler={`#datasheet-toggle`}>
                        <div style={{
                            borderRadius: 20,
                            background: "#FF9933",
                            padding: 10,
                            color: "black",
                            width: "50%"
                        }} className="center">
                            <h3 style={{textAlign: 'center'}}>Click on a disease to view its summary!</h3>
                            <Table hover borderless>
                                <tbody>
                                    {tabList.map((tab) => {
                                        return(
                                            <tr key={`${tab['diseaseName']}-row`} onClick={(e) => {e.preventDefault(); window.location.href=`/bddf/${tab['diseaseId']}`;}}>
                                                <td key={tab['diseaseName']} style={{textAlign: 'center'}}>
                                                    <h4>{tab['diseaseName']}</h4>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        </UncontrolledCollapse>*/ }
                    <br />
                    <h2 style={{textAlign: "left"}}>About BDDF</h2>
                    <p style={{fontSize:"20px"}}>
                        The Brain Disease Drug Finder (BDDF) allows users to investigate and retrieve clinical trial information related to over 50 brain 
                        diseases and access therapeutic compounds targeting potential markers associated with these diseases. BDDF also enables users to search 
                        for chemical compounds interacting with specific genes or proteins of interest. By integrating multiple databases and resource hubs 
                        such as&nbsp;
                        <a href="https://www.ebi.ac.uk/chembl/" target='blank'>ChEMBL</a>,&nbsp;
                        <a href="https://www.bindingdb.org/" target='blank'>BindingDB</a>,&nbsp;
                        <a href="https://db.idrblab.net/ttd/" target='blank'>Therapeutic Target Database(TTD)</a>,&nbsp;
                        <a href="https://clue.io/" target='blank'>CLUE</a>,&nbsp;
                        <a href="https://www.opentargets.org/" target='blank'>Open Targets</a>,&nbsp;
                        <a href="https://pubchem.ncbi.nlm.nih.gov/" target='blank'>PubChem</a>, and&nbsp;
                        <a href="https://clinicaltrials.gov/" target='blank'>ClinicalTrials.gov</a>
                        &nbsp;                        BDDF offers a comprehensive overview of the therapeutic landscape for various diseases.
                        
                    </p>
                    <br />
                    <h5>Last Update </h5>
                    15 May 2023
                    <br />
                    <br />
                    <h4>Publications</h4>
                    No Publication Yet!
                </Col>
                </Row>
            </Container>              
           
        </>
    );
}