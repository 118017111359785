import React from 'react';
import { useParams, Link } from "react-router-dom";
import { Container, TabContent, Nav, NavItem, NavLink, TabPane, Col, Row, Table, UncontrolledCarousel} from 'reactstrap';
import classnames from 'classnames';
import { HBDAScatterPlot } from '../../components/charts/hbdaScatterPlot';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { LineSeparator } from '../../components/common/separatorLine';
import { HorizontalBar } from '../../components/charts/horizontalBar';
import ReadMoreReact from 'read-more-react';

export default function HBDAResult() {
    let params = useParams();
    let diseaseId = params.diseaseId;
    //console.log(diseaseId)
    return (
        <>
            <Result query={diseaseId}/>
        </>
    );
}

const carouselItems = [
    {
        src: '/images/BDMC_Icon.png',
        altText: 'BDMC',
        header: 'BDMC',
        key: 1,
        caption: 'This domain of BrainProt allows users to identify and select markers of alarming Brain tumors and Neurodegenerative disorders which will accelerate biomarker discovery and therapeutic target identification.'
    },
    {
        src: '/images/BDTM_Icon.png',
        altText: 'BDTM',
        header: 'BDTM',
        key: 2,
        caption: 'This domain of BrainProt shows the gene expression profile of alarming Brain tumors and Neurodegenerative disorders mined from public repositories like ArrayExpress and Gene Expression Omnibus (GEO).'
    },
    {
        src: '/images/BDPM_Icon.png',
        altText: 'BDPM',
        header: 'BDPM',
        key: 3,
        caption: 'This domain of BrainProt shows the protein expression profile and identified peptides of alarming Brain tumors and Neurodegenerative disorders mined from public repositories like ProteomeXchange and OmicsDi.'
    },
    {
        src: '/images/BDDF_Icon.png',
        altText: 'BDDF',
        header: 'BDDF',
        key: 4,
        caption: 'This domain of BrainProt allows users to curate, select and identify probable drug and chemical compounds against the query (protein/gene) search. BDDF is a platform that integrates popularly available drug repositories and knowledgebases.'
    }
];

export class Result extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            activeTab: 'a',
            resultJSX: null,
        };
        this.query = this.props.query;
        this.diseaseId = null;
        this.diseaseName = null;
        this.pageresult = null;
        this.meshId = null;
        this.medGenUid = null;
        this.umlsId = null;
        this.diseaseOntology = null;
        this.nci = null;
        this.gard = null;
        this.kegg = null;
        this.hpo = null;
        this.monarchInitiative = null;
        this.orphanet = null;
        this.omim = null;
        this.diseaseDescription = null;
    }


    componentDidMount() {
        if (this.props.query) {
            this.diseaseId = this.query;
            fetch(`https://api.brainprot.org/api/hbda/disease_table`)
            .then((res) => res.json())
            .then((allResult) => {
                console.log(allResult);
                this.setState({
                    isLoaded: true,
                    resultJSX: this.setupData(allResult)
                });
                const ele = document.getElementById('ipl-progress-indicator');
                if(ele && this.state.isLoaded){
                    ele.classList.add('available');
                    setTimeout(() => {
                        ele.remove();
                    }, 2000)
                };
                },
                
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    const ele = document.getElementById('ipl-progress-indicator');
                    if(ele && this.state.isLoaded){
                        ele.classList.add('available');
                        setTimeout(() => {
                            ele.remove();
                        }, 2000)
                    };
                }
            )
        };
    }

    modifyDiseaseName(diseaseName){
        let modifiedDisease = diseaseName.toLowerCase();
        modifiedDisease = modifiedDisease.replace("'s", "");
        return modifiedDisease;
    }
    

    setupData(allResult) {
        this.pageresult = allResult.diseaseTableData.find(disease => disease.MESHID === this.diseaseId);;
        this.diseaseName = this.pageresult.diseaseName;
        this.meshId = this.pageresult.MESHID;
        this.umlsId = this.pageresult.umlsId;
        this.medGenUid = this.pageresult.MedGenUID;
        this.gard = this.pageresult.GARD;
        this.diseaseOntology = this.pageresult.diseaseOntology;
        this.nci = this.pageresult.NCI;
        this.kegg = this.pageresult.KEGG;
        this.hpo = this.pageresult.HPO;
        this.monarchInitiative = this.pageresult.monarchInitiative;
        this.omim = this.pageresult.OMIM;
        this.diseaseDescription = this.pageresult.description;
        //console.log(this.pageresult)
        
        if (this.pageresult.length == 0) {
            return (
                <>
                    <main style={{ padding: "1rem" }}>
                        <h3>Our Search Duck didn't find anything!</h3>
                        <p>Looks like your query was unable to return anything in our portal. Please check your query and retry!</p>
                        <img src='/images/duck_conf.gif' />
                    </main>
                </>
            )
        }
    }

    render() {
        const { error, isLoaded, resultJSX } = this.state;
        const bdmcPath = "/images/BDMC_Icon.svg";
        const bdtmPath = "/images/BDTM_Icon.svg";
        const bdpmPath = "/images/BDPM_Icon.svg";
        const bddfPath = "/images/BDDF_Icon.svg";
        const ele = document.getElementById('ipl-progress-indicator');
        if(ele && isLoaded){
            ele.classList.add('available');
            setTimeout(() => {
                ele.remove();
            }, 2000)
        };
        if (error) {
        return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>    
                    Loading!
                </>    
                );
        } else {
        return ( 
            <>
                <Container fluid>
                    <div>
                        <h1 className="text-center"> Welcome to the Human Brain Disease Atlas (HBDA)</h1>
                        <LineSeparator />
                        <h2>{this.diseaseName}: {this.diseaseId}</h2>
                        <p>
                            <ReadMoreReact 
                                text={this.diseaseDescription + "  Read Less..."}
                                min={500}
                                ideal={500}
                                max={this.diseaseDescription.length} 
                                readMoreText="Read More..."
                            />
                        </p>
                    </div>
                    <Row>
                        <Col sm="4">
                            <div style={{
                                borderRadius: 20,
                                background: "white",
                                padding: 10,
                                color: "black",
                            }}>
                                <h5 style={{textAlign: 'center'}}><b>Disease Resources: {this.proteinName}</b></h5>
                                <Container fluid>
                                    <Table hover borderless>
                                    <tbody>
                                        <tr key={"disease"}>
                                            <th scope='row' key={"col-1-1"}>
                                                Disease Name:
                                            </th>
                                            <td key={"col-2-1"}>
                                                {this.diseaseName}
                                            </td>
                                        </tr>
                                        <tr key={"mesh"}>
                                            <th scope='row' key={"col-1-2"}>
                                                MeSH ID:
                                            </th>
                                            <td key={"col-2-2"}>
                                                <a href={`https://www.ncbi.nlm.nih.gov/mesh/${this.meshId}`} target='blank'>{this.meshId}</a>
                                            </td>
                                        </tr>
                                        <tr key={"medgen"}>
                                            <th scope='row' key={"col-1-3"}>
                                                MedGen UID:
                                            </th>
                                            <td key={"col-2-3"}>
                                                <a href={`https://www.ncbi.nlm.nih.gov/medgen/${this.medGenUid}`} target='blank'>{this.medGenUid}</a>
                                            </td>
                                        </tr>
                                        <tr key={"umls"}>
                                            <th scope='row' key={"col-1-4"}>
                                                UMLS:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://www.disgenet.org/browser/0/1/0/${this.umlsId}/_a/_b./`} target='blank'>{this.umlsId}</a>
                                            </td>
                                        </tr>
                                        <tr key={"do"}>
                                            <th scope='row' key={"col-1-4"}>
                                                Disease Ontology (DO):
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://disease-ontology.org/?id=DOID:${this.diseaseOntology}`} target='blank'>{this.diseaseOntology}</a>
                                            </td>
                                        </tr>
                                        <tr key={"nci"}>
                                            <th scope='row' key={"col-1-4"}>
                                                NCI:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://ncit.nci.nih.gov/ncitbrowser/ConceptReport.jsp?dictionary=NCI_Thesaurus&code=${this.nci}`} target='blank'>{this.nci}</a>
                                            </td>
                                        </tr>
                                        <tr key={"gard"}>
                                            <th scope='row' key={"col-1-4"}>
                                                GARD:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://rarediseases.info.nih.gov/diseases/${this.gard}/${this.modifyDiseaseName(this.diseaseName)}`} target='blank'>{this.gard}</a>
                                            </td>
                                        </tr>
                                        <tr key={"kegg"}>
                                            <th scope='row' key={"col-1-4"}>
                                                KEGG:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://www.genome.jp/entry/pathway+${this.kegg}`} target='blank'>{this.kegg}</a>
                                            </td>
                                        </tr>
                                        <tr key={"hpo"}>
                                            <th scope='row' key={"col-1-4"}>
                                                MSeqDR:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://mseqdr.org/search_phenotype.php?hponame=${this.hpo}&dbsource=genomic`} target='blank'>{this.hpo}</a>
                                            </td>
                                        </tr>
                                        <tr key={"monarchInitiative"}>
                                            <th scope='row' key={"col-1-4"}>
                                            Monarch Initiative:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://monarchinitiative.org/disease/MONDO:${this.monarchInitiative}`} target='blank'>{this.monarchInitiative}</a>
                                            </td>
                                        </tr>
                                        <tr key={"monarchInitiative"}>
                                            <th scope='row' key={"col-1-4"}>
                                            OMIM:
                                            </th>
                                            <td key={"col-2-4"}>
                                                <a href={`https://www.omim.org/entry/${this.omim}`} target='blank'>{this.omim}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </Table>
                                </Container>
                            </div>
                        </Col>
                        <Col sm='8'>
                            <Row>
                                <Col sm='3'>
                                    <a 
                                    href={`https://brainprot.org/bdmc/${this.meshId}`}
                                    className="svg">
                                            <object 
                                            className="bdmc-icon center" 
                                            data={bdmcPath} 
                                            style = {{ width: "100%", height:"100%", position: "relative", zindex: -1}} 
                                            type="image/svg+xml"
                                            />
                                    </a>
                                </Col>
                                <Col sm='3'>
                                    <a 
                                    href={`https://brainprot.org/bdtm`}
                                    className="svg">
                                            <object 
                                            className="bdtm-icon center" 
                                            data={bdtmPath} 
                                            style = {{ width: "100%", height:"100%", position: "relative", zindex: -1}} 
                                            type="image/svg+xml"
                                            />
                                    </a>
                                </Col>
                                <Col sm='3'>
                                    <a 
                                    href={`https://brainprot.org/bdpm`}
                                    className="svg">
                                            <object 
                                            className="bdpm-icon center" 
                                            data={bdpmPath} 
                                            style = {{ width: "100%", height:"100%", position: "relative", zindex: -1}} 
                                            type="image/svg+xml"
                                            />
                                    </a>
                                </Col>
                                <Col sm='3'>
                                    <a 
                                    href={`https://brainprot.org/bddf/clinical/${this.meshId}`}
                                    className="svg">
                                            <object 
                                            className="bddf-icon center" 
                                            data={bddfPath} 
                                            style = {{ width: "100%", height:"100%", position: "relative", zindex: -1}} 
                                            type="image/svg+xml"
                                            />
                                    </a>
                                </Col>
                            </Row>
                            <LineSeparator />
                            <Container>
                                <UncontrolledCarousel id = "hbdaCarousel" styel={{height: 200}} items={carouselItems}/>
                            </Container> 
                        </Col>
                    </Row>
                </Container>
            </>
        );
        }
    }
}
//http://localhost:8983/solr/brainprot/select?q=gene_name_list%3AOMP&wt=json

