import React, { useState } from 'react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

function HelpAccordion(props) {
  const [open, setOpen] = useState('0');
  const toggle = (id) => {
    if (open === id) {
      setOpen('0');
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <Accordion open={open} toggle={toggle} className='bg-dark'>
        <AccordionItem id='hbda-accordion'>
          <AccordionHeader targetId="6"><h3>Human Brain Disease Atlas (HBDA)</h3></AccordionHeader>
          <AccordionBody accordionId="6">
            <h5>What is HBDA? </h5>
            <p>
            The Human Brain Disease Atlas (HBDA) within BrainProt serves as a comprehensive index and navigator, showcasing the extensive scope of the 
            portal. HBDA encompasses data on over 50 human brain diseases, meticulously curated and integrated from various sources, including multiple IDs 
            and associated information on brain disorders. Moreover, HBDA offers streamlined access to disease-specific data across different domains within 
            BrainProt
            </p>

            <h5>Does HBDA contain all the Human Brain Diseases present in MeSH?</h5>
            <p>
            HBDA has been meticulously developed through manual curation of diseases, predominantly sourced from&nbsp;
            <a href="https://www.ncbi.nlm.nih.gov/mesh/" target="blank">MeSH</a>. Currently, the domain encompasses 
            over 50 Brain Diseases, with the potential for expansion through upcoming updates and releases.
            </p>

            <h5>How could HBDA benefit users?</h5>
            <p>
              HBDA provides users with a seamless navigation and exploration experience, enabling access to various IDs and their corresponding disease 
              information related to brain diseases scattered across multiple databases. Additionally, users can conveniently retrieve disease-related 
              information from BDTM, BDPM, BDMC, and BDDF within the same platform.
            </p>
            <h5>What is the future of HBDA?</h5>
            <p>
              The aim and objective of HBDA is to include and integrate all the brain diseases and disorders available in MeSH related to the 
              Human Brain.
            </p>

          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='ibpm-accordion'>
          <AccordionHeader targetId="1"><h3>Inter-Hemisphere Brain Proteome Map (IBPM) with PhosphoMap</h3></AccordionHeader>
          <AccordionBody accordionId="1">
          <h5>What is IBPM?</h5>
            <p>
              The IBPM domain of BrainProt conducts a comprehensive analysis of protein expression profiles and phosphosite characterization in specific 
              regions of the human brain's left and right hemispheres. It provides information on over 3000 proteins and 46,000 peptides, visualized through a 
              Bar Plot showing profiles for the hemispheres and regions. IBPM aims to enhance our understanding of hemisphere functionality related to 
              dominance, lateralization, and the development of brain disorders. Recently, the phosphoproteome section has been integrated under the 
              PhosphoMap. Users can search and access protein expression, peptide information, phosphosite identification, and other valuable brain resources. 
              The portal strives to bridge the gap and complement the efforts of the Human Brain Proteome Project (HBPP) under the patronage of the Human 
              Proteome Organization (HUPO).
            </p>
            <h5>How to use IBPM Portal?</h5>
            <p>
              Search any gene/protein of interest using the search bar above. You will be redirected to a page with the search results. To access the specific 
              entry page for IBPM, click on the corresponding 'IBPM Link' in the column. For the IBPM PhosphoMap Entry Page, select the 'IBPM PhosphoMap Entry' 
              column link. In case you cannot find your desired gene or protein, kindly ensure the accuracy of your query and check if the protein is present 
              in humans, as BrainProt focuses on UniProt's Human Proteome Database. If you continue to encounter difficulties, please reach out to us.
            </p>

            <h5>How to access IBPM PhosphoMap?</h5>
            <p>
              Search the gene/protein of interest using the search bar above. You will be redirected to a page with the search results. To visit the IBPM 
              PhosphoMap entry page, click the 'IBPM PhosphoMap Link.'
            </p>

            <h5>How was the analysis done for the proteomics data available in IBPM?</h5>
            <p>
            Proteomics data was analyzed in MaxQuant, and the log-transformed data of different regions from both hemispheres are shown using bar plots. 
            PhosphoMap integrates the enriched peptide data using the TiO<sub>2</sub> Enrichment Kit. The localization probability of identified phosphosites is 
            included with the peptide data in BrainProt. Under the PhosphoMap section, users can explore the phosphosite profile of each peptide represented 
            by a lollipop plot. It is important to note that sites showing more than one lollipop are due to the missed cleavage of peptides.
            </p>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdmc-accordion'>
          <AccordionHeader targetId="4"><h3>Brain Disease Marker Curator (BDMC)</h3></AccordionHeader>
          <AccordionBody accordionId="4">
            <h5>How to use the BDMC portal?</h5>
            <p> Select any one of the desease from the list of more than 50 human brain diseases available in the BDMC home page. 
              You will be redirected to result page of that particular disease. The interactive scatterplot includes data from 5 popular 
              knowledgebases which includes
              &nbsp;<a href="https://www.disgenet.org/" target='blank'>DisGeNET</a>,&nbsp;  
              <a href="https://maayanlab.cloud/Harmonizome/dataset/DISEASES+Text-mining+Gene-Disease+Assocation+Evidence+Scores" target='blank'>Harmonizome 3.0</a>,&nbsp; 
              <a href="https://heart.shinyapps.io/PubPular/" target='blank'>PubPular 3.1</a>,&nbsp;<a href="http://ctdbase.org/" target='blank'>CTD</a>,&nbsp;and &nbsp;
              <a href="https://diseases.jensenlab.org/Search" target='blank'>Disease 2.0</a>. Two of these knowledgebases could be selected in the 
              X-axis and Y-axis as per user interest to visulaize the top markers associated with the disease. The scatterplot shows top 50 markers as default
              ; however, you can choose the number of markers in the "top N marker" input box. The top 10 marker of the query disease are 
              also shown as a bar plot taking the combination score of the chosen knowledgebases. 
              The result page also includes an option to visulaize the disease assocaited markers available in the 
              &nbsp;<a href="http://edgar.biocomp.unibo.it/gene_disease_db/" target='blank'>eDGAR</a>,&nbsp;
              <a href="http://ctdbase.org/" target='blank'>CTD</a>,&nbsp; <a href="https://diseases.jensenlab.org/Search" target='blank'>Disease 2.0</a> and &nbsp;
              <a href="http://bionda.mpc.ruhr-uni-bochum.de/start.php" target="blank">BIONDA</a>.
              </p>

            <h5>How the scores are calculated for each in BDMC? </h5>
            <p> The raw scores from 5 main knowledgebases (
              <a href="https://www.disgenet.org/" target='blank'>DisGeNET</a>,&nbsp;  
              <a href="https://maayanlab.cloud/Harmonizome/dataset/DISEASES+Text-mining+Gene-Disease+Assocation+Evidence+Scores" target='blank'>Harmonizome 3.0</a>,&nbsp; 
              <a href="https://heart.shinyapps.io/PubPular/" target='blank'>PubPular 3.1</a>,&nbsp;<a href="http://ctdbase.org/" target='blank'>CTD</a>,&nbsp;and &nbsp;
              <a href="https://diseases.jensenlab.org/Search" target='blank'>Disease 2.0</a>) have been included. The scores has been processed and scaled to draw the
              visualisation plots. 
            </p>

            <h5>How BDMC is choosing the markers for eDGAR, CTD, BIONDA and Disease 2.0? </h5>
            <p> The number of genes available in the <a href="http://edgar.biocomp.unibo.it/gene_disease_db/" target='blank'>eDGAR</a> and&nbsp;
            <a href="http://bionda.mpc.ruhr-uni-bochum.de/start.php" target="blank">BIONDA</a> for a particular disease
            are considered as a gene disease associated marker for eDGAR and BIONDA respectively. In regards to <a href="http://ctdbase.org/" target='blank'>CTD</a>,
            the genes with a (M) marker/mechanism and/or (T) therapeutic annotations
            under the Direct Evidence column are considered as CTD marker. The <a href="https://diseases.jensenlab.org/Search" target='blank'>Disease 2.0</a> markers 
            are selected if the gene of a particular disease have a confidence 3 or more than 3 stars. </p>
            
            <h5>Why there are blank data for genes in the BDMC table? </h5>
            <p> The blank value indicates unavailability of data in that particular database. </p>

            <h5>How I can select the best marker from BDMC? </h5>
            <p>BDMC allows you to access, compare and combine markers from multiple sources which could be explored to identify top markers of a 
              disease; however, BDMC also provides a BDMC score which could also be used to identify top markers. The more the BDMC score, the more is the 
              assocation of the gene/protein with the disease.  
            </p>
      
          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdtm-accordion'>
          <AccordionHeader targetId="2"><h3>Brain Disease Transcriptome Map (BDTM)</h3></AccordionHeader>
          <AccordionBody accordionId="2">
            <h5>What kind of data can be accessed by this domain?</h5>
            <p>The Brain Disease Transcriptome Map (BDTM) currently offers microarray-based gene expression data. BDTM provides processed intensities
              of each gene along with meta-data information of each dataset.</p>

            <h5>How to use the BDTM portal?</h5>
            <p>Search any gene that you are interested in by using the search bar above. You will be redirected to a page with the search results.
              To visit the BDTM entry page, click on the 'BDTM Link' column link of the favoured gene. Make sure you have typed your query correctly,
              and whether the gene exists in the human database (BrainProt follows UniProt's Human Proteome Database).
            </p>

            <h5>How the transcriptomics data analysis was done for BDTM?</h5>
            <h6>Data Mining, Filtering and Downloading </h6>
            <p>For the mining of microarray gene expression datasets, well-known databases such as Gene Expression Omnibus (GEO) from NCBI, OMICS DI,
              and ArrayExpress of EMBL EBI were used. The keyword used for the disease search was "Disease Name". The key filtering factors taken into
              account were the following: platform: Affymetrix microarray data, sample type: brain tissue, species: Homo sapiens, and availability of sample
              meta-data. From GEO2R, the meta-data data was downloaded and translated into compatible structured files. (Few datasets have not been taken forward
              due to the unavailability of sample level information and technical anomalies).
            </p>
            <h6>Data Quality Check</h6>
            <p>
              The .CEL format files containing the microarray experimental data were downloaded in the
              R environment to perform the statistical analysis. The Bioconductor package
              arrayQualityMetrics was used to assess the technical quality of arrays which was
              determined based on background values and various scaling factors. Outlier analysis
              was used on the high-quality arrays, included array intensity distribution, principal
              component analysis, array-to-array correlation, and unsupervised clustering.
            </p>
            <h6>Data Analysis: </h6>
            <p>affylmGUI, an R-based Graphical user interface tool, was used to pre- process
              the raw data and perform statistical analysis. The raw data was subjected to
              background correction and normalization using GCRMA (background adjustment using
              sequence information) integrated within afflylmGUI. It adjusts the background,
              normalizes, and log-transforms the probe-level perfect match (PM-values).</p>

            <h5>What diseases are included in BDTM? </h5>
            <p>BDTM includes data from Neurodegenerative diseases namely Alzheimer's disease,
              Parkinson's disease, Multiple sclerosis, Huntington's diseases, Amyotrophic lateral sclerosis,
              Fronto-temporal lobar degeneration and Brain Tumors namely Meningioma, Medulloblastoma, Glioma/Glioblastoma
              and Pituitary adenoma.
            </p>

            <h5>Whether BDTM provides visualisation of the data</h5>
            <p> BDTM provides visualisation of data in the form of both bar plot and box plots for each datasets. Bar plot
              represents sample wise expression of each gene; however, boxplot deciphers the differential expression in disease  compared 
              to the control group. The significance annotation has been provided using the Moderate T-test to calculate pvalue.
              (Pvalue annotation mapping - "***" = p-value &lt; 0.001; "**" = p-value &lt; 0.01; "*" = p-value &lt; 0.05; "ns" = p-value &gt; 0.05.).
              No Preview Indicates either non availability of controls or number of samples in a group less than 3. &nbsp;
              <span style={{color:"green"}}>Green</span> color indicates Control and <span style={{color:"red"}}>Red</span> color indicates Disease in barplot and boxplot.
            </p>

          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bdpm-accordion'>
          <AccordionHeader targetId="3"><h3>Brain Disease Proteome Map (BDPM)</h3></AccordionHeader>
          <AccordionBody accordionId="3">
            <h5>What kind of data can be accessed by this domain?</h5>
            <p>The Brain Disease Proteome Map (BDPM) currently offers processed intensity of Mass Spectrometry (MS) data of human brain disease datasets.
              Barplots and Boxplots are used to represent the sample information of diseased datasets. BDPM also provides the meta-data information of
              each dataset.
            </p>

            <h5>How to use the BDPM portal?</h5>
            <p>Search any Gene/Protein ID you are interested in using the search bar above. You will be redirected to a page with the search results. To 
              visit the BDPM entry page, click the 'BDPM Link' column link of the favored Gene/Protein. Ensure you have typed your query correctly and 
              whether the gene/Protein ID exists in humans (BrainProt follows UniProt's Human Proteome Database).
            </p>

            <h5>How the proteomics data analysis was done for BDPM?</h5>
            <h6>Data Mining, Filtering and Downloading </h6>
            <p>
              Well-known databases such as ProteomeXchange, PRIDE, and OMICS DI were used for diseased mining datasets. The keyword used for the disease search 
              was "Disease Name." The key filtering factors taken into account were the following: Publication: Yes, Sample type: Brain tissue, Species: Homo 
              sapiens, and availability of sample meta-data. However, specific datasets were excluded from further analysis due to insufficient sample-level 
              information and incomplete raw files.
            </p>
            <h6>Data Analysis: </h6>
            <p>The .RAW, .MZML, .WIFF format files were analyzed in the MaxQuant (v1.6.12.0) with the Human Uniprot Database 
              (uniprot_UniProtKB_2022_02_human_reviewed). MaxQuant (Version: 6.12.0) was used to analyze the raw data with a maximum missed cleavage of 2. 
              Carbamidomethylation of cysteine (+57.021464 Da) was set as the fixed modification. In contrast, methionine oxidation (+15.994915 Da) was set 
              as the variable modification, and the maximum variable modifications on a peptide threshold were set to 3. The False-Discovery-Rate (FDR) was 
              set to 1 % for the proteins and PSM to ensure high protein identification/quantification reliability. Decoy mode was set to "reverse".  
              Furthermore, the proteins with a 50% missing value were removed. The missing value estimation was done using the KNN imputation strategy.
            </p>
            <h6>Visualization: </h6>
            <p>The median normalized and log transformed data is used to plot the box plots, and scaled processed data is used to draw the bar plots. 
              The significance annotation has been provided using the Welch test 
              to calculate the p-value (p-value annotation mapping - "***" = p-value &lt; 0.001; "**" = p-value &lt; 0.01; "*" = p-value &lt; 0.05; 
              "ns" = p-value &gt; 0.05). &nbsp;
              <span style={{color:"green"}}>Green</span> color indicates Control and <span style={{color:"red"}}>Red</span> color indicates Disease in 
              barplot and boxplot.
            </p>
            <h5>What diseases are included in BDPM? </h5>
            <p>BDPM includes data from different brain diseases like Alzheimer's disease, Parkinson's disease, Amyotrophic lateral sclerosis, Meningioma, 
              Glioma/Glioblastoma, and Medulloblastoma.</p>

          </AccordionBody>
        </AccordionItem>
        <AccordionItem id='bddf-accordion'>
          <AccordionHeader targetId="5"><h3>Brain Disease Drug Finder (BDDF)</h3></AccordionHeader>
          <AccordionBody accordionId="5">
            <h5>What is BDDF?</h5>
            <p>
              Brain Disease Drug Finder (BDDF) integrates multiple repositories comprising compounds that target potential protein/gene markers associated with 
              various human brain diseases. It provides information on drugs involved in clinical trials for different brain diseases. BDDF consolidates 
              information on known compounds, their affinity towards specific protein targets, toxicity status, and the phase of clinical studies. Users can 
              explore drugs linked to the top markers of 11 diseases (including 6 brain tumors and 5 neurodegenerative diseases) and search for drugs 
              targeting specific proteins based on their preferences.
            </p>

            <h5>How to use the BDDF portal?</h5>
            <p>
              BDDF can be explored in three ways: the "Clinical Trial Information" tab, the "Disease Therapeutic Compounds" tab, and a search bar for 
              protein/gene queries. The "Clinical Trial Information" tab provides comprehensive details about clinical trials, including phases and status, 
              for over 50 Human Brain Diseases. The "Disease Therapeutic Compounds" tab presents various compounds that show potential for targeting the top 
              markers of specific diseases. Users can search for genes of interest using the search bar and access the corresponding search results page. 
              To access the BDDF entry page, users can click on the 'BDDF Link' column link associated with the desired gene. It is important to ensure 
              accurate query entry and check the presence of the gene in humans, as BrainProt follows UniProt's Human Proteome Database.
            </p>

                <h5>What are the different repositories that are incorporated in BDDF?</h5>
                <p>
                  The data for BDDF is obtained from popular repositories like <a href="http://stitch.embl.de/" target='blank'>STITCH</a>,&nbsp;
                  <a href="https://www.ebi.ac.uk/chembl/" target='blank'>ChEMBL</a>,&nbsp;
                  <a href="https://www.bindingdb.org/" target='blank'>BindingDB</a>,&nbsp;
                  <a href="https://db.idrblab.net/ttd/" target='blank'>Therapeutic Target Database(TTD)</a>,&nbsp;
                  <a href="https://clue.io/" target='blank'>CLUE</a>,&nbsp;
                  <a href="https://www.opentargets.org/" target='blank'>Open Targets</a>,
                  <a href="https://pubchem.ncbi.nlm.nih.gov/" target='blank'>PubChem</a>, and &nbsp;
                  <a href="https://clinicaltrials.gov/" target='blank'>ClinicalTrials.gov</a>
                </p>

                <h5>What does the 'score' mean in BDDF?</h5>
                <p>
                  The score in BDDF represents the pIC50 value (9-log10(Activity IC50 value)) obtained from ChEMBL, BindingDB, TTD, and a normalized score 
                  using min-max normalization from STITCH. These scores indicate the affinity of a compound toward a specific target. A higher pIC50 value 
                  and stitch score indicate a greater affinity of the compound for the target. Essentially, the higher the pIC50 and stitch score, the 
                  stronger the affinity of the compound towards a particular target.
                </p>

                <h5>Does BDDF provide drug names and structures?</h5>
                <p>
                  BDDF provides the IUPAC names and SMILES structures of specific compounds, which can be utilized for further analysis.
                </p>

                <h5>Does BDDF provide FDA approved drugs for different diseases?</h5>
                <p> 
                  BDDF allows access to the drugs involved in clinical trials for over 50 brain diseases. Additionally, users can obtain information on 
                  FDA-approved drugs for 11 brain diseases, including 6 brain tumors and 5 neurodegenerative diseases whose detailed data has been 
                  incorporated in the BDPM, BDMC, AND BDTM domains. Furthermore, repositories like CLUE and TTD provide information on approved drugs and 
                  drugs in various stages of clinical development.
                </p>

                <h5>Does BDDF provides mechanism of action for inhibitor/compounds against the particular target?</h5>
                <p>
                  Yes, BDDF provides the mechanism of action of certain compounds against the targets with the data provided from TTD.
                </p>
                <h5>How BDDF is beneficial for a neuroscientist/researcher?</h5>
                <p>
                  BDDF aims to serve as a unique drug library containing known compounds that target various proteins associated with brain diseases. By 
                  integrating data from multiple repositories, this resource supports neuroscientists and researchers in accessing information about existing 
                  drugs targeting specific diseases or targets. It provides valuable insights into the clinical phases of the study and the toxicity status 
                  of the drugs. This knowledge empowers scientists to make informed decisions regarding the testing of compounds in clinical or laboratory 
                  settings, streamlining the process of discovering novel drugs for brain-related disorders and potentially reducing the time required for 
                  such discoveries.
                </p>

                <h5>Does BDDF provide any method to conduct Molecular/Docking or MD simulation studies in it?</h5>
                <p>
                  BDDF does not facilitate in-silico experiments. It integrates known drug libraries to provide information on drugs targeting 
                  specific proteins. However, it has the vision to expand its capabilities in the future to include information on target druggability and 
                  the effectiveness of targeting specific proteins as potential drug targets in brain disorders, including overcoming blood-brain barriers.
                </p>

                <h5>Why does it take so much time to load some proteins?</h5>
                <p>The response for the ChEMBL and BindingDB data is dynamic and is being fetched in real time from the ChEMBL and BindingDB API in addition 
                  to TTD, Stitch and Clue data and hence based on the number of therapeutic compounds associated with a protein it might take time to get the 
                  reponse
                </p>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </div>
        );
}

export default HelpAccordion;
