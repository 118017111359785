import React from 'react';
import { Container } from 'reactstrap';
import { LineSeparator } from '../components/common/separatorLine';

/*
////////////////////////////////
// TO DO
////////////////////////////////
1) Implement Light Mode/Dark Mode with help from https://github.com/famzila/dark-mode-demo
2) JWT Authentication

*/

export default function Contact() {
  const ele = document.getElementById('ipl-progress-indicator');
  if(ele){
      ele.classList.add('available');
      setTimeout(() => {
          ele.remove();
      }, 2000)
  };
  return (
    <>
        <Container fluid>
          <h1 style={{textAlign: "center"}}>BrainProt Team</h1>
          <h4 style={{textAlign: "center"}}>You can reach out to us by emailing us at <a href='mailto:support@brainprot.org'>support@brainprot.org</a></h4>
          <br />
          <LineSeparator />
          <h2 className= "text-center">Team</h2>
          <img className='contact-center' src='/images/concourse/Team_Photo1.jpg' />
          <LineSeparator />
          <h2 className= "text-center">Collaborators</h2>
          <img className='contact-center' src='/images/concourse/Collaborator_1.jpg' />
          <LineSeparator />
          <h2 className= "text-center">Acknowledgement</h2>
          <div style={{
            borderRadius: 20,
            background: "white",
            padding: 30,
            color: "black",
            fontSize: '20px'
          }}
          className= "contact-center text-center">
            BrainProt would like to extend its gratitude to the following people for their help and support -
            <br />
            Nirjhar Banerjee (IIT Bombay), Srijana Srivastava (NEU, Boston), Pubali Paul (LMU, Munich), Joel Abraham (IIT Bombay), Aryan Gupta (IIT Bombay), 
            Saicharan Ghantasala (IIT Bombay), Arghya Banerjee (IIT Bombay), Saail Narvekar(IIT Bombay), Yash Choudhary (IIT Bombay), and the
            &nbsp;<a href="https://www.bio.iitb.ac.in/~sanjeeva/" target="_blank">Proteomics Lab</a>, IIT Bombay.
          </div>
        </Container>
    </>
  );
}